import CustomState from '@rio-cloud/rio-uikit/CustomState';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { fireTrackingEvent, INFO_TRACKING_ACTIONS, TRACKING_CATEGORIES } from '../../../tracking/gtm-helper';
import { Chapter } from '../infoModal/Chapter';
import { setActiveInfoModalChapter } from '../../../routes/actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { accountType, getAccountType } from '../../../configuration/login/loginSlice';

export const UserGroupsContent = (props: React.PropsWithChildren) => {
    const location = useLocation();
    const params = useParams();
    const push = useNavigate();

    const currentAccountType = useAppSelector(getAccountType);

    const showGroupsDescription = () => {
        fireTrackingEvent(
            TRACKING_CATEGORIES.USERADMIN_INFO,
            INFO_TRACKING_ACTIONS.USER_GROUP_DESCRIPTION_LINK_CLICKED
        );
        let groupToDisplay;
        switch (currentAccountType) {
            case accountType.BUYER:
                groupToDisplay = Chapter.BUYER_GROUPS;
                break;
            case accountType.SUPPLIER:
                groupToDisplay = Chapter.SUPPLIER_GROUPS;
                break;
            case accountType.CARRIER:
            default:
                groupToDisplay = Chapter.GROUPS;
        }
        const routeProps = {
            location,
            params,
            push,
        };
        setActiveInfoModalChapter(groupToDisplay, routeProps);
    };

    return (
        <CustomState
            headline={<FormattedMessage id={'header.info.entities.usergroups.title'} />}
            message={
                <>
                    <div>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="link" onClick={showGroupsDescription}>
                            <FormattedMessage id={'header.info.entities.usergroups.linkText'} />
                        </a>{' '}
                        <FormattedMessage id={'header.info.entities.usergroups.text'} />
                    </div>
                    <div className="padding-top-10">{props.children}</div>
                </>
            }
            icons={[
                {
                    name: 'rioglyph rioglyph-user',
                    className: 'text-size-200pct',
                },
                {
                    name: 'rioglyph rioglyph-user',
                    className: 'text-size-300pct',
                },
                {
                    name: 'rioglyph rioglyph-user',
                    className: 'text-size-200pct',
                },
            ]}
            outerClassName={'margin-bottom-15 bg-highlight-decent'}
            condensed={true}
        />
    );
};
