import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { UnsavedChangesDialog } from './UnsavedChangesDialog';

export class ModalPrompt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            goToLocation: null,
            confirmedPromptAlready: false,
            trigger: Symbol.for(`__RioConfirmationCallback_${uuid()}`),
        };
        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        window[this.state.trigger] = this.show;
    }

    componentWillUnmount() {
        delete window[this.state.trigger];
    }

    close() {
        this.setState({ open: false });
    }

    show() {
        this.setState({ open: true });
    }

    render() {
        const { handleConfirmDiscard, handleKeepEditing, when } = this.props;

        const { open } = this.state;

        const onClickConfirmDiscard = () => {
            handleConfirmDiscard();
            this.close();
        };

        const onClickKeepEditing = () => {
            handleKeepEditing();
            this.close();
        };

        // TODO: find an alternative for <Prompt /> - this has blocked leaving the site with unsaved changes
        return (
            <React.Fragment>
                {when && open && (
                    <UnsavedChangesDialog
                        onClickConfirmDiscard={() => {
                            this.setState({ confirmedPromptAlready: true }, () => {
                                onClickConfirmDiscard();
                                this.props.navigate(this.state.goToLocation);
                            });
                        }}
                        onClickKeepEditing={onClickKeepEditing}
                    />
                )}
            </React.Fragment>
        );
    }
}

ModalPrompt.propTypes = {
    when: PropTypes.bool.isRequired,
    navigate: PropTypes.func.isRequired,
    handleConfirmDiscard: PropTypes.func.isRequired,
    handleKeepEditing: PropTypes.func.isRequired,
};
