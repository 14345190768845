import { connect } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import {
    mapDispatchToGroupSidebarFooter,
    mapStateToGroupSidebarFooter,
} from '../groups/groupsidebar/groupSidebarFooter';
import { mapDispatchToUserSidebarFooter, mapStateToUserSidebarFooter } from '../users/usersidebar/userSidebarFooter';
import { withRouter } from '../../../routes/routerUtils';

interface SidebarFooterProps {
    canDelete: boolean;
    openDeleteConfirmation: () => void;
    cancel: () => void;
    sidebarHasChanges: boolean;
    save: () => void;
    deleteButtonLabel: string;
    cancelButtonLabel: string;
    saveButtonLabel: string;
}

const SidebarFooter = (props: SidebarFooterProps) => {
    const {
        canDelete,
        openDeleteConfirmation,
        cancel,
        sidebarHasChanges,
        save,
        deleteButtonLabel,
        cancelButtonLabel,
        saveButtonLabel,
    } = props;

    return (
        <>
            <div className={'btn-toolbar pull-left'}>
                {canDelete && (
                    <button className={'btn btn-link btn-danger'} onClick={openDeleteConfirmation} type={'button'}>
                        <span className={'rioglyph rioglyph-trash text-color-danger margin-right-5'} />
                        <FormattedMessage id={deleteButtonLabel} />
                    </button>
                )}
            </div>
            <div className={'btn-toolbar pull-right'}>
                <button className={'btn btn-default'} onClick={cancel} type={'button'}>
                    <FormattedMessage id={cancelButtonLabel} />
                </button>
                <button
                    className={classNames('btn btn-primary', !sidebarHasChanges && 'disabled')}
                    onClick={save}
                    type={'button'}
                >
                    <FormattedMessage id={saveButtonLabel} />
                </button>
            </div>
        </>
    );
};

export const GroupSidebarFooter = withRouter(
    connect(mapStateToGroupSidebarFooter, mapDispatchToGroupSidebarFooter)(SidebarFooter)
);
export const UserSidebarFooter = withRouter(
    connect(mapStateToUserSidebarFooter, mapDispatchToUserSidebarFooter)(SidebarFooter)
);
