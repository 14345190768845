import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from '../../../notifications/notificationType';
import { getErrorMessageKey } from '../../common/commonFunctions';
import { getCreatePayloadFromState } from '../groupsidebar/groupSidebarSaveService';
import { getCreateGroupDescriptionModification, getCreateGroupNameModification } from './createGroupSelectors';
import { ThunkAction } from '@reduxjs/toolkit';
import { UserGroup } from '../../appTypes';
import { closeCreateGroupModal } from '../../../../routes/actions';
import { createGroup } from '../../../api/api';
import { RouteComponentProps } from '../../../../routes/routerUtils';
import createGroupSlice, { CreateGroupActionTypes } from './createGroupSlice';
import { RootState } from '../../../../configuration/setup/store';
import appSlice, { AppActionTypes } from '../../appSlice';
import notificationSlice, { NotificationActionTypes } from '../../../notifications/notificationSlice';

export const createGroupThunk =
    (
        props: RouteComponentProps
    ): ThunkAction<any, RootState, unknown, CreateGroupActionTypes | AppActionTypes | NotificationActionTypes> =>
    (dispatch, getState) => {
        dispatch(createGroupSlice.actions.setGroupCreationInProgress(true));

        const state = getState();
        const name = getCreateGroupNameModification(state);
        const description = getCreateGroupDescriptionModification(state);
        const updatedGroupPayload = getCreatePayloadFromState(state, name, description);

        createGroup(updatedGroupPayload)
            .then((enriched: UserGroup) => {
                dispatch(appSlice.actions.addGroup(enriched));
                dispatch(createGroupSlice.actions.resetCreateGroupState());
                closeCreateGroupModal(props);
                dispatch(
                    notificationSlice.actions.displayNotification({
                        notificationType: SUCCESS_NOTIFICATION,
                        notificationKey: 'groups.create.groupSaved',
                    })
                );
            })
            .catch((error: any) => {
                // api.reportError(error);
                dispatch(
                    notificationSlice.actions.displayNotification({
                        notificationType: ERROR_NOTIFICATION,
                        notificationKey: getErrorMessageKey(error, 'groups.create.error.groupNotCreated'),
                    })
                );
            });
    };
