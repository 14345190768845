import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* eslint-disable-next-line import/no-unresolved */
import { getSessionWarningAcknowledged } from '../features/app/appSelectors';
import { isUserSessionExpired } from '../configuration/login/loginSlice';
import { getLocale } from '../configuration/lang/langSlice';
import appSlice from '../features/app/appSlice';

SessionExpiredDialog.propTypes = {
    locale: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    locale: getLocale(state),
    show: isUserSessionExpired(state) && !getSessionWarningAcknowledged(state),
});

const mapDispatchToProps = (dispatch) => ({
    onClose: (ev) => {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }
        dispatch(appSlice.actions.sessionWarningDialogClosed());
    },
});

export const SessionExpiredDialogContainer = connect(mapStateToProps, mapDispatchToProps)(SessionExpiredDialog);
