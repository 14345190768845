import RioMenuItem from './RioMenuItem';
import { FormattedMessage } from 'react-intl';

export const LogisticsMenu = () => (
    <div className={'flex-1-0 width-100pct min-width-300 display-flex flex-column padding-5'}>
        <RioMenuItem
            title={<FormattedMessage id={'applicationmenu.supplierhub.title'} />}
            description={<FormattedMessage id={'applicationmenu.supplierhub.description'} />}
            icon={'order'}
            link={'https://rio.webscm.webedi.rio.cloud'}
        />
        <RioMenuItem
            title={<FormattedMessage id={'applicationmenu.useradmin.title'} />}
            description={<FormattedMessage id={'applicationmenu.useradmin.description'} />}
            icon={'user'}
            link={'/'}
        />
    </div>
);
