import { getFirstNameOfSelectedUser, getLastNameOfSelectedUser } from '../../appSelectors';
import { confirmDiscardUserSidebar, deleteUserThunk } from './userSidebarThunks';
import { fireTrackingEvent, TRACKING_CATEGORIES, USER_TRACKING_ACTIONS } from '../../../../tracking/gtm-helper';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { deselectUser } from '../../../../routes/actions';
import { AppDispatch, RootState } from '../../../../configuration/setup/store';
import userSidebarSlice from './userSidebarSlice';
import { RouteComponentProps } from '../../../../routes/routerUtils';

export const mapStateToDeleteUserDialogProps = (state: RootState) => ({
    values: {
        firstName: getFirstNameOfSelectedUser(state),
        lastName: getLastNameOfSelectedUser(state),
    },
    cancelButtonLabel: 'dialog.delete.cancel',
    confirmButtonLabel: 'dialog.delete.confirm',
    content: 'dialog.user.delete.message',
    title: 'dialog.user.delete.title',
});

export const mapDispatchToDeleteUserDialogProps = (dispatch: AppDispatch, ownProps: RouteComponentProps) => ({
    clickConfirm: () => {
        fireTrackingEvent(TRACKING_CATEGORIES.USERADMIN_USER, USER_TRACKING_ACTIONS.DELETE_USER_CONFIRM);
        dispatch(deleteUserThunk());
        dispatch(userSidebarSlice.actions.showDeleteConfirmation(false));
        deselectUser(ownProps);
    },
    clickCancel: () => dispatch(userSidebarSlice.actions.showDeleteConfirmation(false)),
});

export const mapStateToReallyDiscardUserChangesProps = () => ({
    values: {},
    cancelButtonLabel: 'dialog.discard.keepEditing',
    confirmButtonLabel: 'dialog.discard.discardChanges',
    content: 'dialog.discard.reallyDiscardChanges',
    title: 'dialog.discard.discardChangesTitle',
});

export const mapDispatchToReallyDiscardUserChangesProps = (dispatch: ThunkDispatch<RootState, void, AnyAction>) => ({
    clickConfirm: () => {
        fireTrackingEvent(TRACKING_CATEGORIES.USERADMIN_USER, USER_TRACKING_ACTIONS.DISCARD_USER_EDITING);
        dispatch(confirmDiscardUserSidebar());
    },
    clickCancel: () => {
        fireTrackingEvent(TRACKING_CATEGORIES.USERADMIN_USER, USER_TRACKING_ACTIONS.CONTINUE_USER_EDITING);
        dispatch(userSidebarSlice.actions.showConfirmation(false));
    },
});
