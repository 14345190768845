import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DriverSettings } from './types';

type PendingState = 'load' | 'save' | undefined;

export interface AccountSettingsState {
    pending: PendingState;
    settings: {
        userSelfInvitation: {
            driver: DriverSettings;
        };
    };
    modifiedSettings: {
        userSelfInvitation: {
            driver: DriverSettings | undefined;
        };
    };
}

const initialState: AccountSettingsState = {
    pending: undefined,
    settings: {
        userSelfInvitation: {
            driver: 'disabled',
        },
    },
    modifiedSettings: {
        userSelfInvitation: {
            driver: undefined,
        },
    },
};

const accountSettingsSlice = createSlice({
    name: 'accountSettings',
    initialState,
    reducers: {
        setDriverSelfInvitation(state, action: PayloadAction<DriverSettings>) {
            state.settings.userSelfInvitation.driver = action.payload;
        },
        setModifiedDriverSelfInvitation(state, action: PayloadAction<DriverSettings>) {
            state.modifiedSettings.userSelfInvitation.driver = action.payload;
        },
        setPending(state, action: PayloadAction<PendingState>) {
            state.pending = action.payload;
        },
    },
});

export const { setDriverSelfInvitation, setModifiedDriverSelfInvitation, setPending } = accountSettingsSlice.actions;

export const accountSettingsReducer = accountSettingsSlice.reducer;
