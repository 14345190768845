import { userSidebarHasChanges } from '../usersidebar/userSidebarService';
import { deselectUser, restrictSelectionToUser, setActiveInfoModalChapter } from '../../../../routes/actions';
import { selectUserIdOfSelectedUser } from '../../../../routes/selector';
import { Chapter } from '../../infoModal/Chapter';
import { getAccountType } from '../../../../configuration/login/loginSlice';
import { AppDispatch, RootState } from '../../../../configuration/setup/store';
import userSidebarSlice from '../usersidebar/userSidebarSlice';
import appSlice from '../../appSlice';
import { RouteComponentProps } from '../../../../routes/routerUtils';
import { User } from '../../appTypes';

export const userSelectionInTable =
    (selection: User, props: RouteComponentProps) => (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState();
        const selectedUserId = selectUserIdOfSelectedUser(props);
        const sidebarHasChanges = userSidebarHasChanges(state);
        if (sidebarHasChanges) {
            dispatch(userSidebarSlice.actions.showConfirmation(true));
        } else if (selection.id === selectedUserId) {
            dispatch(appSlice.actions.userDeselected());
            deselectUser(props);
        } else {
            dispatch(appSlice.actions.userSelected(selection));
            restrictSelectionToUser(selection.id, props);
        }
    };

export const showUserGroupInfoForAccountType =
    (props: RouteComponentProps) => (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState();
        const accountType = getAccountType(state);

        switch (accountType) {
            case 'BUYER':
                setActiveInfoModalChapter(Chapter.BUYER_GROUPS, props);
                break;
            case 'SUPPLIER':
                setActiveInfoModalChapter(Chapter.SUPPLIER_GROUPS, props);
                break;
            case 'CARRIER':
            default:
                setActiveInfoModalChapter(Chapter.GROUPS, props);
                break;
        }
    };
