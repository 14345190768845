import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';

export const TableHeadPure = memo(({ field, text, className, onClick, sortBy, sortOrder }) => (
    <th
        className={classNames(sortBy && 'sort-column', className && className)}
        data-field={field}
        data-sortby={sortBy}
        onClick={onClick}
    >
        <span>
            {sortBy && <span className={classNames('sort-arrows', sortOrder && `sort-${sortOrder}`)} />}
            {text}
        </span>
    </th>
));

TableHeadPure.propTypes = {
    className: PropTypes.string,
    field: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    sortBy: PropTypes.string,
    sortOrder: PropTypes.oneOf(['', 'asc', 'desc']),
    // eslint-disable-next-line react/forbid-prop-types
    text: PropTypes.any,
};

TableHeadPure.defaultProps = {
    className: '',
    sortBy: '',
    sortOrder: '',
    text: '',
};

export const TableHead = TableHeadPure;
