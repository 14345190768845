import packageJson from '../package.json';
import { LogLevel } from 'configcat-react';

export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
        USER_ADMIN: string | undefined;
        ACCOUNTS_SERVICE: string | undefined;
        TAG_SERVICE: string | undefined;
    };
    configCat: {
        logLevel: LogLevel;
        apiKey: string;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: Array<string>;
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    serviceVersion: string;
    serviceEnvironment: string;
    logoutUri: string | undefined;
    sentryToken: string | undefined;
    prodAccessToken: string | undefined;
    prodIdToken: string | undefined;
}

const asBool = (value: string | undefined): boolean => value === 'true';

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        USER_ADMIN: import.meta.env.VITE_USERADMIN_SERVICE,
        TAG_SERVICE: import.meta.env.VITE_TAGS_SERVICE,
        ACCOUNTS_SERVICE: import.meta.env.VITE_ACCOUNTS_SERVICE,
    },
    configCat: {
        logLevel: getLoglevelFromString(import.meta.env.VITE_CONFIG_CAT_LOG_LEVEL),
        apiKey: import.meta.env.VITE_CONFIG_API_KEY,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '26d29ac9-462b-48dd-8b09-2bd465f753f6',
        oauthScope: ['openid', 'profile', 'email', 'phone'],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    prodAccessToken: import.meta.env.VITE_PROD_ACCESS_TOKEN,
    prodIdToken: import.meta.env.VITE_PROD_ID_TOKEN,
};

function getLoglevelFromString(logLevel: string): LogLevel {
    switch (logLevel) {
        case 'LogLevel.Debug':
            return LogLevel.Debug;
        case 'LogLevel.Info':
            return LogLevel.Info;
        case 'LogLevel.Warn':
            return LogLevel.Warn;
        case 'LogLevel.Error':
            return LogLevel.Error;
        case 'LogLevel.Off':
            return LogLevel.Off;
        default:
            return LogLevel.Off;
    }
}
