import { FormattedMessage } from 'react-intl';

export const FleetGroupExplanation = () => (
    <>
        <h4 className="margin-bottom-10">
            <FormattedMessage id={'header.info.entities.fleetgroups.title'} />
        </h4>
        <div>
            <FormattedMessage id={'header.info.entities.fleetgroups.text'} />{' '}
            <a className="link" href="https://administration.assets.rio.cloud/">
                <FormattedMessage id={'header.info.entities.fleetgroups.linktext'} />
            </a>
            {'.'}
        </div>
    </>
);
