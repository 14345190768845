import { MAX_LENGTH_STRING_SHORT, MAX_LENGTH_STRING } from '../app/types';

const emailValidator = (value) =>
    /^[\x21-\x26\x28-\x7E]+@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/g.test(value) && value.length <= 73;
export const phoneNumberValidator = (value) => /\+[1-9][0-9]{5,13}$/u.test(value);
export const nameValidator = (value) => /^[^!$^#*&"<>|/\\{}=+?%]*$/u.test(value);

export const commonStringValidation = (stringToValidate, errorMessage) => {
    if (!stringToValidate || stringToValidate.length > MAX_LENGTH_STRING || !nameValidator(stringToValidate)) {
        return errorMessage;
    }
    return null;
};

export const commonStringValidationAllowEmpty = (stringToValidate, errorMessage) => {
    if (stringToValidate.length > MAX_LENGTH_STRING || !nameValidator(stringToValidate)) {
        return errorMessage;
    }
    return null;
};

export const commonEmailValidation = (email, required) => {
    if (!email) {
        if (required) {
            return 'users.error.email.string.empty';
        }
        return null;
    }
    return emailValidator(email) ? null : 'users.error.email.string.email';
};

export const commonPhoneNumberValidation = (phone, required) => {
    if (!phone) {
        if (required) {
            return 'users.error.phoneNumber.string.empty';
        }
        return null;
    }
    return phoneNumberValidator(phone) ? null : 'users.error.phoneNumber.string.format';
};

export const nameTypes = {
    FIRST_NAME: {
        emptyError: 'users.error.firstName.any.empty',
        whitespaceError: 'users.error.firstName.string.whitespace',
        tooLongError: 'users.error.firstName.string.max',
        specialCharError: 'users.error.firstName.string.regex.base',
    },
    LAST_NAME: {
        emptyError: 'users.error.lastName.any.empty',
        whitespaceError: 'users.error.lastName.string.whitespace',
        tooLongError: 'users.error.lastName.string.max',
        specialCharError: 'users.error.lastName.string.regex.base',
    },
};

export const commonValidateName = (name, nameType) => {
    if (!name) {
        return nameType.emptyError;
    }
    if (name.toString().startsWith(' ')) {
        return nameType.whitespaceError;
    }
    if (name.length > MAX_LENGTH_STRING_SHORT) {
        return nameType.tooLongError;
    }
    return nameValidator(name) ? null : nameType.specialCharError;
};
