import { canUpdate } from '../../meta';
import { getSelectedUser } from '../../appSelectors';
import { userSidebarHasChanges } from './userSidebarService';
import { cancelUserSidebar, saveUserThunk } from './userSidebarThunks';
import { isAppInMaintenanceMode } from '../../../selectors';
import { fireTrackingEvent, TRACKING_CATEGORIES, USER_TRACKING_ACTIONS } from '../../../../tracking/gtm-helper';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { groupsInSidebarNotEmpty, namesInSidebarNotEmpty } from './userSidebarSelectors';
import { RootState } from '../../../../configuration/setup/store';
import userSidebarSlice from './userSidebarSlice';
import { RouteComponentProps } from '../../../../routes/routerUtils';

export interface StateProps {
    canDelete: boolean;
    sidebarHasChanges: boolean;
    deleteButtonLabel: string;
    cancelButtonLabel: string;
    saveButtonLabel: string;
}

export interface DispatchProps {
    openDeleteConfirmation: () => void;
    cancel: () => void;
    save: () => void;
}

export const mapStateToUserSidebarFooter = (state: RootState): StateProps => ({
    canDelete: !isAppInMaintenanceMode(state) && canUpdate(getSelectedUser(state)),
    sidebarHasChanges: userSidebarHasChanges(state) && namesInSidebarNotEmpty(state) && groupsInSidebarNotEmpty(state),

    deleteButtonLabel: 'users.edit.deleteUser',
    cancelButtonLabel: 'users.edit.cancel',
    saveButtonLabel: 'users.edit.saveChanges',
});

export const mapDispatchToUserSidebarFooter = (
    dispatch: ThunkDispatch<RootState, void, AnyAction>,
    ownProps: RouteComponentProps
): DispatchProps => ({
    openDeleteConfirmation: () => {
        fireTrackingEvent(TRACKING_CATEGORIES.USERADMIN_USER, USER_TRACKING_ACTIONS.DELETE_USER);
        dispatch(userSidebarSlice.actions.showDeleteConfirmation(true));
    },
    cancel: () => dispatch(cancelUserSidebar(ownProps)),
    save: () => {
        fireTrackingEvent(TRACKING_CATEGORIES.USERADMIN_USER, USER_TRACKING_ACTIONS.SAVE_UPDATED_USER);
        dispatch(saveUserThunk());
    },
});
