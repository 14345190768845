import * as t from 'io-ts';
import { decode } from './iotsDecoder';

/// const knownSettings = t.union([t.literal('user-self-registration'), t.literal('to-be-defined')]);
const knownSettingsIds = t.union([t.literal('user-self-registration'), t.unknown]);

const knownUserSelfRegistrationKeys = t.literal('driver_self_registration');

const knownDriverSelfRegistrationValues = t.union([t.literal('disabled'), t.literal('enabled')]);

const setting = t.type({ id: knownSettingsIds, group: t.union([t.string, t.undefined]), values: t.UnknownRecord });

const accountSettingsApiResponse = t.exact(
    t.type({
        items: t.array(setting),
    })
);

export type KnownUserSelfRegistrationKeys = t.TypeOf<typeof knownUserSelfRegistrationKeys>;

export type KnownDriverSelfRegistrationValues = t.TypeOf<typeof knownDriverSelfRegistrationValues>;

export type ApiAccountSetting = t.TypeOf<typeof setting>;
export type AccountSettingsApiResponse = t.TypeOf<typeof accountSettingsApiResponse>;

export const decodeAccountSettingsApiResponse = (parsedObject: unknown): AccountSettingsApiResponse =>
    decode(parsedObject, accountSettingsApiResponse);
