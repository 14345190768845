import { GROUPS_LIST_MODE_CHANGED, USERS_LIST_MODE_CHANGED } from './actions';
import { configureRepository } from './settingsRepository';
import { configureIsMobile } from '../app/common/responsive';

const USERS_TABLE_NAME = 'users';
const GROUPS_TABLE_NAME = 'groups';

const preselectedTableMode = () => (configureIsMobile(window)() ? 'split' : 'table');

const loadInitialTableModeSettings = (tableName, repository) => {
    const modeFromStore = repository.getTableMode(tableName);
    const currentTableMode = modeFromStore ? modeFromStore : preselectedTableMode();

    if (!modeFromStore) {
        repository.setTableMode(tableName, currentTableMode);
    }
    return currentTableMode;
};

const createInitialState = (repository) => {
    const currentUsersTableMode = loadInitialTableModeSettings(USERS_TABLE_NAME, repository);
    const currentGroupsTableMode = loadInitialTableModeSettings(GROUPS_TABLE_NAME, repository);

    return {
        groupsListMode: currentGroupsTableMode,
        usersListMode: currentUsersTableMode,
    };
};

export const configureReducer = (tableSettingsRepository) => (state, fact) => {
    switch (fact.type) {
        case GROUPS_LIST_MODE_CHANGED:
            tableSettingsRepository.setTableMode(GROUPS_TABLE_NAME, fact.payload);
            return {
                ...state,
                groupsListMode: fact.payload,
            };
        case USERS_LIST_MODE_CHANGED:
            tableSettingsRepository.setTableMode(USERS_TABLE_NAME, fact.payload);
            return {
                ...state,
                usersListMode: fact.payload,
            };
        default:
            return state;
    }
};

const configureReducerWithInitialState = (window) => {
    const settingsRepository = configureRepository(window);
    const createdInitialState = createInitialState(settingsRepository);
    return (state = createdInitialState, fact = {}) => configureReducer(settingsRepository)(state, fact);
};

export const setting = configureReducerWithInitialState(window);
