import get from 'lodash/get';
import { PreferredLogin } from './users/invite/inviteUserTypes';
import { Tag } from './appTypes';
import { RootState } from '../../configuration/setup/store';

export const getSortByField = (sort: string | undefined) => (sort ? sort.substring(1) : '');
export const getSortOrderIsAsc = (sort: string) => (sort ? sort.charAt(0) === '+' : true);

export const getApp = (state: RootState) => get(state, 'app');

export const getGroups = (state: RootState) => get(getApp(state), 'groups', []);
export const getGroupsLoadMoreLink = (state: RootState) => get(getApp(state), 'groupsLoadMoreLink', null);
export const getRoles = (state: RootState) => get(getApp(state), 'roles', []);

export const getSelectedGroup = (state: RootState) => get(getApp(state), 'selectedGroup', null);
export const getSelectedGroupId = (state: RootState) => get(getSelectedGroup(state), 'id', null);
export const getRolesOfSelectedGroup = (state: RootState) => get(getSelectedGroup(state), 'assigned_roles', []);
export const getPersistedNameOfSelectedGroup = (state: RootState) => get(getSelectedGroup(state), 'name', '');
export const getPersistedDescriptionOfSelectedGroup = (state: RootState) =>
    get(getSelectedGroup(state), 'description', '');

export const getSelectedUser = (state: RootState) => get(getApp(state), 'selectedUser', null);
export const getLastCreatedUserId = (state: RootState) => get(getApp(state), 'lastCreatedUserId', undefined);
export const getSelectedUserId = (state: RootState) => get(getSelectedUser(state), 'id', null);
export const getGroupIdsOfSelectedUser = (state: RootState): Array<string> | undefined =>
    get(getSelectedUser(state), 'group_ids', []);
export const getFirstNameOfSelectedUser = (state: RootState) => get(getSelectedUser(state), 'first_name', '');
export const getLastNameOfSelectedUser = (state: RootState) => get(getSelectedUser(state), 'last_name', '');
export const getEmailOfSelectedUser = (state: RootState) => get(getSelectedUser(state), 'email', '');
export const getAdditionalAuthenticationFactorsOfSelectedUser = (state: RootState) =>
    get(getSelectedUser(state), 'additional_authentication_factors', []);
export const getPhoneNumberOfSelectedUser = (state: RootState) => get(getSelectedUser(state), 'phone_number', '');
export const getLoginOfSelectedUser = (state: RootState) => get(getSelectedUser(state), 'login', '');
export const getPreferredLoginOfSelectedUser = (state: RootState): PreferredLogin =>
    get(getSelectedUser(state), 'preferred_login', 'email');

export const getSessionWarningAcknowledged = (state: RootState) =>
    get(getApp(state), 'sessionWarningAcknowledged', false);
export const getUsers = (state: RootState) => get(getApp(state), 'users', []);
export const getUsersLoadMoreLink = (state: RootState) => get(getApp(state), 'usersLoadMoreLink', null);
export const getTags = (state: RootState): Array<Tag> => get(getApp(state), 'tags', []);
export const getAllowedGroupActions = (state: RootState) => get(getApp(state), 'allowedGroupActions', []);
export const getAllowedUserActions = (state: RootState) => get(getApp(state), 'allowedUserActions', []);
export const getAccessIsForbidden = (state: RootState) => get(getApp(state), 'accessIsForbidden', false);
export const getServerErrorCode = (state: RootState) => get(getApp(state), 'serverErrorCode', null);

export const getLoadingError = (state: RootState) => get(getApp(state), 'loadingError', null);
export const getIsLoading = (state: RootState) => get(getApp(state), 'isLoading', false);
