import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import IframeResizer from 'iframe-resizer-react';

import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';

import { config } from '../config';
import { getSelectedGroupId, getSelectedUserId } from '../features/app/appSelectors';
import metaInfo from '../meta.json';

import { InfoButton } from './InfoButton';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import { DEFAULT_LOCALE } from '../configuration/lang/lang';
import { useAppSelector } from '../configuration/setup/hooks';
import { getLocale } from '../configuration/lang/langSlice';
import { accountType, getAccountType } from '../configuration/login/loginSlice';
import { Link } from 'react-router-dom';
import { LogisticsMenu } from './LogisticsMenu';

const appTitleTranslation: { [key: string]: { name: string } } = metaInfo['translation'];

export const ApplicationHeaderContainer = () => {
    const locale = useAppSelector(getLocale);
    const selectedUserId = useAppSelector(getSelectedUserId);
    const selectedGroupId = useAppSelector(getSelectedGroupId);
    const isCarrierAccount = useAppSelector((state) => getAccountType(state) === accountType.CARRIER);

    const userMenu = (
        <DefaultUserMenu
            environment={import.meta.env.PROD ? 'production' : 'development'}
            logoutRedirectUri={'https://users.iam.rio.cloud'}
        />
    );
    const notifications = <RioNotifications />;

    const url = config.backend.MENU_SERVICE;
    const appNavigator = isCarrierAccount ? <IframeResizer className={'iFrameResizer'} src={url} /> : <LogisticsMenu />;

    const appTitle = appTitleTranslation[locale!]
        ? appTitleTranslation[locale!].name
        : appTitleTranslation[DEFAULT_LOCALE].name;

    const navItems = [
        {
            key: 'users',
            route: (
                <NavLink to={selectedUserId ? `/users/${selectedUserId}` : '/users'}>
                    <FormattedMessage id={'users.subModuleName'} />
                </NavLink>
            ),
        },
    ];

    if (isCarrierAccount) {
        // users in logistics accounts should not be able to access the groups tab via ui
        navItems.push({
            key: 'groups',
            route: (
                <NavLink to={selectedGroupId ? `/groups/${selectedGroupId}` : '/groups'}>
                    <FormattedMessage id={'groups.subModuleName'} />
                </NavLink>
            ),
        });
    }

    const accountSettings = (
        <ActionBarItem id={'noPopoverComponent'} hidePopoverOnClick={false} popoverWidth={400}>
            <ActionBarItem.Icon>
                <NavLink to={'/settings'}>
                    <span className={'icon rioglyph rioglyph-cog'} data-cy={'account-settings-icon'} />
                </NavLink>
            </ActionBarItem.Icon>
        </ActionBarItem>
    );

    const infoButton = <InfoButton appTitle={appTitle} />;

    const actionBarItems = isCarrierAccount ? [accountSettings, infoButton, notifications, userMenu] : [userMenu];

    const homeRoute = isCarrierAccount ? (
        <Link to={'https://home.rio.cloud'} />
    ) : (
        <Link to={'https://users.iam.rio.cloud'} />
    ); // TODO use correct url for supplier hub
    return (
        <ApplicationLayout.Header>
            <ApplicationHeader
                actionBarItems={actionBarItems}
                appNavigator={appNavigator}
                homeRoute={homeRoute}
                label={appTitle}
                navItems={navItems}
            />
        </ApplicationLayout.Header>
    );
};
