import { UserSidebarState } from './userSidebarTypes';
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import appSlice from '../../appSlice';
import { User } from '../../appTypes';

export const initialState: UserSidebarState = {
    selectedUser: {
        email: '',
        firstName: '',
        lastName: '',
        groups: undefined,
    },
    showConfirmation: false,
    showDeleteConfirmation: false,
};

const userSidebarSlice = createSlice({
    name: 'userSidebarSlice',
    initialState,
    reducers: {
        deleteUserSuccess(state) {
            state.showDeleteConfirmation = false;
        },
        deleteUserError() {},

        saveUserSuccess() {},
        saveUserError() {},

        setNewFirstName(state, action: PayloadAction<string>) {
            state.selectedUser.firstName = action.payload;
        },

        setNewLastName(state, action: PayloadAction<string>) {
            state.selectedUser.lastName = action.payload;
        },

        setNewEmail(state, action: PayloadAction<string>) {
            state.selectedUser.email = action.payload;
        },

        setNewGroups(state, action: PayloadAction<string[]>) {
            state.selectedUser.groups = action.payload;
        },

        resetMutations(state) {
            state.selectedUser.groups = undefined;
        },

        resetState() {
            return initialState;
        },

        showDeleteConfirmation(state, action: PayloadAction<boolean>) {
            state.showDeleteConfirmation = action.payload;
        },

        showConfirmation(state, action: PayloadAction<boolean>) {
            state.showConfirmation = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isAnyOf(appSlice.actions.userDeselected, appSlice.actions.userDeleted), () => {
                return initialState;
            })
            .addMatcher(
                isAnyOf(
                    appSlice.actions.userSelected,
                    appSlice.actions.updateUser,
                    appSlice.actions.selectedUserFetched
                ),
                (state, action: PayloadAction<User>) => {
                    state.selectedUser.firstName = action.payload.first_name;
                    state.selectedUser.lastName = action.payload.last_name;
                }
            )
            .addDefaultCase(() => {});
    },
});

export default userSidebarSlice;
