import React from 'react';
import { useIntl } from 'react-intl';
import RestrictUserGroup from '../../../../assets/usergroup_restrivt_to_fleet_group.png';
import { CounterList } from '../CounterList';

export const RestrictUserGroupExplained = () => {
    const intl = useIntl();

    const title = intl.formatMessage({ id: `infoDialog.features.restrictUserGroup` });

    return (
        <div className={'row'} data-testid={'Create User'}>
            <div className={'col-xs-12'}>
                <h4>{title}</h4>
                <div className={`margin-bottom-25`}>
                    <img src={RestrictUserGroup} alt={'tdv'} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div>
                <CounterList chapter={'restrictUserGroup'} count={2} />
            </div>
        </div>
    );
};
