import React from 'react';
import PropTypes from 'prop-types';
import { itemPropTypes } from '../../types';
import { connect } from 'react-redux';
import {
    mapDispatchToCreateGroupRoleAssignmentProps,
    mapDispatchToGroupSidebarRoleAssignmentProps,
    mapStateToCreateGroupRoleAssignmentProps,
    mapStateToGroupSidebarRoleAssignmentProps,
} from './roleList';
import { injectIntl } from 'react-intl';
import { RemovableRoleListItem } from './RemovableRoleListItem';
import { TagSearch } from './TagSearchField';

const panelClassNameBase =
    'panel panel-default panel-body padding-top-0 padding-bottom-0 margin-top-15 margin-bottom-0';

const panelClassName = (color) =>
    color ? panelClassNameBase + ` border-color-${color} text-color-${color}` : panelClassNameBase;

export const RoleRestrictionListRaw = ({
    items,
    handleRemoveItem,
    handleResurrectItem,
    handleResurrectSubItem,
    handleRemoveSubItem,
    onSelectSuggestionSubItem,
    handleFetchSuggestionsSubItems,
    canUpdate,
    intl,
}) => {
    const translateItem = (item) => {
        const prefix = intl.formatMessage({ id: `roles.names.prefix` });
        const rawDisplayName = intl.formatMessage({
            id: `roles.names.${item.name}`,
        });
        const displayName = prefix ? prefix + ': ' + rawDisplayName : rawDisplayName;
        return {
            ...item,
            displayName,
        };
    };

    const getSubItemListWithTitle = (renderedSubItems) => (
        <div className={'taglist taglist-autowidth taglist-vertical'}>{renderedSubItems}</div>
    );

    const renderSubItems = (item) => {
        const { subItems, id, shouldBeRemoved, canNotHaveSubItems } = item;
        const renderedSubItems = subItems
            .filter((subItems) => subItems !== null)
            .map((subItem) => {
                const borderColor = subItem.shouldBeAdded ? 'success' : subItem.shouldBeRemoved ? 'danger' : '';
                subItem.displayName =
                    intl.formatMessage({ id: 'roles.restrictions.prefix' }) + ': ' + subItem.displayName;
                return (
                    <RemovableRoleListItem
                        key={subItem.parentId + subItem.id}
                        handleRemoveItem={handleRemoveSubItem}
                        handleResurrectItem={handleResurrectSubItem}
                        item={subItem}
                        mayBeUpdated={canUpdate}
                        className={`tag rounded padding-top-0 padding-bottom-0 margin-bottom-15 border-color-${borderColor} text-color-${borderColor}`}
                        isTag={true}
                    />
                );
            });

        if (shouldBeRemoved || canNotHaveSubItems) {
            return <React.Fragment />;
        }

        return <React.Fragment>{getSubItemListWithTitle(renderedSubItems, id)}</React.Fragment>;
    };

    const renderRestrictionSearch = (roleId) => (
        <TagSearch
            roleId={roleId}
            onSelectSuggestion={onSelectSuggestionSubItem}
            onFetchSuggestions={handleFetchSuggestionsSubItems}
            readOnly={!canUpdate}
        />
    );

    const displayItems = items.map(translateItem).map((item) => {
        const borderColor = item.shouldBeAdded ? 'success' : item.shouldBeRemoved ? 'danger' : null;
        const shouldRenderSearchField = !item.canNotHaveSubItems && !item.shouldBeRemoved;
        return (
            <div className={panelClassName(borderColor)} key={item.id}>
                <RemovableRoleListItem
                    handleRemoveItem={handleRemoveItem}
                    handleResurrectItem={handleResurrectItem}
                    mayBeUpdated={canUpdate}
                    item={item}
                    className={'text-size-18 margin-top-10 margin-bottom-10'}
                />
                {shouldRenderSearchField && renderRestrictionSearch(item.id)}
                {renderSubItems(item)}
            </div>
        );
    });

    return <React.Fragment>{displayItems}</React.Fragment>;
};
RoleRestrictionListRaw.propTypes = {
    intl: PropTypes.object,
    items: PropTypes.arrayOf(itemPropTypes).isRequired,
    canUpdate: PropTypes.bool.isRequired,
    handleResurrectItem: PropTypes.func.isRequired,
    handleRemoveItem: PropTypes.func.isRequired,

    handleResurrectSubItem: PropTypes.func.isRequired,
    handleRemoveSubItem: PropTypes.func.isRequired,

    onSelectSuggestionSubItem: PropTypes.func.isRequired,
    handleFetchSuggestionsSubItems: PropTypes.func.isRequired,
};

export const RoleRestrictionListInSidebar = connect(
    mapStateToGroupSidebarRoleAssignmentProps,
    mapDispatchToGroupSidebarRoleAssignmentProps
)(injectIntl(RoleRestrictionListRaw));

export const RoleRestrictionListInCreateGroup = connect(
    mapStateToCreateGroupRoleAssignmentProps,
    mapDispatchToCreateGroupRoleAssignmentProps
)(injectIntl(RoleRestrictionListRaw));
