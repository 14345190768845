import CustomState from '@rio-cloud/rio-uikit/CustomState';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { fireTrackingEvent, INFO_TRACKING_ACTIONS, TRACKING_CATEGORIES } from '../../../tracking/gtm-helper';
import { Chapter } from '../infoModal/Chapter';
import { setActiveInfoModalChapter } from '../../../routes/actions';
import { withRouter } from '../../../routes/routerUtils';

// eslint-disable-next-line no-unused-vars
const mapDispatchToHelpContentProps = (dispatch, ownProps) => ({
    showRoleDescription: () => {
        fireTrackingEvent(
            TRACKING_CATEGORIES.USERADMIN_INFO,
            INFO_TRACKING_ACTIONS.USER_GROUP_ROLE_DESCRIPTION_LINK_CLICKED
        );
        setActiveInfoModalChapter(Chapter.ROLES, ownProps);
    },
});

const UserGroupRolesContentRaw = (props) => (
    <CustomState
        headline={<FormattedMessage id={'header.info.entities.usergrouproles.title'} />}
        message={
            <>
                <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="link" onClick={props.showRoleDescription}>
                        <FormattedMessage id={'header.info.entities.usergrouproles.linkText'} />
                    </a>{' '}
                    <FormattedMessage id={'header.info.entities.usergrouproles.text'} />
                </div>
                <div className="padding-top-10">{props.children}</div>
            </>
        }
        icons={[
            {
                name: 'rioglyph rioglyph-role-management',
                className: 'text-size-300pct',
            },
        ]}
        outerClassName={'margin-bottom-15 bg-highlight-decent'}
        condensed={true}
    />
);

UserGroupRolesContentRaw.propTypes = {
    showRoleDescription: PropTypes.func.isRequired,
    children: PropTypes.node,
};

export const UserGroupRolesContent = withRouter(connect(null, mapDispatchToHelpContentProps)(UserGroupRolesContentRaw));
