import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import { TableToolbarPure } from '../../common/TableToolbar';
import { getUsersListMode } from '../../../setting/selectors';
import { changeUsersListMode } from '../../../setting/actions';
import { changeUserSearch } from '../../../../routes/actions';

import { selectUserSearch } from '../../../../routes/selector';
import { withRouter } from '../../../../routes/routerUtils';

const mapStateToUserTableToolbarProps = (state, ownProps) => ({
    dataListMode: getUsersListMode(state),
    searchPlaceholder: 'users.table.search.placeholder',
    searchValue: selectUserSearch(ownProps),
});

const mapDispatchToUserTableToolbarProps = (dispatch, ownProps) => ({
    handleSelectSplitMode: () => dispatch(changeUsersListMode('split')),
    handleSelectTableMode: () => dispatch(changeUsersListMode('table')),
    handleSearchChange: (query) => changeUserSearch(ownProps, query),
});
export const UserTableToolbar = withRouter(
    connect(mapStateToUserTableToolbarProps, mapDispatchToUserTableToolbarProps)(injectIntl(TableToolbarPure))
);
