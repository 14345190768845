import { commonStringValidation, commonStringValidationAllowEmpty } from '../../../validation/userInputValidation';
import {
    getCreateGroupDescriptionModification,
    getCreateGroupNameModification,
    getCreateGroupRolesToAdd,
} from './createGroupSelectors';

export const getCreateGroupNameError = (state) => {
    const name = getCreateGroupNameModification(state);
    return commonStringValidation(name, 'groups.create.error.invalidName');
};

export const getCreateGroupDescriptionError = (state) => {
    const description = getCreateGroupDescriptionModification(state);
    return commonStringValidationAllowEmpty(description, 'groups.create.error.invalidDescription');
};

export const canSubmitCreateGroup = (state) => {
    const rolesToAdd = getCreateGroupRolesToAdd(state);
    const descriptionError = getCreateGroupDescriptionError(state);
    const nameError = getCreateGroupNameError(state);

    return rolesToAdd.length > 0 && !descriptionError && !nameError;
};
