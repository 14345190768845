import classNames from 'classnames';
import { ReactNode } from 'react';

export type RioMenuItemProps = {
    title: ReactNode;
    description: ReactNode;
    icon: string;
    link: string;
};

const RioMenuItem = (props: RioMenuItemProps) => {
    const { title, description, icon, link = '#' } = props;

    const className = classNames(
        'RioMenuItem',
        'height-100pct',
        'rounded',
        'bg-white',
        'display-flex',
        'align-items-start',
        'gap-20',
        'text-decoration-none',
        'user-select-none',
        'bg-white',
        'hover-bg-lightest',
        'padding-15'
    );

    return (
        <a className={className} href={link} target="_top">
            {icon && (
                <div className="position-relative padding-20 border border-size-2 border-color-primary bg-white">
                    <div className="position-absolute inset-0 display-grid place-items-center">
                        <span className={`text-size-20 rioglyph rioglyph-${icon}`} />
                    </div>
                </div>
            )}
            <div className="flex-1-1">
                <div className="line-height-125rel margin-top--3">
                    <div className="text-size-18 text-medium margin-bottom-3 text-color-primary">{title}</div>
                    <div className="text-size-14 text-color-darkest text-capitalize">{description}</div>
                </div>
            </div>
        </a>
    );
};

export default RioMenuItem;
