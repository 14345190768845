import { canDelete } from '../../meta';
import { getSelectedGroup } from '../../appSelectors';
import { groupSidebarHasChanges } from './groupPropertiesService';
import { cancelGroupSidebar, saveGroupThunk } from './groupSidebarThunks';
import { isAppInMaintenanceMode } from '../../../selectors';
import { fireTrackingEvent, TRACKING_CATEGORIES, USER_GROUP_TRACKING_ACTIONS } from '../../../../tracking/gtm-helper';
import { AppDispatch, RootState } from '../../../../configuration/setup/store';
import groupSidebarSlice from './groupSidebarSlice';
import { RouteComponentProps } from '../../../../routes/routerUtils';

export const mapStateToGroupSidebarFooter = (state: RootState) => ({
    canDelete: !isAppInMaintenanceMode(state) && canDelete(getSelectedGroup(state)),
    sidebarHasChanges: groupSidebarHasChanges(state),

    deleteButtonLabel: 'groups.edit.deleteGroup',
    cancelButtonLabel: 'groups.edit.cancel',
    saveButtonLabel: 'groups.edit.saveChanges',
});

export const mapDispatchToGroupSidebarFooter = (dispatch: AppDispatch, ownProps: RouteComponentProps) => ({
    openDeleteConfirmation: () => {
        fireTrackingEvent(TRACKING_CATEGORIES.USERADMIN_USER_GROUP, USER_GROUP_TRACKING_ACTIONS.DELETE_USER_GROUP);
        dispatch(groupSidebarSlice.actions.showDeleteGroupConfirmation(true));
    },
    cancel: () => dispatch(cancelGroupSidebar(ownProps)),
    save: () => {
        fireTrackingEvent(
            TRACKING_CATEGORIES.USERADMIN_USER_GROUP,
            USER_GROUP_TRACKING_ACTIONS.SAVE_UPDATED_USER_GROUP
        );
        dispatch(saveGroupThunk);
    },
});
