import { IFuseOptions } from 'fuse.js';

export const autoCompleteSettings: IFuseOptions<{
    label: string;
    id: string;
    type: string;
}> = {
    distance: 100,
    keys: ['label'],
    location: 0,
    minMatchCharLength: 1,
    threshold: 0.5,
};
