import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { EditGroupFormContainer } from '../groupform/GroupFormContainer';
import { mapDispatchToEditGroupDialog, mapStateToEditGroupDialog } from './editGroupDialog';

export const EditGroupDialogPure = (props) => {
    const { handleCancel, handleSubmit, canSubmit } = props;

    const body = (
        <React.Fragment>
            <EditGroupFormContainer />
        </React.Fragment>
    );

    return (
        <ConfirmationDialog
            bsSize={'sm'}
            cancelButtonText={<FormattedMessage id={'groups.edit.cancel'} />}
            confirmButtonText={<FormattedMessage id={'groups.edit.saveGroup'} />}
            content={body}
            disableConfirm={!canSubmit}
            onClickCancel={handleCancel}
            onClickConfirm={handleSubmit}
            show
            title={<FormattedMessage id={'groups.edit.dialogTitle'} />}
        />
    );
};

EditGroupDialogPure.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    canSubmit: PropTypes.bool.isRequired,
};

export const EditGroupDialogContainer = connect(
    mapStateToEditGroupDialog,
    mapDispatchToEditGroupDialog
)(EditGroupDialogPure);
