const TABLE_SETTINGS_KEY = 'useradmin.table.settings';

const readSettingsFromStorage = (window) => JSON.parse(window.localStorage.getItem(TABLE_SETTINGS_KEY));

const writeSettingsToStorage = (window, settings) =>
    window.localStorage.setItem(TABLE_SETTINGS_KEY, JSON.stringify(settings));

const setTableMode = (window) => (tableName, mode) => {
    const previousSettings = readSettingsFromStorage(window);

    const newSettings = {
        ...previousSettings,
        [tableName]: { mode },
    };

    writeSettingsToStorage(window, newSettings);
};

const getTableMode = (window) => (tableName) => {
    const settings = readSettingsFromStorage(window);
    if (settings && settings[tableName] && settings[tableName].mode) {
        return settings[tableName].mode;
    }
    return null;
};

export const configureRepository = (window) => ({
    getTableMode: getTableMode(window),
    setTableMode: setTableMode(window),
});
