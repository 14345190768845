import { useIntl } from 'react-intl';

export const DriverSelfRegistrationExplanation = () => {
    const intl = useIntl();

    const title = intl.formatMessage({ id: 'accountSettings.settings.userSelfInvitation.driver' });
    const content1 = intl.formatMessage({ id: 'accountSettings.settings.userSelfInvitation.driver.help.content1' });
    const content2 = intl.formatMessage({ id: 'accountSettings.settings.userSelfInvitation.driver.help.content2' });
    const listSettings = intl.formatMessage({
        id: 'accountSettings.settings.userSelfInvitation.driver.help.listSettings',
    });
    const enabledSettings = intl.formatMessage({
        id: 'accountSettings.settings.userSelfInvitation.driver.help.enabledSettings',
    });
    const disabledSettings = intl.formatMessage({
        id: 'accountSettings.settings.userSelfInvitation.driver.help.disabledSettings',
    });
    const enabled = intl.formatMessage({ id: 'accountSettings.settings.userSelfInvitation.driver.help.enabled' });
    const disabled = intl.formatMessage({ id: 'accountSettings.settings.userSelfInvitation.driver.help.disabled' });
    return (
        <>
            <h4 className="margin-bottom-10">{title}</h4>
            <div>
                <p>
                    <span>{content1}</span>
                    <br />
                    <span>{content2}</span>
                </p>
                <p>{listSettings}</p>
                <ul className={'margin-top-10'}>
                    <li className={'margin-bottom-5'}>
                        <b className={'margin-right-5'}>{enabled}</b>
                        <span>{enabledSettings}</span>
                    </li>
                    <li className={'margin-bottom-5'}>
                        <b className={'margin-right-5'}>{disabled}</b>
                        <span>{disabledSettings}</span>
                    </li>
                </ul>
            </div>
        </>
    );
};
