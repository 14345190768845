const LARGE_SCREEN = '1440px';
const MOBILE_SCREEN_BREAKPOINT = '768px';

export const SIDEBAR_BREAKPOINT_FLY = 1440;

export const configureIsMobile = (window) => () => {
    if (window.matchMedia) {
        return window.matchMedia(`(max-width: ${MOBILE_SCREEN_BREAKPOINT})`).matches;
    }
    return false;
};

export const isScreenMinWidth = (breakpoint) => window.matchMedia(`(min-width: ${breakpoint})`).matches;

export const isLargeScreen = () => isScreenMinWidth(LARGE_SCREEN);
