import { equals } from 'ramda';
import PropTypes from 'prop-types';

export const MAX_LENGTH_STRING_SHORT = 40;
export const MAX_LENGTH_STRING = 100;

export const listMode = {
    isSplit: equals('split'),
    isTable: equals('table'),
};

// Prop Type Migration
export const scopeTypePropType = PropTypes.oneOf(['account', 'global', 'tag']);

export const scopePropType = PropTypes.shape({
    identifier_type: scopeTypePropType.isRequired,
    identifiers: PropTypes.arrayOf(PropTypes.string),
});

export const roleAssignmentPropType = PropTypes.shape({
    role_id: PropTypes.string.isRequired,
    scope: scopePropType.isRequired,
});

export const groupPropType = PropTypes.shape({
    assigned_roles: PropTypes.arrayOf(roleAssignmentPropType).isRequired,
    description: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

export const tagPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    account_id: PropTypes.string.isRequired,
});

export const subItemPropTypes = PropTypes.shape({
    id: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired,
    shouldBeAdded: PropTypes.bool.isRequired,
    shouldBeRemoved: PropTypes.bool.isRequired,
    displayName: PropTypes.string.isRequired,
});

export const itemPropTypes = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shouldBeAdded: PropTypes.bool.isRequired,
    shouldBeRemoved: PropTypes.bool.isRequired,
    displayName: PropTypes.string.isRequired,
    subItems: PropTypes.arrayOf(subItemPropTypes),
    canNotHaveSubItems: PropTypes.bool,
});

export const intlPropType = PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
});

export const rolePropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    scope_type: scopeTypePropType,
});
