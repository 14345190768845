import { fetchGroup, fetchGroups, fetchRoles, fetchTags, fetchUsers } from '../../../api/api';
import { ERROR_NOTIFICATION } from '../../../notifications/notificationType';
import { getGroups, getGroupsLoadMoreLink } from '../../appSelectors';
import { getErrorMessageKey } from '../../common/commonFunctions';
import { DEFAULT_LIMIT_FOR_BACKEND_FETCHES, GROUPS_PAGE_SIZE } from '../../../../constants';
import { selectGroupIdOfSelectedGroup, selectGroupSearch, selectGroupSort } from '../../../../routes/selector';
import { AppDispatch, State } from '../../../../setup/types';
import { GroupsAndLoadMore } from '../../../api/types';
import { RouteComponentProps } from '../../../../routes/routerUtils';
import appSlice from '../../appSlice';
import notificationSlice from '../../../notifications/notificationSlice';

export const fetchGroupsAccordingToSearch = (props: RouteComponentProps) => (dispatch: AppDispatch) => {
    dispatch(appSlice.actions.isLoading({ isLoading: true }));
    const groupSort = selectGroupSort(props);

    const groupRequestParameters = {
        groupSortBy: groupSort ? groupSort.substring(1) : '',
        groupSortByAsc: groupSort ? groupSort.charAt(0) === '+' : true,
        limit: GROUPS_PAGE_SIZE,
        search: selectGroupSearch(props),
    };

    Promise.all<any>([fetchGroups(groupRequestParameters)])
        .then((result) => {
            const [groupsResponse] = result;
            dispatch(
                appSlice.actions.groupsFetched({
                    groups: groupsResponse.groups,
                    loadMoreLink: groupsResponse.loadMoreLink,
                    allowedActions: groupsResponse.allowedActions,
                })
            );
            dispatch(appSlice.actions.isLoading({ isLoading: false }));
        })
        .catch((error) => {
            // api.reportError(error);
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: ERROR_NOTIFICATION,
                    notificationKey: 'groups.error.notAllDataRetrievable',
                })
            );
            dispatch(appSlice.actions.isLoading({ isLoading: false, error }));
        });
};

export const loadMoreGroups = (dispatch: AppDispatch, getState: () => State) => {
    const groupsLoadMoreLink = getGroupsLoadMoreLink(getState());

    const mergeMore = (data: GroupsAndLoadMore): GroupsAndLoadMore => {
        const oldGroups = getGroups(getState());
        const { loadMoreLink, groups, allowedActions } = data;

        return {
            loadMoreLink,
            groups: [...oldGroups, ...groups],
            allowedActions,
        };
    };

    return fetchGroups({ rawUrl: groupsLoadMoreLink! })
        .then(mergeMore)
        .then((data: GroupsAndLoadMore) =>
            dispatch(
                appSlice.actions.groupsFetched({
                    groups: data.groups,
                    loadMoreLink: data.loadMoreLink,
                    allowedActions: data.allowedActions,
                })
            )
        )
        .catch((error: any) => {
            // api.reportError(error);
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: ERROR_NOTIFICATION,
                    notificationKey: 'loadMore.error.noItemsRetrievable',
                })
            );
        });
};

export const fetchDataForGroupTab = (props: RouteComponentProps) => (dispatch: AppDispatch) => {
    const groupSort = selectGroupSort(props);
    const groupSearch = selectGroupSearch(props);
    const groupIdOfGroupToSelect = selectGroupIdOfSelectedGroup(props);
    const groupRequestParameters = {
        groupSortBy: groupSort ? groupSort.substring(1) : '',
        groupSortByAsc: groupSort ? groupSort.charAt(0) === '+' : true,
        limit: GROUPS_PAGE_SIZE,
        search: groupSearch,
    };
    const requests: Array<Promise<any>> = [
        fetchTags({ limit: DEFAULT_LIMIT_FOR_BACKEND_FETCHES }),
        fetchGroups(groupRequestParameters),
        fetchRoles({ limit: DEFAULT_LIMIT_FOR_BACKEND_FETCHES }),
        fetchUsers({ limit: DEFAULT_LIMIT_FOR_BACKEND_FETCHES }),
    ];

    if (groupIdOfGroupToSelect) {
        requests.push(fetchGroup(groupIdOfGroupToSelect));
    }

    dispatch(appSlice.actions.isLoading({ isLoading: true }));

    Promise.all<any>(requests)
        .then((result) => {
            const [tags = [], groupsResponse = {}, roles = [], usersResponse = {}, selectedGroup] = result;
            dispatch(appSlice.actions.tagsFetched(tags));
            dispatch(
                appSlice.actions.groupsFetched({
                    groups: groupsResponse.groups,
                    loadMoreLink: groupsResponse.loadMoreLink,
                    allowedActions: groupsResponse.allowedActions,
                })
            );
            dispatch(appSlice.actions.rolesFetched(roles));
            dispatch(
                appSlice.actions.usersFetched({
                    users: usersResponse.users,
                    loadMoreLink: usersResponse.loadMoreLink,
                    allowedActions: usersResponse.allowedActions,
                })
            );

            if (selectedGroup) {
                dispatch(appSlice.actions.selectedGroupFetched(selectedGroup));
            }

            dispatch(appSlice.actions.isLoading({ isLoading: false }));
        })
        .catch((error) => {
            // api.reportError(error);
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: ERROR_NOTIFICATION,
                    notificationKey: getErrorMessageKey(error, 'groups.error.notAllDataRetrievable'),
                })
            );
            dispatch(appSlice.actions.isLoading({ isLoading: false, error }));
        });
};
