import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import React from 'react';

export const GenericErrorState = () => (
    <div className={'display-flex justify-content-center margin-top-25'}>
        <ErrorState
            headline={'Oops, something went wrong'}
            message={
                <div>
                    {'An error (module user administration) has occurred.\n'}
                    {'We apologise for the inconvenience and request that you try again '}
                    {'later or contact the RIO Customer Care Hotline:'}
                    <span className={'text-size-large margin-top-10'}>{'00800 / 22550746'}</span>
                </div>
            }
        />
    </div>
);
