import { getUsersListMode } from '../../../setting/selectors';
import { getGroups, getIsLoading, getUsers, getUsersLoadMoreLink } from '../../appSelectors';
import { loadMoreUsers } from '../userstab/usersTabThunks';
import { USERS_PAGE_SIZE } from '../../../../constants';
import { showUserGroupInfoForAccountType, userSelectionInTable } from './userTableThunks';
import {
    fireTrackingEvent,
    INFO_TRACKING_ACTIONS,
    TRACKING_CATEGORIES,
    USER_TRACKING_ACTIONS,
} from '../../../../tracking/gtm-helper';
import { changeUserSort } from '../../../../routes/actions';
import { selectUserIdOfSelectedUser, selectUserSort } from '../../../../routes/selector';

export const mapStateToUserTableProps = (state, ownProps) => ({
    groups: getGroups(state),
    users: getUsers(state),
    hasMoreData: Boolean(getUsersLoadMoreLink(state)),
    isFirstPage: getUsers(state).length <= USERS_PAGE_SIZE,
    selectedUserId: selectUserIdOfSelectedUser(ownProps),
    usersListMode: getUsersListMode(state),
    userSort: selectUserSort(ownProps),
    isLoading: getIsLoading(state),
});

export const mapDispatchToUserTableProps = (dispatch, ownProps) => ({
    handleSelectionChange: (user) => {
        fireTrackingEvent(TRACKING_CATEGORIES.USERADMIN_USER, USER_TRACKING_ACTIONS.SELECT_USER);
        dispatch(userSelectionInTable(user, ownProps));
    },
    handleSortChange: (ev) => {
        const newSortBy = ev.currentTarget.getAttribute('data-sortby');
        const previousUserSort = selectUserSort(ownProps);
        const previousSortAsc = previousUserSort ? previousUserSort.charAt(0) === '+' : true;
        const previousSortBy = previousUserSort ? previousUserSort.substring(1) : '';
        if (previousSortBy === newSortBy) {
            changeUserSort(ownProps, (!previousSortAsc ? '+' : '-') + newSortBy);
        } else {
            changeUserSort(ownProps, (previousSortAsc ? '+' : '-') + newSortBy);
        }
    },
    handleLoadMore: () => dispatch(loadMoreUsers),
    showUserGroupHelp: () => {
        fireTrackingEvent(TRACKING_CATEGORIES.USERADMIN_INFO, INFO_TRACKING_ACTIONS.USER_GROUP_TABLE_HEADING_CLICKED);
        dispatch(showUserGroupInfoForAccountType(ownProps));
    },
});
