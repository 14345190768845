import { find } from 'ramda';

export const isRestrictedRole = (tags, scope) => {
    if (scope.identifier_type === 'account') {
        return false;
    }
    const tagsOfRole = (scope.identifiers || [])
        .map((scopeId) => find(({ id }) => id === scopeId, tags))
        .filter((it) => it !== undefined);

    return tagsOfRole.length > 0;
};
