import ForbiddenState from '@rio-cloud/rio-uikit/ForbiddenState';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const AccessNotAllowed = () => (
    <ForbiddenState
        headline={<FormattedMessage id={'errors.forbidden.title'} />}
        message={<FormattedMessage id={'errors.forbidden.message'} />}
    />
);
