import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { FormattedMessage } from 'react-intl';

export const LoadMore = memo((props) => {
    const { handleLoadMore, hasMore, isLoading, showNoMoreDataLabel } = props;

    const loadButton = (
        <button
            className={classNames('btn btn-default', isLoading && 'btn-loading-overlay')}
            onClick={handleLoadMore}
            type={'button'}
        >
            <FormattedMessage defaultMessage={'Load More...'} id={'loadMore'} />
        </button>
    );

    const atWorldsEnd = showNoMoreDataLabel ? <FormattedMessage id={'loadMore.noMoreData'} /> : null;

    return (
        <div
            className={classNames(
                'align-items-center',
                'display-flex',
                'justify-content-center',
                'margin-top-25',
                'margin-bottom-25'
            )}
            role={'toolbar'}
        >
            {hasMore ? loadButton : atWorldsEnd}
        </div>
    );
});

LoadMore.propTypes = {
    handleLoadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool,
    isLoading: PropTypes.bool,
    showNoMoreDataLabel: PropTypes.bool,
};

LoadMore.defaultProps = {
    hasMore: false,
    isLoading: false,
    showNoMoreDataLabel: true,
};
