import TagManager from '@rio-cloud/rio-uikit/TagManager';
import React from 'react';
import { InviteUserGroupHeader, UserSidebarGroupHeader } from '../ListHeader';
import { UserGroupsContent } from '../../users/UsersHelpContent';
import PropTypes from 'prop-types';
import { getGroupIdsOfSelectedUser, getGroups } from '../../appSelectors';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getInviteUserGroupIds } from '../../users/invite/inviteUserSelectors';

export const UserGroupsListRaw = (props) => {
    const { allGroups, existingGroups, notifyParentOfChange } = props;

    const selectedGroupsWithLabels = allGroups
        .filter((group) => existingGroups.includes(group.id))
        .map((group) => ({
            ...group,
            label: group.name,
        }));

    const handleTagListChange = (tags) => {
        notifyParentOfChange(tags.filter((group) => !group.toRemove).map((group) => group.id));
    };

    const allGroupsWithLabels = allGroups.map((group) => ({
        ...group,
        label: group.name,
    }));

    const getFormattedMessage = (key) => props.intl.formatMessage({ id: key });

    return (
        <div className={'margin-bottom-10'}>
            <TagManager
                tagList={selectedGroupsWithLabels}
                onTagListChange={handleTagListChange}
                tagSuggestions={allGroupsWithLabels}
                placeholder={getFormattedMessage('users.edit.groups.searchPlaceholder')}
                noItemMessage={getFormattedMessage('users.edit.groups.noGroupsMessage')}
                useCustomTags={false}
            />
        </div>
    );
};

UserGroupsListRaw.propTypes = {
    allGroups: PropTypes.array.isRequired,
    existingGroups: PropTypes.array.isRequired,
    notifyParentOfChange: PropTypes.func.isRequired,
};

const mapStateToUserGroupsListForSidebarProps = (state) => ({
    allGroups: getGroups(state),
    existingGroups: getGroupIdsOfSelectedUser(state),
});
export const UserGroupsListForSidebar = connect(mapStateToUserGroupsListForSidebarProps)(injectIntl(UserGroupsListRaw));

const mapStateToUserGroupsListForInviteProps = (state) => ({
    allGroups: getGroups(state),
    existingGroups: getInviteUserGroupIds(state),
});
export const UserGroupsListForInvite = connect(mapStateToUserGroupsListForInviteProps)(injectIntl(UserGroupsListRaw));

export const UserSidebarGroups = (props) => (
    <div>
        <UserSidebarGroupHeader />
        <UserGroupsContent>
            <UserGroupsListForSidebar notifyParentOfChange={props.notifyParentOfChange} />
        </UserGroupsContent>
    </div>
);

UserSidebarGroups.propType = {
    notifyParentOfChange: PropTypes.func.isRequired,
};

export const UserInviteGroups = (props) => (
    <div className={'inviteUserGroupSelect'}>
        <InviteUserGroupHeader />
        <UserGroupsContent>
            <UserGroupsListForInvite notifyParentOfChange={props.notifyParentOfChange} />
        </UserGroupsContent>
    </div>
);

UserInviteGroups.propType = {
    notifyParentOfChange: PropTypes.func.isRequired,
};
