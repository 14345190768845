import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import PropTypes from 'prop-types';

import { Navigate, Route, Routes } from 'react-router-dom';
import { SessionExpiredDialogContainer } from '../SessionExpiredContainer';
import { AccountSettings } from '../../features/app/accountSettings/AccountSettings';
import { Groups } from '../../features/app/groups/Groups';
import { InfoModal } from '../../features/app/infoModal/InfoModal';
import { Users } from '../../features/app/users/Users';

import { NotificationsTrigger } from '../../features/notifications/Notifications';
import { GROUP_ID_PATH_PARAM, USER_ID_PATH_PARAM } from '../../routes/selector';
import DefaultRedirect from '../../DefaultRoute';

const USERS_ROUTE = '/users';
const GROUPS_ROUTE = '/groups';
const SETTINGS_ROUTE = '/settings';

export const DEFAULT_ROUTE = USERS_ROUTE;
export const routes = [USERS_ROUTE, GROUPS_ROUTE, SETTINGS_ROUTE];

export const Shell = (props: { accessToken: string }) => {
    return (
        <div>
            <NotificationsTrigger />
            <NotificationsContainer />
            <SessionExpiredDialogContainer />
            <Routes>
                <Route path={`${USERS_ROUTE}/:${USER_ID_PATH_PARAM}`} element={<Users />} />
                <Route path={`${USERS_ROUTE}/*`} element={<Users />} />
                <Route path={`${GROUPS_ROUTE}/*`} element={<Groups />} />
                <Route path={`${GROUPS_ROUTE}/:${GROUP_ID_PATH_PARAM}`} element={<Groups />} />
                <Route path={SETTINGS_ROUTE} element={<AccountSettings />} />
                <Route path="/" element={<Navigate replace={true} to="users" />} />
            </Routes>
            <InfoModal />
            <DefaultRedirect />
            <input data-semantic-id={'access-token'} type={'hidden'} value={props.accessToken} />
        </div>
    );
};

Shell.propTypes = {
    accessToken: PropTypes.string.isRequired,
};
