/* eslint-disable */
import * as t from 'io-ts';
import { decode } from './iotsDecoder';

const allowedAction = t.union([t.literal('read'), t.literal('update'), t.literal('delete'), t.literal('add')]);

// USERS
const metadata = t.type({
    created: t.unknown,
    tenant: t.string,
    allowed_actions: t.unknown, // TODO do we actually have allowed actions for User metadata
});

const user = t.type({
    id: t.string,
    account_id: t.string,
    email: t.union([t.string, t.undefined, t.null]),
    additional_authentication_factors: t.union([t.array(t.string), t.undefined]),
    phone_number: t.union([t.string, t.undefined, t.null]),
    preferred_login: t.string, // leave this as general as possible here and do actual logic in mapper.ts
    preferred_language: t.string,
    first_name: t.string,
    last_name: t.string,
    group_ids: t.array(t.string),
    metadata: t.union([metadata, t.undefined]), // TODO check when metadata is/should be available
    subject: t.union([t.string, t.undefined, t.null]),
});

const usersApiResponse = t.type({
    items: t.array(user),
    allowed_actions: t.union([t.array(t.string), t.undefined, t.null]),
    _links: t.type({
        next: t.union([t.type({ href: t.string }), t.undefined]),
        self: t.union([t.type({ href: t.string }), t.undefined]),
    }),
});

export type UsersApiResponse = t.TypeOf<typeof usersApiResponse>;

export type ApiUser = t.TypeOf<typeof user>;

export const decodeUsersApiResponse = (parsedObject: unknown): UsersApiResponse =>
    decode(parsedObject, usersApiResponse);

export const decodeSingleUserApiResponse = (parsedObject: unknown): ApiUser => decode(parsedObject, user);

// GROUPS
const groupRole = t.type({
    role_id: t.string,
    scope: t.type({
        identifier_type: t.union([t.literal('tag'), t.literal('account')]),
        identifiers: t.array(t.string),
    }),
});

const group = t.type({
    id: t.string,
    name: t.string,
    description: t.union([t.string, t.undefined, t.null]),
    assigned_roles: t.array(groupRole),
    metadata: t.type({
        allowed_actions: t.array(allowedAction),
        created: t.unknown,
        tenant: t.unknown,
    }),
    _links: t.unknown,
});

const userGroupsApiResponse = t.type({
    items: t.array(group),
    allowed_actions: t.union([t.array(t.string), t.undefined, t.null]),
    _links: t.type({
        next: t.union([t.type({ href: t.string }), t.undefined]),
        self: t.union([t.type({ href: t.string }), t.undefined]),
    }),
});

export type ApiGroup = t.TypeOf<typeof group>;
export type UserGroupsApiResponse = t.TypeOf<typeof userGroupsApiResponse>;

export const decodeGroupsApiResponse = (parsedObject: unknown): UserGroupsApiResponse =>
    decode(parsedObject, userGroupsApiResponse);

export const decodeSingleGroupApiResponse = (parsedObject: unknown): ApiGroup => decode(parsedObject, group);

// ROLES
const role = t.type({
    id: t.string,
    name: t.string,
    scope_type: t.string,
});

const roleApiResponse = t.type({
    items: t.array(role),
    allowed_actions: t.unknown,
    _links: t.union([t.type({ self: t.union([t.type({ href: t.string }), t.undefined]) }), t.undefined]), // TODO should this really be undefined or is this just due to local mock?
});

export type ApiRole = t.TypeOf<typeof role>;
export type RoleApiResponse = t.TypeOf<typeof roleApiResponse>;

export const decodeRoleApiResponse = (parsedObject: unknown): RoleApiResponse => decode(parsedObject, roleApiResponse);
