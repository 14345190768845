import { getGroupDescriptionModification, getGroupNameModification } from '../groupsidebar/groupSidebarSelectors';
import { getCurrentGroupDescription, getCurrentGroupName } from '../groupsidebar/groupPropertiesService';
import { getEditGroupDescriptionError, getEditGroupNameError } from '../groupsidebar/editGroupService';
import { AppDispatch, RootState } from '../../../../configuration/setup/store';
import groupSidebarSlice from '../groupsidebar/groupSidebarSlice';

const setupGroupFormForEdit = (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(groupSidebarSlice.actions.updateGroupName(getCurrentGroupName(getState())));
    dispatch(groupSidebarSlice.actions.updateGroupDescription(getCurrentGroupDescription(getState())));
};

export const mapStateToEditGroupFormProps = (state: string) => ({
    readOnly: false,
    groupName: getGroupNameModification(state),
    groupDescription: getGroupDescriptionModification(state),
    groupDescriptionError: getEditGroupDescriptionError(state),
    groupNameError: getEditGroupNameError(state),
});

export const mapDispatchToEditGroupFormProps = (dispatch: AppDispatch) => ({
    setGroupName: (newGroupName: string) => dispatch(groupSidebarSlice.actions.updateGroupName(newGroupName)),
    setGroupDescription: (newGroupDescription: string) =>
        dispatch(groupSidebarSlice.actions.updateGroupDescription(newGroupDescription)),
    setInitialValues: () => dispatch(setupGroupFormForEdit),
});
