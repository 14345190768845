import {
    collectRolesForGroupInCreateGroup,
    collectRolesForGroupInGroupSidebar,
} from '../../services/fetchRolesService';
import { canUpdate } from '../../meta';
import { getSelectedGroup } from '../../appSelectors';
import { getCreateGroupRolesToAdd } from '../../groups/creategroup/createGroupSelectors';
import { isAppInMaintenanceMode } from '../../../selectors';
import createGroupSlice from '../../groups/creategroup/createGroupSlice';
import { AppDispatch, RootState } from '../../../../configuration/setup/store';
import { DisplayedGroupRole, UserGroupRole } from '../../appTypes';
import groupSidebarSlice from '../../groups/groupsidebar/groupSidebarSlice';

export const mapStateToGroupSidebarRoleSearchProps = (state: RootState) => ({
    addLabel: 'roles.edit.assignments.addAssignment',
    closeLabel: 'roles.edit.assignments.closeAddAssignment',
    noItemMessage: 'roles.edit.assignments.noAssignmentsMessage',
    placeholder: 'roles.edit.assignments.searchPlaceholder',
    onFetchSuggestions: () => collectRolesForGroupInGroupSidebar(state),
    readOnly: isAppInMaintenanceMode(state) || !canUpdate(getSelectedGroup(state)),
});

export const mapDispatchToGroupSidebarRoleSearchProps = (dispatch: AppDispatch) => ({
    onSelectSuggestion: (role: UserGroupRole) => dispatch(groupSidebarSlice.actions.addRole(role)),
});

export const mapStateToCreateGroupRoleSearchProps = (state: RootState) => ({
    addLabel: 'roles.edit.assignments.addAssignment',
    closeLabel: 'roles.edit.assignments.closeAddAssignment',
    noItemMessage: 'roles.edit.assignments.noAssignmentsMessage',
    placeholder: 'roles.edit.assignments.searchPlaceholder',
    pleaseSelectText: 'groups.create.error.noRolesSelected',
    hasNoElements: getCreateGroupRolesToAdd(state).length === 0,
    onFetchSuggestions: () => collectRolesForGroupInCreateGroup(state),
    readOnly: false,
});

export const mapDispatchToCreateGroupRoleSearchProps = (dispatch: AppDispatch) => ({
    onSelectSuggestion: (role: DisplayedGroupRole) => dispatch(createGroupSlice.actions.addRole(role)),
});
