import React from 'react';
import { useIntl } from 'react-intl';
import CreateUserForDriver from '../../../../assets/assign_user.png';
import { CounterList } from '../CounterList';

export const CreateUserForDriverExplained = () => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: `infoDialog.features.inviteUserForDriver` });

    return (
        <div className={'row'} data-testid={'Create User For Driver'}>
            <div className={'col-xs-12'}>
                <h4>{title}</h4>
                <div className={`margin-bottom-25`}>
                    <img src={CreateUserForDriver} alt={'tdv'} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div>
                <CounterList chapter={'createUserForDriver'} count={4} />
            </div>
        </div>
    );
};
