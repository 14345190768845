import { canSubmitEditGroup } from './editGroupService';
import { AppDispatch, RootState } from '../../../../configuration/setup/store';
import groupSidebarSlice from './groupSidebarSlice';

export const mapStateToEditGroupDialog = (state: RootState) => ({
    canSubmit: canSubmitEditGroup(state),
});
export const mapDispatchToEditGroupDialog = (dispatch: AppDispatch) => ({
    handleSubmit: () => dispatch(groupSidebarSlice.actions.showEditGroupDialog(false)),
    handleCancel: () => {
        dispatch(groupSidebarSlice.actions.showEditGroupDialog(false));
        dispatch(groupSidebarSlice.actions.updateGroupProperties({ newGroupName: '', newGroupDescription: '' }));
    },
});
