import get from 'lodash/get';

export const getGroupSidebar = (state) => get(state, 'groupSidebar');
export const getGroupModifications = (state) => get(getGroupSidebar(state), 'modifications', null);
export const getGroupUsersToAdd = (state) => get(getGroupModifications(state), 'usersToAdd', []);
export const getGroupUsersToRemove = (state) => get(getGroupModifications(state), 'usersToRemove', []);
export const getGroupRolesToAdd = (state) => get(getGroupModifications(state), 'rolesToAdd', []);
export const getGroupRolesToRemove = (state) => get(getGroupModifications(state), 'rolesToRemove', []);
export const getGroupRestrictionsToAdd = (state) => get(getGroupModifications(state), 'restrictionsToAdd', []);
export const getGroupRestrictionsToRemove = (state) => get(getGroupModifications(state), 'restrictionsToRemove', []);
export const getShowDeleteGroupConfirmation = (state) => get(getGroupSidebar(state), 'showDeleteConfirmation', false);
export const getShowEditGroupDialog = (state) => get(getGroupSidebar(state), 'showEditDialog', false);
export const getShowGroupConfirmation = (state) => get(getGroupSidebar(state), 'showConfirmation', false);

export const getGroupNameModification = (state) => get(getGroupModifications(state), 'newGroupName', '');
export const getGroupDescriptionModification = (state) => get(getGroupModifications(state), 'newGroupDescription', '');
