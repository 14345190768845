import { RootState } from '../../../../configuration/setup/store';

export const getUserSidebar = (state: RootState) => state.userSidebar;
export const getSelectedUser = (state: RootState) => getUserSidebar(state).selectedUser;
export const getFirstNameInSidebar = (state: RootState) => getSelectedUser(state).firstName;
export const getLastNameInSidebar = (state: RootState) => getSelectedUser(state).lastName;
export const getEmailInSidebar = (state: RootState) => getSelectedUser(state).email;
export const getPhoneNumberInSidebar = (state: RootState) => getSelectedUser(state).phoneNumber;
export const getUserGroupsInSidebar = (state: RootState) => getSelectedUser(state).groups;

export const getShowConfirmation = (state: RootState) => getUserSidebar(state).showConfirmation;
export const getShowDeleteConfirmation = (state: RootState) => getUserSidebar(state).showDeleteConfirmation;

export const namesInSidebarNotEmpty = (state: RootState) =>
    getFirstNameInSidebar(state).length !== 0 && getLastNameInSidebar(state).length !== 0;

export const groupsInSidebarNotEmpty = (state: RootState) => {
    const groups = getUserGroupsInSidebar(state);
    return groups ? groups.length > 0 : true; // groups is initially undefined, so we return true to not block the user from saving changes to user name
};
