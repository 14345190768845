import { memo, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

interface Props {
    fieldError?: ReactNode;
    fieldLabel: ReactNode;
    fieldName: string;
    onChange?: (arg0: any) => void;
    value?: string;
    icon?: string;
    readOnly?: boolean;
    mailToEmail?: string; // TODO what is this used for?
}

export const FormInputGroup = memo((props: Props) => {
    const [touched, toggleTouch] = useState(false);

    const { fieldName, fieldLabel, fieldError, icon, value, onChange, readOnly, mailToEmail } = props;

    const intl = useIntl();

    return (
        <div className={classNames('form-group', touched && fieldError && 'has-feedback has-error')}>
            <label className={'control-label'} htmlFor={fieldName}>
                {fieldLabel}
            </label>
            <div className={'input-group position-relative width-100pct'}>
                {icon && (
                    <span className={'input-group-addon'}>
                        <span className={icon} />
                    </span>
                )}
                <input
                    className={'form-control'}
                    name={fieldName}
                    onChange={onChange ? (evt) => onChange(evt.target.value) : () => {}}
                    readOnly={readOnly}
                    type={'text'}
                    onBlur={() => toggleTouch(true)}
                    value={value}
                />
                {mailToEmail && (
                    <span className={'input-group-btn'}>
                        <a
                            className={'btn btn-default btn-icon-only'}
                            href={mailToEmail}
                            type={'button'}
                            title={intl.formatMessage({ id: 'users.sidebar.send.email' })}
                        >
                            <span className={'rioglyph rioglyph-send'} />
                        </a>
                    </span>
                )}
                {touched && fieldError && <span className={'form-control-feedback rioglyph rioglyph-warning-sign'} />}
            </div>
            {touched && fieldError && (
                <span className={'help-block'}>
                    <span>{fieldError}</span>
                </span>
            )}
        </div>
    );
});

FormInputGroup.displayName = 'FormInputGroup';
