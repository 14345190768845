import {
    getInviteFirstName,
    getInviteLastName,
    getInviteLogin,
    getInvitePhoneNumber,
    getInvitePreferredLogin,
} from './inviteUserSelectors';
import {
    getInviteFirstNameError,
    getInviteLastNameError,
    getInvitePhoneNumberError,
    getLoginError,
} from './inviteUserInputValidation';
import { State } from '../../../../setup/types';
import { PreferredLogin } from './inviteUserTypes';
import { Dispatch } from '@reduxjs/toolkit';
import { UserFormDispatchProps, UserFormStateProps } from '../usersidebar/UserFormContainer';
import inviteUserSlice from './inviteUserSlice';

export const mapStateToInviteUserFormProps = (state: State): UserFormStateProps => ({
    readOnly: false,
    isCreationDialog: true,
    loginIsReadOnly: false,
    phoneNumberIsReadOnly: false,
    firstName: getInviteFirstName(state),
    lastName: getInviteLastName(state),
    email: undefined,
    phoneNumber: getInvitePhoneNumber(state),
    preferredLogin: getInvitePreferredLogin(state),
    login: getInviteLogin(state),
    phoneNumberError: getInvitePhoneNumberError(state),
    loginError: getLoginError(state),
    lastNameError: getInviteLastNameError(state),
    firstNameError: getInviteFirstNameError(state),
});
export const mapDispatchToInviteUserFormProps = (dispatch: Dispatch): UserFormDispatchProps => ({
    onChangeFirstName: (firstName: string) => dispatch(inviteUserSlice.actions.setNewInviteFirstName(firstName)),
    onChangeLastName: (lastName: string) => dispatch(inviteUserSlice.actions.setNewInviteLastName(lastName)),
    onChangeLogin: (login: string) => dispatch(inviteUserSlice.actions.setNewLogin(login)),
    onChangePreferredLogin: (preferredLogin: PreferredLogin) =>
        dispatch(inviteUserSlice.actions.setNewInvitePreferredLogin(preferredLogin)),
});
