import { submitInviteUser } from './inviteUserThunks';
import { fireTrackingEvent, TRACKING_CATEGORIES, USER_TRACKING_ACTIONS } from '../../../../tracking/gtm-helper';
import { selectShowCreateUserModal } from '../../../../routes/selector';
import { closeCreateUserModal } from '../../../../routes/actions';
import { getInviteInProgress } from './inviteUserSelectors';
import { canSubmitInviteUser } from './inviteUserInputValidation';
import inviteUserSlice from './inviteUserSlice';
import { AppDispatch, RootState } from '../../../../configuration/setup/store';

export const mapStateToInviteUserProps = (state: RootState, ownProps: any) => ({
    canSubmit: !getInviteInProgress(state) && canSubmitInviteUser(state),
    showInvite: selectShowCreateUserModal(ownProps),
});

export const mapDispatchToInviteUserProps = (dispatch: AppDispatch, ownProps: any) => ({
    handleCancel: () => {
        closeCreateUserModal(ownProps);
        dispatch(inviteUserSlice.actions.resetInviteState());
    },
    handleSubmitForm: () => {
        fireTrackingEvent(TRACKING_CATEGORIES.USERADMIN_USER, USER_TRACKING_ACTIONS.SAVE_CREATED_USER);
        dispatch(submitInviteUser(ownProps));
    },
    handleUserGroupsSelectionForInvite: (groups: string[]) =>
        dispatch(inviteUserSlice.actions.setInviteUserGroupIds(groups)),
});
