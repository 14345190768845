import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import PropTypes from 'prop-types';
import React from 'react';

import { SIDEBAR_BREAKPOINT_FLY, isLargeScreen } from './responsive';

const SIDEBAR_WIDTH_DEFAULT = '500px';

export class SidebarShell extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isFly: false,
        };
    }

    componentDidMount() {
        this.setSidebarMode();
    }

    setSidebarMode() {
        this.setState({
            isFly: !isLargeScreen(),
        });
    }

    render() {
        const { children, footer, handleClose, header, title, className } = this.props;

        const { isFly } = this.state;

        const sidebarHeader = (
            <div className={'row margin-bottom-25'}>
                <div className={'col-xs-12'}>{header}</div>
            </div>
        );

        return (
            <ApplicationLayout.Sidebar className={'right'}>
                <Sidebar
                    className={className}
                    bodyClassName={'padding-20'}
                    closed={false}
                    fly={isFly}
                    footer={footer}
                    onClose={handleClose}
                    switchModeBreakpoint={SIDEBAR_BREAKPOINT_FLY}
                    title={title}
                    titleClassName={'padding-left-10'}
                    width={SIDEBAR_WIDTH_DEFAULT}
                >
                    {sidebarHeader}
                    {children}
                </Sidebar>
            </ApplicationLayout.Sidebar>
        );
    }
}

SidebarShell.propTypes = {
    footer: PropTypes.node.isRequired,
    handleClose: PropTypes.func.isRequired,
    header: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
};
