import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const OverlayTooltip = (props) => {
    return (
        <OverlayTrigger
            placement={props.placement}
            trigger={props.trigger}
            overlay={
                <Tooltip id={'tooltip'} className={props.tooltipPlacement}>
                    <FormattedMessage id={props.popoverTooltipMessage} />
                </Tooltip>
            }
        >
            <span
                className={classNames('rioglyph rioglyph-question-sign margin-left-5 text-size-16 text-muted')}
                aria-hidden={'true'}
            />
        </OverlayTrigger>
    );
};

OverlayTooltip.propTypes = {
    popoverTooltipMessage: PropTypes.string.isRequired,
    trigger: PropTypes.oneOf(['hover', 'click', 'focus']),
    placement: PropTypes.oneOf(['right', 'left', 'top', 'bottom']),
    tooltipPlacement: PropTypes.oneOf(['right', 'left', 'top', 'bottom', 'right-top', 'top-right']),
};

OverlayTooltip.defaultProps = {
    trigger: 'click',
    placement: 'right',
    tooltipPlacement: 'right',
};
