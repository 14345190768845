import { getRoles, getRolesOfSelectedGroup } from '../appSelectors';
import { getCreateGroupRolesToAdd } from '../groups/creategroup/createGroupSelectors';
import { getGroupRolesToAdd } from '../groups/groupsidebar/groupSidebarSelectors';
import { getRoleDisplayName } from '../meta';

export const extractSuggestions = (alreadySelectedRoles, roles, rolesToAdd) => {
    const idsOfRolesToAdd = rolesToAdd.map((role) => role.id);
    const notSelectedRoles = roles
        .filter((role) => !alreadySelectedRoles.includes(role.id))
        .filter((role) => !idsOfRolesToAdd.includes(role.id));

    const suggestions = notSelectedRoles.map((role) => ({
        label: getRoleDisplayName(role),
        id: role.id,
        type: role.key,
    }));

    return { suggestions };
};

export const collectRolesForGroupInGroupSidebar = (state) => {
    const alreadySelectedRoles = getRolesOfSelectedGroup(state).map((role) => role.role_id);
    const roles = getRoles(state);
    const rolesToAdd = getGroupRolesToAdd(state);

    return extractSuggestions(alreadySelectedRoles, roles, rolesToAdd);
};

export const collectRolesForGroupInCreateGroup = (state) => {
    const roles = getRoles(state);
    const rolesToAdd = getCreateGroupRolesToAdd(state);

    return extractSuggestions([], roles, rolesToAdd);
};
