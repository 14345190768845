import Switch from '@rio-cloud/rio-uikit/Switch';
import { useEffect } from 'react';
import { setModifiedDriverSelfInvitation } from './accountSettingsSlice';
import { getAccountSettings, saveDriverSelfRegistrationAccountSettings } from './accountSettingsThunks';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { setActiveInfoModalChapterNew } from '../../../routes/actions';
import { Chapter } from '../infoModal/Chapter';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { RootState } from '../../../configuration/setup/store';

export const AccountSettingsContainer = () => {
    const dispatch = useAppDispatch();

    const { driverSelfRegistration, modifiedDriverSelfRegistration } = useAppSelector((state: RootState) => ({
        driverSelfRegistration: state.accountSettings.settings.userSelfInvitation.driver,
        modifiedDriverSelfRegistration: state.accountSettings.modifiedSettings.userSelfInvitation.driver,
        pending: state.accountSettings.pending,
    }));

    useEffect(() => {
        dispatch(getAccountSettings());
    }, [dispatch]);

    const handleToggle = (isToggled: boolean) => {
        const featureStatus = isToggled ? 'enabled' : 'disabled';
        dispatch(setModifiedDriverSelfInvitation(featureStatus));
        dispatch(saveDriverSelfRegistrationAccountSettings(featureStatus));
    };

    const location = useLocation();
    const push = useNavigate();
    const handleDriverHelp = () => setActiveInfoModalChapterNew(Chapter.DRIVER_SELF_REGISTRATION, push, location);

    return (
        <div className={'panel panel-default panel-body'}>
            <h5>
                <FormattedMessage id={'accountSettings.settings.userSelfInvitation.subtitle'} />
            </h5>

            <div className={'panel panel-default panel-body margin-top-10 margin-bottom-20'}>
                <div>
                    <Switch
                        onChange={handleToggle}
                        checked={
                            modifiedDriverSelfRegistration
                                ? modifiedDriverSelfRegistration === 'enabled'
                                : driverSelfRegistration === 'enabled'
                        }
                        minWidth={50}
                        enabledText={'ON'}
                        disabledText={'OFF'}
                        labelPosition={'left'}
                    >
                        <span className={'text-size-16 text-color-darkest margin-right-10'}>
                            <FormattedMessage id={'accountSettings.settings.userSelfInvitation.driver'} />
                        </span>
                    </Switch>
                </div>
                <div className={'text-color-darker margin-top-10 margin-left-10'}>
                    <FormattedMessage id={'accountSettings.settings.userSelfInvitation.driver.shortHelp'} />
                    <br />
                    <FormattedMessage id={'accountSettings.settings.userSelfInvitation.driver.help.click'} />
                    <span
                        className={'rioglyph rioglyph-question-sign text-color-gray margin-left-5 text-size-18'}
                        aria-hidden={'true'}
                        style={{ cursor: 'pointer' }}
                        onClick={handleDriverHelp}
                    />
                </div>
            </div>

            {/*<div className={'panel panel-default panel-body margin-top-10 margin-bottom-20'}>
                <div className={'form-group'}>
                    <label className={'control-label col-lg-2 text-size-16 text-color-darker'}>
                        <FormattedMessage id={'accountSettings.settings.userSelfInvitation.driver'} />
                    </label>
                    <div className={'col-lg-2'}>
                        <select
                            className={'form-control'}
                            value={
                                modifiedDriverSelfRegistration ? modifiedDriverSelfRegistration : driverSelfRegistration
                            }
                            onChange={(event) => console.log('TODO:', event.currentTarget.value)}
                        >
                            <option value={'enabled'}>Enabled</option>
                            <option value={'disabled'}>Disabled</option>
                        </select>
                    </div>
                </div>
                <br />

                <div className={'text-color-darker margin-top-10 margin-left-10'}>
                    <FormattedMessage id={'accountSettings.settings.userSelfInvitation.driver.shortHelp'} />
                    <br />
                    <FormattedMessage id={'accountSettings.settings.userSelfInvitation.driver.help.click'} />
                    <span
                        className={'rioglyph rioglyph-question-sign text-color-gray margin-left-5 text-size-18'}
                        aria-hidden={'true'}
                        style={{ cursor: 'pointer' }}
                        onClick={handleDriverHelp}
                    />
                </div>
            </div>*/}
        </div>
    );
};
