import { FormattedMessage } from 'react-intl';
import { RoleAccessRights } from './RoleAccessRights';
import { AvailableRole, availableRole } from './roleDescriptionSlice';
import { RoleContentData, ServiceDescription } from './roleDescriptionContent';

const generateList = (listContent: ServiceDescription[]) => (
    <ul className={'list-group'}>
        {listContent.map((serviceDescription) => (
            <li className={'list-group-item'} key={serviceDescription.service}>
                <span>
                    <FormattedMessage id={serviceDescription.service} />
                </span>
                <RoleAccessRights
                    access={serviceDescription.description.access}
                    details={serviceDescription.description.details}
                />
            </li>
        ))}
    </ul>
);

interface Props {
    content: RoleContentData;
    switchRole: (role: AvailableRole) => void;
}

export const RoleDescription = ({ content, switchRole }: Props) => (
    <div>
        <button type="button" className="btn btn-default" onClick={() => switchRole(availableRole.OVERVIEW)}>
            <span className="rioglyph rioglyph-chevron-left" aria-hidden="true" />
            <span>
                <FormattedMessage id={'infoDialog.concepts.roles.back'} />
            </span>
        </button>
        <h4>
            <FormattedMessage id={content.titleId} />
        </h4>
        <p>{<FormattedMessage id={content.generalDescription} />}</p>
        <p>{<FormattedMessage id={content.scopeType} />}</p>
        <div className={'padding-top-5'}>
            {generateList(content.serviceDescriptions!)}
            {content.adminServiceDescriptions && (
                <h5>
                    <FormattedMessage id={'roles.description.adminServices'} />
                </h5>
            )}
            {content.adminServiceDescriptions ? (
                generateList(content.adminServiceDescriptions)
            ) : (
                <FormattedMessage id={'roles.description.noAdminServices'} />
            )}
        </div>
        <p className={'pull-right text-color-dark text-light'}>
            {<FormattedMessage id={'roles.description.footnote'} />}
        </p>
    </div>
);
