import { config } from '../../config';
import { trace } from './trace';
import { routeStorage } from '../login/storage';
import { reportErrorToSentry } from './sentry';
import { UserManager } from 'oidc-client-ts';

const saveCurrentRoute = () => {
    const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#\/?/u, '');

    if (initialRoute) {
        routeStorage.saveRoute(initialRoute);
        trace('saving initial route', initialRoute);
    }
};

export const attemptInitialSignIn = async (userManager: UserManager) => {
    const isFreshRedirect = window.location.href.includes('redirected');

    try {
        const user = await userManager.signinSilent();
        const initialRoute = routeStorage.getRoute();

        trace('initialRoute lookup', initialRoute);
        if (initialRoute && isFreshRedirect) {
            trace(`redirecting to "#/${initialRoute}"`);
            window.location.replace(`#/${initialRoute}`);
        } else if (isFreshRedirect) {
            trace('no route stored - redirecting to base url');
            window.location.replace('#/users');
        }
        routeStorage.discardRoute();

        return user;
    } catch (error) {
        trace('oidc.signinSilent failed, trying page redirect...', error);

        if (config.login.preventRedirect) {
            // eslint-disable-next-line no-console
            console.warn('[feature/login] redirect prevented due to config. Error was', error);
        } else if (isFreshRedirect) {
            trace('oidc.signinSilent.error', 'redirect prevented due to suspicious signin error', error);
            routeStorage.discardRoute();
            reportErrorToSentry(error);
        } else {
            saveCurrentRoute();
            await userManager.signinRedirect();
            // signinRedirect sets window.location - Until the new page is loaded, code will still be executed, and
            // there is no easy way to await the page load.
            // Therefore, we return early here to ensure that no code is executed after the signinRedirect call
            return;
        }

        trace('auth problem?', error);
        return Promise.reject(new Error(`Need to sign in`));
    }
};
