import { getAccessIsForbidden, getSelectedGroup, getServerErrorCode } from '../appSelectors';
import { isAppInMaintenanceMode } from '../../selectors';
import { accountType, getAccountType } from '../../../configuration/login/loginSlice';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectGroupSearch, selectGroupSort } from '../../../routes/selector';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { ApplicationHeaderContainer } from '../../../components/ApplicationHeader';
import { GroupSidebarContainer } from './groupsidebar/GroupSidebarContainer';
import { AccessNotAllowed } from '../common/AccessNotAllowed';
import { GenericErrorState } from '../common/GenericErrorState';
import { GroupTableWrapperContainer } from './grouptable/GroupTableWrapperContainer';
import { DEFAULT_ROUTE } from '../../../components/shell/Shell';
import { useEffect } from 'react';
import { fetchDataForGroupTab, fetchGroupsAccordingToSearch } from './groupstab/groupTabThunks';
import { MaintenanceBanner } from '../../../components/MaintenanceBanner';

export const Groups = () => {
    const dispatch = useAppDispatch();

    const selectedGroup = useAppSelector(getSelectedGroup);
    const serverErrorCode = useAppSelector(getServerErrorCode);
    const accessIsForbidden = useAppSelector(getAccessIsForbidden);
    const maintenanceMode = useAppSelector(isAppInMaintenanceMode);
    const isCarrierAccount = useAppSelector((state) => getAccountType(state) === accountType.CARRIER);

    const location = useLocation();
    const params = useParams();
    const push = useNavigate();

    const groupSearch = selectGroupSearch({ location, params, push });
    const groupSort = selectGroupSort({ location, params, push });

    useEffect(() => {
        fetchDataForGroupTab({ location, params, push })(dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchGroupsAccordingToSearch({ location, params, push })(dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupSearch, groupSort]);

    return isCarrierAccount ? (
        <ApplicationLayout>
            <ApplicationHeaderContainer />
            {selectedGroup && <GroupSidebarContainer maintenanceMode={maintenanceMode} />}
            <ApplicationLayout.Body className={'UserAdmin responsive'} banner={<MaintenanceBanner />}>
                {accessIsForbidden ? (
                    <AccessNotAllowed />
                ) : serverErrorCode ? (
                    <GenericErrorState />
                ) : (
                    <GroupTableWrapperContainer />
                )}
            </ApplicationLayout.Body>
        </ApplicationLayout>
    ) : (
        <Navigate to={DEFAULT_ROUTE} />
    );
};
