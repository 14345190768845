import { QUERY_PARAMETER, removeQueryParameter, updateQueryParameter } from './routeParser';
import { historyPushLocation, RouteComponentProps } from './routerUtils';
import { Location, NavigateFunction } from 'react-router-dom';

export function getUrlParameter(chapter: string): string {
    return chapter.split('infoDialog.')[1];
}

export function setActiveInfoModalChapter(chapter: string, props: RouteComponentProps) {
    historyPushLocation(props.push, {
        pathname: props.location.pathname,
        search: updateQueryParameter(props.location.search, QUERY_PARAMETER.INFO_MODAL, getUrlParameter(chapter)),
    });
}

export function setActiveInfoModalChapterNew(chapter: string, push: NavigateFunction, location: Location) {
    historyPushLocation(push, {
        pathname: location.pathname,
        search: updateQueryParameter(location.search, QUERY_PARAMETER.INFO_MODAL, getUrlParameter(chapter)),
    });
}

export function closeInfoModal(props: RouteComponentProps) {
    historyPushLocation(props.push, {
        pathname: props.location.pathname,
        search: removeQueryParameter(props.location.search, QUERY_PARAMETER.INFO_MODAL),
    });
}

export function showCreateUserModal(props: RouteComponentProps) {
    historyPushLocation(props.push, {
        pathname: props.location.pathname,
        search: updateQueryParameter(props.location.search, QUERY_PARAMETER.CREATE_USER_MODAL, 'show'),
    });
}

export function closeCreateUserModal(props: RouteComponentProps) {
    historyPushLocation(props.push, {
        pathname: props.location.pathname,
        search: removeQueryParameter(props.location.search, QUERY_PARAMETER.CREATE_USER_MODAL),
    });
}

export function showCreateGroupModal(props: RouteComponentProps) {
    historyPushLocation(props.push, {
        pathname: props.location.pathname,
        search: updateQueryParameter(props.location.search, QUERY_PARAMETER.CREATE_GROUP_MODAL, 'show'),
    });
}

export function closeCreateGroupModal(props: RouteComponentProps) {
    historyPushLocation(props.push, {
        pathname: props.location.pathname,
        search: removeQueryParameter(props.location.search, QUERY_PARAMETER.CREATE_GROUP_MODAL),
    });
}

export function restrictSelectionToUser(userId: string, props: RouteComponentProps) {
    historyPushLocation(props.push, {
        pathname: `users/${userId}`,
        search: props.location.search,
    });
}

export function restrictSelectionToGroup(groupId: string, props: RouteComponentProps) {
    historyPushLocation(props.push, {
        pathname: `groups/${groupId}`,
        search: props.location.search,
    });
}

export function deselectUser(props: RouteComponentProps) {
    historyPushLocation(props.push, {
        pathname: 'users',
        search: props.location.search,
    });
}

export function deselectGroup(props: RouteComponentProps) {
    historyPushLocation(props.push, {
        pathname: 'groups',
        search: props.location.search,
    });
}

export function changeUserSearch(props: RouteComponentProps, query: string) {
    historyPushLocation(props.push, {
        pathname: props.location.pathname,
        search: updateQueryParameter(props.location.search, QUERY_PARAMETER.USER_SEARCH, query),
    });
}

export function changeGroupSearch(props: RouteComponentProps, query: string) {
    historyPushLocation(props.push, {
        pathname: props.location.pathname,
        search: updateQueryParameter(props.location.search, QUERY_PARAMETER.GROUP_SEARCH, query),
    });
}

export function changeUserSort(props: RouteComponentProps, sortType: string) {
    historyPushLocation(props.push, {
        pathname: props.location.pathname,
        search: updateQueryParameter(props.location.search, QUERY_PARAMETER.USER_SORT, sortType),
    });
}

export function changeGroupSort(props: RouteComponentProps, sortType: string) {
    historyPushLocation(props.push, {
        pathname: props.location.pathname,
        search: updateQueryParameter(props.location.search, QUERY_PARAMETER.GROUP_SORT, sortType),
    });
}
