import { fetchGroups, fetchUser, fetchUsers } from '../../../api/api';
import { ERROR_NOTIFICATION } from '../../../notifications/notificationType';
import { getErrorMessageKey } from '../../common/commonFunctions';
import { getUsers, getUsersLoadMoreLink } from '../../appSelectors';
import { DEFAULT_LIMIT_FOR_BACKEND_FETCHES, USERS_PAGE_SIZE } from '../../../../constants';
import { selectUserIdOfSelectedUser, selectUserSearch, selectUserSort } from '../../../../routes/selector';
import { AppDispatch } from '../../../../setup/types';
import { UsersAndLoadMore } from '../../../api/types';
import { User } from '../../appTypes';
import { RouteComponentProps } from '../../../../routes/routerUtils';
import { RootState } from '../../../../configuration/setup/store';
import appSlice from '../../appSlice';
import notificationSlice from '../../../notifications/notificationSlice';

export const fetchDataForUsersTab = (props: RouteComponentProps) => (dispatch: AppDispatch) => {
    const userSearch = selectUserSearch(props);
    const userSort = selectUserSort(props);
    const userIdOfUserToSelect = selectUserIdOfSelectedUser(props);

    const requests: [Promise<any>, Promise<UsersAndLoadMore>, Promise<User>?] = [
        fetchGroups({ limit: DEFAULT_LIMIT_FOR_BACKEND_FETCHES }),
        fetchUsers({
            limit: USERS_PAGE_SIZE,
            search: userSearch,
            userSortBy: userSort ? userSort.substring(1) : '',
            userSortByAsc: userSort ? userSort.charAt(0) === '+' : true,
        }),
    ];

    if (userIdOfUserToSelect) {
        requests.push(fetchUser(userIdOfUserToSelect));
    }

    dispatch(appSlice.actions.isLoading({ isLoading: true }));

    Promise.all<any>(requests)
        .then((result) => {
            const [groupsResponse, usersResponse, selectedUser] = result;
            dispatch(
                appSlice.actions.groupsFetched({
                    groups: groupsResponse.groups,
                    loadMoreLink: groupsResponse.loadMoreLink,
                    allowedActions: groupsResponse.allowedActions,
                })
            );
            dispatch(
                appSlice.actions.usersFetched({
                    users: usersResponse.users,
                    loadMoreLink: usersResponse.loadMoreLink,
                    allowedActions: usersResponse.allowedActions,
                })
            );
            if (selectedUser) {
                dispatch(appSlice.actions.selectedUserFetched(selectedUser));
            }
            dispatch(appSlice.actions.isLoading({ isLoading: false }));
        })
        .catch((error) => {
            // api.reportError(error);

            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: ERROR_NOTIFICATION,
                    notificationKey: getErrorMessageKey(error, 'users.error.notAllDataRetrievable'),
                })
            );
            dispatch(appSlice.actions.isLoading({ isLoading: false, error }));
        });
};

export const fetchUsersThunk = (props: RouteComponentProps) => (dispatch: AppDispatch) => {
    const userSort = selectUserSort(props);
    const userSearch = selectUserSearch(props);

    dispatch(appSlice.actions.isLoading({ isLoading: true }));

    fetchUsers({
        limit: USERS_PAGE_SIZE,
        search: userSearch,
        userSortBy: userSort ? userSort.substring(1) : '',
        userSortByAsc: userSort ? userSort.charAt(0) === '+' : true,
    })
        .then((fetched) => {
            dispatch(appSlice.actions.isLoading({ isLoading: false }));
            dispatch(
                appSlice.actions.usersFetched({
                    users: fetched.users,
                    loadMoreLink: fetched.loadMoreLink,
                    allowedActions: fetched.allowedActions,
                })
            );
        })
        .catch((error) => {
            dispatch(appSlice.actions.isLoading({ isLoading: false, error }));
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationKey: ERROR_NOTIFICATION,
                    notificationType: 'users.error.notAllDataRetrievable',
                })
            );
            return Promise.reject(error);
        });
};

export const loadMoreUsers = (dispatch: AppDispatch, getState: () => RootState) => {
    const usersLoadMoreLink = getUsersLoadMoreLink(getState());

    const mergeMore = (data: UsersAndLoadMore): UsersAndLoadMore => {
        const oldUsers = getUsers(getState());
        const { loadMoreLink, users, allowedActions } = data;

        return {
            loadMoreLink,
            users: [...oldUsers, ...users],
            allowedActions,
        };
    };

    return fetchUsers({ rawUrl: usersLoadMoreLink! }) // TODO check if assertion is justified
        .then(mergeMore)
        .then((data) =>
            dispatch(
                appSlice.actions.usersFetched({
                    users: data.users,
                    loadMoreLink: data.loadMoreLink,
                    allowedActions: data.allowedActions,
                })
            )
        )
        .catch(() => {
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: ERROR_NOTIFICATION,
                    notificationKey: 'loadMore.error.noItemsRetrievable',
                })
            );
        });
};
