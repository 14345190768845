import { ApplicationHeaderContainer } from '../../../components/ApplicationHeader';
import { AccountSettingsContainer } from './AccountSettingsContainer';
import { FormattedMessage } from 'react-intl';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { accountType, getAccountType } from '../../../configuration/login/loginSlice';
import { Navigate } from 'react-router-dom';
import { DEFAULT_ROUTE } from '../../../components/shell/Shell';

export const AccountSettings = () => {
    const isCarrierAccount = useAppSelector((state) => getAccountType(state) === accountType.CARRIER);

    return isCarrierAccount ? (
        <ApplicationLayout>
            <ApplicationHeaderContainer />
            <ApplicationLayout.Body className={'UserAdmin responsive'}>
                <>
                    <h4 className={'margin-left-5'}>
                        <FormattedMessage id={'accountSettings.title'} />
                    </h4>
                    <AccountSettingsContainer />
                </>
            </ApplicationLayout.Body>
        </ApplicationLayout>
    ) : (
        <Navigate to={DEFAULT_ROUTE} />
    );
};
