import { RoleContent } from './roledescription/roleDescriptionContent';
import { FormattedMessage } from 'react-intl';
import {
    AvailableRole,
    getRoleDescriptionSelectedRole,
    selectRoleDescription,
} from './roledescription/roleDescriptionSlice';
import { RoleDescription } from './roledescription/RoleDescriptionDetails';
import { RoleDescriptionTable } from './roledescription/RoleDescriptionTable';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';

export const UserGroupRolesExplanation = () => {
    const selectedRole = useAppSelector(getRoleDescriptionSelectedRole);

    const dispatch = useAppDispatch();
    const switchRole = (role: AvailableRole) => dispatch(selectRoleDescription(role));

    if (selectedRole !== 'Overview') {
        return <RoleDescription content={RoleContent[selectedRole]} switchRole={switchRole} />;
    }
    return (
        <>
            <h4 className="margin-bottom-10">
                <FormattedMessage id={'roles.available'} />
            </h4>
            <div>
                <FormattedMessage id={'header.info.entities.usergrouproles.linkText'} />{' '}
                <FormattedMessage id={'header.info.entities.usergrouproles.text'} />
            </div>
            <RoleDescriptionTable fullContent={RoleContent} switchRoleFn={switchRole} />
        </>
    );
};
