import {
    ApiGroup,
    ApiRole,
    ApiUser,
    RoleApiResponse,
    UserGroupsApiResponse,
    UsersApiResponse,
} from './userAdminBackendCodec';
import { AllowedAction, isAllowedAction, User, UserGroup, UserGroupRole } from '../app/appTypes';
import { ApiTag } from './tagBackendCodec';
import { Tag } from './types';
import { ApiAccountSetting, KnownDriverSelfRegistrationValues } from './accountSettingsBackendCodec';
import { AccountSetting } from '../app/accountSettings/types';

export const mapApiUserToUser = (apiUser: ApiUser): User => {
    return {
        id: apiUser.id,
        account_id: apiUser.account_id,
        first_name: apiUser.first_name,
        last_name: apiUser.last_name,
        email: apiUser.email ? apiUser.email : null,
        additional_authentication_factors: apiUser.additional_authentication_factors,
        phone_number: apiUser.phone_number ? apiUser.phone_number : null,
        group_ids: apiUser.group_ids,
        preferred_login: apiUser.preferred_login === 'phone' ? 'phone' : 'email',
        preferred_language: apiUser.preferred_language,
        metadata: apiUser.metadata
            ? {
                  created: apiUser.metadata.created as string, // TODO is this cast reasonable?
                  tenant: apiUser.metadata.tenant === 'prod' ? 'prod' : 'prod', // TODO skip the ternary?
              }
            : undefined,
    };
};

export const mapApiGroupToUserGroup = (apiGroup: ApiGroup): UserGroup => {
    return {
        id: apiGroup.id,
        name: apiGroup.name,
        description: apiGroup.description ? apiGroup.description : '',
        assigned_roles: apiGroup.assigned_roles,
        metadata: {
            allowed_actions: apiGroup.metadata.allowed_actions,
            created: apiGroup.metadata.created as string,
            tenant: apiGroup.metadata.tenant === 'prod' ? 'prod' : 'prod', // TODO skip this ternary?
        },
    };
};

export const mapApiRoleToUserGroupRole = (apiRole: ApiRole): UserGroupRole => ({
    id: apiRole.id,
    key: `roles.names.${apiRole.name}`,
    name: apiRole.name,
    scope_type: apiRole.scope_type === 'account' ? 'account' : 'tag', // TODO this is a bit hacky
});

export const mapToLoadMore = (data: UsersApiResponse | UserGroupsApiResponse): string | undefined =>
    data._links.next && data._links.next.href;

export const mapToUsers = (data: UsersApiResponse): Array<User> => data.items.map(mapApiUserToUser);

export const mapToGroups = (data: UserGroupsApiResponse): Array<UserGroup> => data.items.map(mapApiGroupToUserGroup);

export const mapToUserGroupRole = (data: RoleApiResponse): Array<UserGroupRole> =>
    data.items.map(mapApiRoleToUserGroupRole);

export const mapApiTagToTag = (apiTag: ApiTag): Tag => ({
    id: apiTag.id,
    type: 'user', // TODO is this really the only possible response
    name: apiTag.name,
    accountId: apiTag.account_id,
});

export const mapApiAccountSettingToAccountSetting = (
    apiAccountSetting: ApiAccountSetting
): AccountSetting | undefined => {
    if (apiAccountSetting.id === 'user-self-registration') {
        return {
            id: apiAccountSetting.id,
            group: 'iam',
            values: {
                driver_self_registration: apiAccountSetting.values
                    .driver_self_registration as KnownDriverSelfRegistrationValues,
            },
        };
    }
    return;
};

export const mapToAllowedActions = (rawAllowedActions: string[] | null | undefined): AllowedAction[] | null => {
    if (rawAllowedActions === null || rawAllowedActions === undefined) {
        return null;
    } else {
        return rawAllowedActions.filter(isAllowedAction);
    }
};
