import get from 'lodash/get';
import { getRoles, getSelectedGroup, getTags } from '../appSelectors';
import { getCreateGroupRestrictionsToAdd, getCreateGroupRolesToAdd } from '../groups/creategroup/createGroupSelectors';
import {
    getGroupRestrictionsToAdd,
    getGroupRestrictionsToRemove,
    getGroupRolesToAdd,
    getGroupRolesToRemove,
} from '../groups/groupsidebar/groupSidebarSelectors';
import { getRoleDisplayName } from '../meta';

const getAssignedRoles = (state) => get(getSelectedGroup(state), 'assigned_roles', []);

export const getViewModelOfTag = (tagId, roleId, allTags) => {
    const [tag] = allTags.filter((tagData) => tagData.id === tagId);

    const displayName = tag ? tag.name : 'Unknown';

    return displayName
        ? {
              id: tagId,
              icon: 'role-management',
              parentId: roleId,
              shouldBeAdded: false,
              shouldBeRemoved: false,
              displayName: displayName,
          }
        : null;
};

export const getViewModelOfRole = (role, allRoles, allTags) => {
    const [fullRoleData] = allRoles.filter((roleData) => roleData.id === role.role_id);
    return {
        id: fullRoleData ? fullRoleData.id : 'otherThing',
        name: fullRoleData ? fullRoleData.name : 'unknownRole',
        shouldBeAdded: false,
        shouldBeRemoved: false,
        displayName: getRoleDisplayName(fullRoleData),
        subItems:
            role.scope.identifier_type === 'tag'
                ? role.scope.identifiers.map((tagId) => getViewModelOfTag(tagId, role.role_id, allTags))
                : [],
        canNotHaveSubItems: fullRoleData ? fullRoleData.scope_type === 'account' : false,
    };
};

export const getViewModelOfRoleToAdd = (roleToAdd, allRoles) => {
    const [fullRoleData] = allRoles.filter((roleData) => roleData.id === roleToAdd.id);
    return {
        id: fullRoleData.id,
        name: fullRoleData.name,
        shouldBeAdded: true,
        shouldBeRemoved: false,
        displayName: getRoleDisplayName(fullRoleData),
        subItems: [],
        canNotHaveSubItems: fullRoleData.scope_type === 'account',
    };
};

export const markRolesToBeRemoved = (role, roleIdsToBeRemoved) => {
    const roleShouldBeRemoved = roleIdsToBeRemoved.includes(role.id);
    if (!roleShouldBeRemoved) {
        return role;
    }
    return {
        ...role,
        shouldBeRemoved: true,
        subItems: [],
    };
};

export const markTagsToBeRemoved = (role, allRestrictionsToRemove) => {
    const relevantForRole = allRestrictionsToRemove.filter((restriction) => restriction.parentId === role.id);
    const tagIdsToRemove = relevantForRole.map((restriction) => restriction.id);

    if (tagIdsToRemove.length === 0) {
        return role;
    }

    const markedTags = role.subItems.map((restriction) =>
        tagIdsToRemove.includes(restriction.id)
            ? {
                  ...restriction,
                  shouldBeRemoved: true,
              }
            : restriction
    );

    return {
        ...role,
        subItems: markedTags,
    };
};

export const addRestrictionsToRole = (role, allRestrictionsToAdd, allTags) => {
    const relevantForRole = allRestrictionsToAdd.filter((restriction) => restriction.parentId === role.id);
    const tagIdsToAdd = relevantForRole.map((restriction) => restriction.id);

    if (tagIdsToAdd.length === 0) {
        return role;
    }

    const tagsToAdd = tagIdsToAdd
        .map((tag) => getViewModelOfTag(tag, role.id, allTags))
        .map((tag) => ({
            ...tag,
            shouldBeAdded: true,
        }));

    return {
        ...role,
        subItems: role.subItems.concat(tagsToAdd),
    };
};

export const roleViewModel = (state) => {
    const assignedRoles = getAssignedRoles(state);
    const allRoles = getRoles(state);
    const allTags = getTags(state);
    const rolesToAdd = getGroupRolesToAdd(state);
    const rolesToBeRemove = getGroupRolesToRemove(state);
    const allRestrictionsToAdd = getGroupRestrictionsToAdd(state);
    const allRestrictionsToRemove = getGroupRestrictionsToRemove(state);

    const roleIdsToBeRemoved = rolesToBeRemove.map((roleToRemove) => roleToRemove.id);

    const roleViewsWithItemsToBeRemovedMarked = assignedRoles
        .map((role) => getViewModelOfRole(role, allRoles, allTags))
        .map((role) => markTagsToBeRemoved(role, allRestrictionsToRemove))
        .map((role) => addRestrictionsToRole(role, allRestrictionsToAdd, allTags))
        .map((role) => markRolesToBeRemoved(role, roleIdsToBeRemoved));

    const newRoles = rolesToAdd
        .map((roleToAdd) => getViewModelOfRoleToAdd(roleToAdd, allRoles))
        .map((roleToAdd) => addRestrictionsToRole(roleToAdd, allRestrictionsToAdd, allTags));

    return roleViewsWithItemsToBeRemovedMarked.concat(newRoles);
};

export const createGroupRoleViewModel = (state) => {
    const allRoles = getRoles(state);
    const rolesToAdd = getCreateGroupRolesToAdd(state);
    const allTags = getTags(state);
    const allRestrictionsToAdd = getCreateGroupRestrictionsToAdd(state);

    return rolesToAdd
        .map((roleToAdd) => getViewModelOfRoleToAdd(roleToAdd, allRoles))
        .map((roleToAdd) => addRestrictionsToRole(roleToAdd, allRestrictionsToAdd, allTags));
};
