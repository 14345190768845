import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { decode } from './iotsDecoder';
import { accountType, AccountType } from '../../configuration/login/loginSlice';

const accountByIdApiResponse = t.type({
    id: t.string,
    name: t.string,
    tenant: t.string,
    life_cycle_state: t.string,
    account_type: t.string,
});

type AccountByIdApiResponse = t.TypeOf<typeof accountByIdApiResponse>;

const decodeAccountByIdApiResponse = (parsedObject: unknown) =>
    decode<AccountByIdApiResponse>(parsedObject, accountByIdApiResponse);

export const processAccountsApiResponse = (rawApiResponse: any) => {
    return pipe(rawApiResponse, decodeAccountByIdApiResponse, (it) => ({
        id: it.id,
        name: it.name,
        accountType: mapStringToAccountType(it.account_type),
    }));
};

const mapStringToAccountType = (rawAccountType: string): AccountType => {
    switch (rawAccountType) {
        case 'BUYER':
            return accountType.BUYER;
        case 'SUPPLIER':
            return accountType.SUPPLIER;
        case 'CARRIER':
        default:
            return accountType.CARRIER;
    }
};
