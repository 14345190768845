import { deleteGroup, saveGroup, saveUser } from '../../../api/api';
import { getSelectedGroup } from '../../appSelectors';
import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from '../../../notifications/notificationType';
import { getErrorMessageKey } from '../../common/commonFunctions';
import { canUpdate } from '../../meta';
import { groupSidebarHasChanges } from './groupPropertiesService';
import { getUpdatedGroupPayloadFromState, getUserChangesPayloadsFromState } from './groupSidebarSaveService';
import { User } from '../../appTypes';
import { deselectGroup } from '../../../../routes/actions';
import { RouteComponentProps } from '../../../../routes/routerUtils';
import { RootState } from '../../../../configuration/setup/store';
import appSlice from '../../appSlice';
import groupSidebarSlice from './groupSidebarSlice';
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import notificationSlice from '../../../notifications/notificationSlice';

export const deleteGroupThunk: ThunkAction<void, RootState, void, any> = (
    dispatch: ThunkDispatch<RootState, void, any>,
    getState: () => RootState
) => {
    const selectedGroup = getSelectedGroup(getState());
    deleteGroup(selectedGroup!.id)
        .then(() => {
            dispatch(groupSidebarSlice.actions.deleteGroupSuccess());
            dispatch(groupSidebarSlice.actions.resetGroupState());
            dispatch(appSlice.actions.groupDeselected());
            dispatch(appSlice.actions.groupDeleted(selectedGroup!.id));
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: SUCCESS_NOTIFICATION,
                    notificationKey: 'groups.edit.groupDeleted',
                })
            );
        })
        .catch((error) => {
            dispatch(groupSidebarSlice.actions.deleteGroupError());
            // api.reportError(error);
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: ERROR_NOTIFICATION,
                    notificationKey: getErrorMessageKey(error, 'groups.edit.error.groupNotDeleted'),
                })
            );
        });
};

export const saveGroupThunk: ThunkAction<void, RootState, void, any> = (dispatch, getState) => {
    const state = getState();

    const canUpdateGroup = canUpdate(getSelectedGroup(state));

    const updatedGroupPayload = getUpdatedGroupPayloadFromState(getState());
    const updateUsersPayload = getUserChangesPayloadsFromState(getState());

    Promise.all<any>([
        canUpdateGroup ? saveGroup(updatedGroupPayload) : Promise.resolve(getSelectedGroup(state)),
        ...updateUsersPayload.map((dto: User) => saveUser(dto)),
    ])
        .then(([groupResponse, ...modifiedUsers]) => {
            dispatch(groupSidebarSlice.actions.saveGroupSuccess());
            const updateGroupObject = {
                group: groupResponse,
                modifiedUsers,
            };

            dispatch(groupSidebarSlice.actions.resetGroupState());
            dispatch(appSlice.actions.updateGroup(updateGroupObject));
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: SUCCESS_NOTIFICATION,
                    notificationKey: 'groups.edit.groupSaved',
                })
            );
        })
        .catch((error) => {
            dispatch(groupSidebarSlice.actions.saveGroupError());
            // api.reportError(error);
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: ERROR_NOTIFICATION,
                    notificationKey: 'groups.edit.error.groupNotSaved',
                })
            );
        });
};

export const confirmDiscardGroupSidebar: ThunkAction<void, RootState, void, any> = (dispatch) => {
    dispatch(groupSidebarSlice.actions.resetGroupState());
    dispatch(appSlice.actions.groupDeselected());
};

export const cancelGroupSidebar =
    (routeProps: RouteComponentProps): ThunkAction<void, RootState, void, any> =>
    (dispatch, getState) => {
        const message = groupSidebarHasChanges(getState());
        if (message) {
            dispatch(groupSidebarSlice.actions.showGroupConfirmation(true));
        } else {
            dispatch(groupSidebarSlice.actions.resetGroupState());
            dispatch(appSlice.actions.groupDeselected());
            deselectGroup(routeProps);
        }
    };
