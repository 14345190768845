import {
    getAdditionalAuthenticationFactorsOfSelectedUser,
    getEmailOfSelectedUser,
    getLoginOfSelectedUser,
    getPhoneNumberOfSelectedUser,
    getPreferredLoginOfSelectedUser,
    getSelectedUser,
} from '../../appSelectors';
import { canUpdate } from '../../meta';
import { isAppInMaintenanceMode } from '../../../selectors';
import { Dispatch } from '@reduxjs/toolkit';
import { UserFormDispatchProps, UserFormStateProps } from './UserFormContainer';
import userSidebarSlice from './userSidebarSlice';
import { getFirstNameInSidebar, getLastNameInSidebar } from './userSidebarSelectors';
import { RootState } from '../../../../configuration/setup/store';

export const mapStateToUserFormProps = (state: RootState): UserFormStateProps => ({
    readOnly: isAppInMaintenanceMode(state) || !canUpdate(getSelectedUser(state)),
    isCreationDialog: false,
    loginIsReadOnly: true,
    firstName: getFirstNameInSidebar(state),
    lastName: getLastNameInSidebar(state),
    email: getEmailOfSelectedUser(state),
    additionalAuthenticationFactors: getAdditionalAuthenticationFactorsOfSelectedUser(state),
    phoneNumber: getPhoneNumberOfSelectedUser(state),
    login: getLoginOfSelectedUser(state),
    preferredLogin: getPreferredLoginOfSelectedUser(state),
});

export const mapDispatchToUserFormProps = (dispatch: Dispatch): UserFormDispatchProps => ({
    onChangeFirstName: (name: string) => dispatch(userSidebarSlice.actions.setNewFirstName(name)),
    onChangeLastName: (name: string) => dispatch(userSidebarSlice.actions.setNewLastName(name)),
});
