import { isEmpty } from 'ramda';
import { getAllowedGroupActions, getGroups, getIsLoading, getLoadingError } from '../../appSelectors';
import { isAppInMaintenanceMode } from '../../../selectors';
import { fireTrackingEvent, TRACKING_CATEGORIES, USER_GROUP_TRACKING_ACTIONS } from '../../../../tracking/gtm-helper';
import { selectGroupSearch, selectShowCreateGroupModal } from '../../../../routes/selector';
import { showCreateGroupModal } from '../../../../routes/actions';

const canCreateGroups = (state) => {
    const allowedGroupActions = getAllowedGroupActions(state);
    return allowedGroupActions.includes('add');
};

export const mapStateToGroupTableWrapperProps = (state, ownProps) => ({
    showCreateButton: canCreateGroups(state),
    showCreateDialog: selectShowCreateGroupModal(ownProps),
    hasData: !isEmpty(getGroups(state)),
    hasSearch: Boolean(selectGroupSearch(ownProps)),
    isLoading: getIsLoading(state),
    hasError: Boolean(getLoadingError(state)),
    maintenanceMode: isAppInMaintenanceMode(state),
});

export const mapDispatchToGroupWrapperTableProps = (dispatch, ownProps) => ({
    handleCreateGroup: () => {
        fireTrackingEvent(TRACKING_CATEGORIES.USERADMIN_USER_GROUP, USER_GROUP_TRACKING_ACTIONS.CREATE_USER_GROUP);
        showCreateGroupModal(ownProps);
    },
});
