import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { roleViewModel } from '../../services/roleService';
import { CreateGroupRoleAssignmentHeader, GroupSidebarRoleAssignmentHeader } from '../ListHeader';
import { UserGroupRolesContent } from '../../groups/GroupsHelpContent';
import { RoleSearchInCreateGroup, RoleSearchInSidebar } from './RoleSearchField';
import { RoleRestrictionListInCreateGroup, RoleRestrictionListInSidebar } from './RoleRestrictionList';

const RoleSearchWithRestrictions = () => (
    <div className={'TagManager'}>
        <RoleSearchInSidebar />
        <RoleRestrictionListInSidebar />
    </div>
);

export const RoleAssignmentList = () => (
    <div>
        <GroupSidebarRoleAssignmentHeader />
        <UserGroupRolesContent>
            <RoleSearchWithRestrictions />
        </UserGroupRolesContent>
    </div>
);

RoleAssignmentList.propTypes = {
    maintenanceMode: PropTypes.bool,
};

export const RoleAssignmentCreateGroup = () => (
    <div className={'createGroupRoleSelect'}>
        <CreateGroupRoleAssignmentHeader />

        <UserGroupRolesContent>
            <div className={'TagManager'}>
                <RoleSearchInCreateGroup />
                <RoleRestrictionListInCreateGroup />
            </div>
        </UserGroupRolesContent>
    </div>
);
