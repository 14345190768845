import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { SimpleAutoSuggest } from '../SimpleAutoSuggest';
import {
    mapDispatchToCreateGroupRoleSearchProps,
    mapDispatchToGroupSidebarRoleSearchProps,
    mapStateToCreateGroupRoleSearchProps,
    mapStateToGroupSidebarRoleSearchProps,
} from './roleSearch';

export const RoleSearchField = (props) => {
    const {
        intl,
        onFetchSuggestions,
        onSelectSuggestion,
        noItemMessage,
        placeholder,
        doNotTranslateSuggestions,
        readOnly,
    } = props;

    return readOnly ? null : (
        <SimpleAutoSuggest
            noItemMessage={intl.formatMessage({ id: noItemMessage })}
            onFetchSuggestions={onFetchSuggestions}
            onSelectSuggestion={onSelectSuggestion}
            placeholder={intl.formatMessage({ id: placeholder })}
            intl={intl}
            doNotTranslateSuggestions={doNotTranslateSuggestions}
        />
    );
};

RoleSearchField.propTypes = {
    intl: PropTypes.object,
    noItemMessage: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,

    onSelectSuggestion: PropTypes.func.isRequired,
    onFetchSuggestions: PropTypes.func.isRequired,

    doNotTranslateSuggestions: PropTypes.bool,
};

export const RoleSearchInSidebar = connect(
    mapStateToGroupSidebarRoleSearchProps,
    mapDispatchToGroupSidebarRoleSearchProps
)(injectIntl(RoleSearchField));

export const RoleSearchInCreateGroup = connect(
    mapStateToCreateGroupRoleSearchProps,
    mapDispatchToCreateGroupRoleSearchProps
)(injectIntl(RoleSearchField));
