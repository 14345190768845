import {
    commonEmailValidation,
    commonPhoneNumberValidation,
    commonValidateName,
    nameTypes,
} from '../../../validation/userInputValidation';
import {
    getInviteFirstName,
    getInviteLastName,
    getInviteLogin,
    getInvitePhoneNumber,
    getInvitePreferredLogin,
    getInviteUserGroupIds,
} from './inviteUserSelectors';
import { RootState } from '../../../../configuration/setup/store';

export const getInvitePhoneNumberError = (state: RootState) => {
    const required = getInvitePreferredLogin(state) === 'phone';
    const phoneNumber = getInvitePhoneNumber(state);
    return commonPhoneNumberValidation(phoneNumber, required);
};

export const getInviteFirstNameError = (state: RootState) => {
    const firstName = getInviteFirstName(state);
    return commonValidateName(firstName, nameTypes.FIRST_NAME);
};

export const getInviteLastNameError = (state: RootState) => {
    const lastName = getInviteLastName(state);
    return commonValidateName(lastName, nameTypes.LAST_NAME);
};

export const getLoginError = (state: RootState) => {
    const loginType = getInvitePreferredLogin(state);
    const login = getInviteLogin(state);
    if (loginType === 'email') {
        return commonEmailValidation(login, true);
    } else {
        return commonPhoneNumberValidation(login, true);
    }
};

const canSubmitInviteUserInternal = (state: RootState) => {
    return (
        getLoginError(state) === null &&
        getInviteFirstNameError(state) === null &&
        getInviteLastNameError(state) === null &&
        getInviteUserGroupIds(state).length > 0
    );
};

export const canSubmitInviteUser = (state: RootState) => Boolean(canSubmitInviteUserInternal(state));
