import { FormattedMessage } from 'react-intl';
import { executeAndTrack, TRACKING_CATEGORIES } from '../../../tracking/gtm-helper';

interface Props {
    chapter: string;
    activeChapter: string;
    onClick: (event: any) => void;
    trackingAction: string;
}

export const InfoDialogMenuItem = ({ chapter, activeChapter, onClick, trackingAction }: Props) => (
    <span
        className={activeChapter === chapter ? 'active' : undefined}
        onClick={executeAndTrack(onClick, {
            event: 'ga_event',
            eventCategory: TRACKING_CATEGORIES.USERADMIN_INFO,
            eventAction: trackingAction,
        })}
        data-name={chapter}
    >
        <FormattedMessage id={`${chapter}`} />
    </span>
);
