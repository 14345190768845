import { groupSidebarHasChanges } from '../groupsidebar/groupPropertiesService';
import { selectGroupIdOfSelectedGroup } from '../../../../routes/selector';
import { deselectGroup, restrictSelectionToGroup } from '../../../../routes/actions';
import { AppDispatch, RootState } from '../../../../configuration/setup/store';
import appSlice from '../../appSlice';
import { UserGroup } from '../../appTypes';
import { RouteComponentProps } from '../../../../routes/routerUtils';
import groupSidebarSlice from '../groupsidebar/groupSidebarSlice';

export const groupSelectionInTable =
    (selection: UserGroup, props: RouteComponentProps) => (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState();
        const selectedGroupId = selectGroupIdOfSelectedGroup(props);
        const sidebarHasChanges = groupSidebarHasChanges(state);
        if (sidebarHasChanges) {
            dispatch(groupSidebarSlice.actions.showGroupConfirmation(true));
        } else if (selection.id === selectedGroupId) {
            dispatch(appSlice.actions.groupDeselected());
            deselectGroup(props);
        } else {
            dispatch(appSlice.actions.groupSelected(selection));
            restrictSelectionToGroup(selection.id, props);
        }
    };
