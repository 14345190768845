import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from './notificationType';
import { shouldDisplayNotifications } from './notificationService';
import { mapStateToNotificationsProps } from './mapStateToNotificationsProps';
import { mapDispatchToNotificationProps } from './mapDispatchToNotificationsProps';

export class Notifications extends React.PureComponent {
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (shouldDisplayNotifications(this.props, nextProps)) {
            switch (nextProps.notificationType) {
                case ERROR_NOTIFICATION:
                    Notification.error(<FormattedMessage id={nextProps.notificationKey} />);
                    break;
                case SUCCESS_NOTIFICATION:
                    Notification.success(<FormattedMessage id={nextProps.notificationKey} />);
                    break;
                default:
                    break;
            }
            this.props.resetNotifications();
        }
    }

    // eslint-disable-next-line class-methods-use-this
    render() {
        return null;
    }
}

Notifications.defaultProps = {
    notificationKey: null,
    notificationType: null,
};

Notifications.propTypes = {
    notificationKey: PropTypes.string,
    notificationType: PropTypes.string,
    resetNotifications: PropTypes.func.isRequired,
};

export const NotificationsTrigger = connect(
    mapStateToNotificationsProps,
    mapDispatchToNotificationProps
)(Notifications);
