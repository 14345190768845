import Tag from '@rio-cloud/rio-uikit/Tag';
import { PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';

const mfaOptions = [
    'none',
    'otp',
    // 'totp',
] as const;
type MfaOption = (typeof mfaOptions)[number];

const isMfaOption = (value: string): value is MfaOption => mfaOptions.includes(value as MfaOption);

export const MfaList = ({ additionalAuthFactors }: { additionalAuthFactors: string[] | undefined }) => {
    let selectedMfaOptions: MfaOption[] = [];

    if (additionalAuthFactors === undefined || additionalAuthFactors.filter(isMfaOption).length === 0) {
        selectedMfaOptions = ['none'];
    } else {
        for (const option of mfaOptions) {
            if (additionalAuthFactors.includes(option)) {
                selectedMfaOptions.push(option);
            }
        }
    }
    return (
        <div className={'list-inline'}>
            {selectedMfaOptions.map((option) => {
                switch (option) {
                    case 'none':
                        return (
                            <li key={`li-${option}`}>
                                <MfaOverlay translationId={'users.mfa.none.tooltip'}>
                                    <span className={'text-color-gray'} aria-label={'no-second-factor'}>
                                        <FormattedMessage id={'users.mfa.none'} />
                                    </span>
                                </MfaOverlay>
                            </li>
                        );
                    case 'otp':
                        return (
                            <li key={`li-${option}`}>
                                <MfaOverlay translationId={'users.mfa.otp.tooltip'}>
                                    <Tag
                                        className={'active rioglyph rioglyph-ok'}
                                        size={'small'}
                                        style={{ userSelect: 'none' }}
                                    >
                                        OTP
                                    </Tag>
                                </MfaOverlay>
                            </li>
                        );
                }
            })}
        </div>
    );
};

const MfaOverlay = ({ children, translationId }: PropsWithChildren<{ translationId: string }>) => {
    const intl = useIntl();
    return (
        <OverlayTrigger
            placement="right"
            delay={{ show: 300, hide: 0 }}
            overlay={
                <Tooltip id={`tooltip-${translationId}`} allowOnTouch>
                    {intl.formatMessage({ id: translationId })}
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    );
};
