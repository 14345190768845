import * as t from 'io-ts';
import { decode } from './iotsDecoder';

const tag = t.type({
    id: t.string,
    type: t.string,
    name: t.string,
    account_id: t.string,
});

const tagApiResponse = t.type({
    items: t.array(tag),
});

export type ApiTag = t.TypeOf<typeof tag>;
export type TagApiResponse = t.TypeOf<typeof tagApiResponse>;

export const decodeTagApiResponse = (parsedObject: unknown): TagApiResponse => decode(parsedObject, tagApiResponse);
