import { clone, mergeDeepRight, omit } from 'ramda';
import {
    getFirstNameOfSelectedUser,
    getGroupIdsOfSelectedUser,
    getLastNameOfSelectedUser,
    getSelectedUser,
} from '../../appSelectors';
import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from '../../../notifications/notificationType';
import { getErrorMessageKey } from '../../common/commonFunctions';
import { userSidebarHasChanges } from './userSidebarService';
import { Action, ThunkAction } from '@reduxjs/toolkit';
import { User } from '../../appTypes';
import { deleteUser, saveUser } from '../../../api/api';
import { getProfileUserId } from '../../../../configuration/login/loginSlice';
import { deselectUser } from '../../../../routes/actions';
import { RouteComponentProps } from '../../../../routes/routerUtils';
import userSidebarSlice from './userSidebarSlice';
import { RootState } from '../../../../configuration/setup/store';
import { getFirstNameInSidebar, getLastNameInSidebar, getUserGroupsInSidebar } from './userSidebarSelectors';
import appSlice from '../../appSlice';
import notificationSlice from '../../../notifications/notificationSlice';

export const deleteUserThunk = (): ThunkAction<void, RootState, unknown, Action> => (dispatch, getState) => {
    const state = getState();
    const profileUserId = getProfileUserId(state);
    const selectedUser = getSelectedUser(state);
    selectedUser &&
        deleteUser(selectedUser.id)
            .then(() => {
                dispatch(userSidebarSlice.actions.deleteUserSuccess());
                dispatch(userSidebarSlice.actions.resetState());
                dispatch(userSidebarSlice.actions.resetMutations());
                dispatch(appSlice.actions.userDeselected());
                dispatch(appSlice.actions.userDeleted(selectedUser!.id)); // TODO should we deal with undefined/null here?
                dispatch(
                    notificationSlice.actions.displayNotification({
                        notificationType: SUCCESS_NOTIFICATION,
                        notificationKey: 'users.edit.userDeleted',
                    })
                );
                // if user deletes himself redirect to logout
                if (selectedUser.id === profileUserId) {
                    window.location.href = 'https://auth.iam.rio.cloud/logout';
                }
            })
            .catch((error: any) => {
                dispatch(userSidebarSlice.actions.deleteUserError());
                dispatch(
                    notificationSlice.actions.displayNotification({
                        notificationType: ERROR_NOTIFICATION,
                        notificationKey: getErrorMessageKey(error, 'users.edit.error.userNotDeleted'),
                    })
                );
            });
};

export const confirmDiscardUserSidebar = (): ThunkAction<void, RootState, unknown, Action> => (dispatch) => {
    dispatch(userSidebarSlice.actions.resetState());
    dispatch(appSlice.actions.userDeselected());
};

export const cancelUserSidebar =
    (routeProps: RouteComponentProps): ThunkAction<void, RootState, unknown, Action> =>
    (dispatch, getState) => {
        const message = userSidebarHasChanges(getState());
        if (message) {
            dispatch(userSidebarSlice.actions.showConfirmation(true));
        } else {
            console.log(routeProps);
            dispatch(userSidebarSlice.actions.resetState());
            dispatch(appSlice.actions.userDeselected());
            deselectUser(routeProps);
        }
    };

export const buildUserToSave = (state: RootState): User => {
    const selectedUser = getSelectedUser(state);

    const userModel = mergeDeepRight(clone(selectedUser) as User, {
        first_name: getFirstNameInSidebar(state) || getFirstNameOfSelectedUser(state),
        last_name: getLastNameInSidebar(state) || getLastNameOfSelectedUser(state),
    });

    const myGroups = getUserGroupsInSidebar(state) || getGroupIdsOfSelectedUser(state);
    const strip = omit(['metadata']);

    // TODO can this be done without the forced cast? Maybe drop ramda
    return mergeDeepRight(strip(clone(userModel)) as User, {
        group_ids: myGroups,
    }) as User;
};

export const saveUserThunk = (): ThunkAction<void, RootState, unknown, Action> => (dispatch, getState) => {
    const state = getState();
    const enrichedUser = buildUserToSave(state);

    saveUser(enrichedUser)
        .then((savedUser: any) => {
            // TODO use correct type
            dispatch(userSidebarSlice.actions.saveUserSuccess());
            dispatch(userSidebarSlice.actions.resetState());
            dispatch(appSlice.actions.updateUser(savedUser));
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: SUCCESS_NOTIFICATION,
                    notificationKey: 'users.edit.userSaved',
                })
            );
        })
        .catch((error: any) => {
            dispatch(userSidebarSlice.actions.saveUserError(error));
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: ERROR_NOTIFICATION,
                    notificationKey: 'users.edit.error.userNotSaved',
                })
            );
        });
};
