import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

export const ListHeader = (props) => (
    <div className="margin-bottom-15">
        <span className="line-height-btn-sm text-size-larger">
            <FormattedMessage id={props.titleId} values={props.values} />
        </span>
    </div>
);

ListHeader.propTypes = {
    titleId: PropTypes.string.isRequired,
};

ListHeader.defaultProps = {
    values: {},
};

const mapStateToUserSidebarGroupHeaderProps = (_state) => ({
    titleId: 'users.edit.groups.title',
});
export const UserSidebarGroupHeader = connect(mapStateToUserSidebarGroupHeaderProps)(ListHeader);

const mapStateToGroupSidebarRoleAssignmentHeaderProps = (_state) => ({
    titleId: 'roles.edit.assignments.title',
});
export const GroupSidebarRoleAssignmentHeader = connect(mapStateToGroupSidebarRoleAssignmentHeaderProps)(ListHeader);

export const CreateGroupRoleAssignmentHeader = () => (
    <div>
        <label className={'control-label'} htmlFor={'roles'}>
            {<FormattedMessage id={'groups.create.label.roles'} />}
        </label>
    </div>
);

export const InviteUserGroupHeader = () => (
    <label className={'control-label'} htmlFor={'roles'}>
        {<FormattedMessage id={'users.invite.label.userGroups'} />}
    </label>
);
