import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { selectUserIdOfSelectedUser } from '../../../../routes/selector';
import { getFirstNameOfSelectedUser, getLastNameOfSelectedUser } from '../../appSelectors';
import { SidebarShell } from '../../common/SidebarShell';
import { ModalPrompt } from '../../dialogs/ModalPrompt';
import { UserSidebarGroups } from '../../listelements/groups/GroupLists';
import { UserSidebarFooter } from '../../common/SidebarFooter';
import { ConfirmDeleteUserDialog, ReallyDiscardUserChanges } from '../../common/ConfirmDialogWrapper';
import { UserFormContainer } from './UserFormContainer';
import { getShowConfirmation, getShowDeleteConfirmation } from './userSidebarSelectors';
import { userSidebarHasChanges } from './userSidebarService';
import { cancelUserSidebar, confirmDiscardUserSidebar } from './userSidebarThunks';
import { RouteComponentProps, withRouter } from '../../../../routes/routerUtils';
import userSidebarSlice from './userSidebarSlice';
import { RootState } from '../../../../configuration/setup/store';

interface OwnProps {
    maintenanceMode: boolean;
}

interface StateProps {
    selectedUserId: string | null;
    showConfirmation: boolean;
    showDeleteConfirmation: boolean;
    hasChanges: boolean;
    persistedFirstName: string;
    persistedLastName: string;
}

interface DispatchProps {
    handleCancel: () => void;
    handleUserGroupsUpdate: (groups: Array<string>) => void;
    handleConfirmDiscard: () => void;
    handleKeepEditing: () => void;
}

export const UserSidebarPure = (props: RouteComponentProps & OwnProps & StateProps & DispatchProps) => {
    const {
        handleCancel,
        persistedFirstName,
        persistedLastName,
        showDeleteConfirmation,
        showConfirmation,
        handleUserGroupsUpdate,
        selectedUserId,
        hasChanges,
        handleConfirmDiscard,
        handleKeepEditing,
    } = props;

    const header = (
        <React.Fragment>
            <h2 className={'margin-top-5'}>{`${persistedFirstName} ${persistedLastName}`}</h2>
        </React.Fragment>
    );

    const modalPromptWhenSwitchingToGroupsTab = (
        <ModalPrompt
            key={selectedUserId}
            when={hasChanges}
            handleConfirmDiscard={handleConfirmDiscard}
            handleKeepEditing={handleKeepEditing}
            navigate={(path: string) => props.push(path)}
        />
    );

    return (
        <React.Fragment>
            {!showConfirmation && modalPromptWhenSwitchingToGroupsTab}
            {showConfirmation && <ReallyDiscardUserChanges />}
            {showDeleteConfirmation && <ConfirmDeleteUserDialog />}
            <SidebarShell
                className={'users-sidebar'}
                footer={<UserSidebarFooter />}
                handleClose={handleCancel}
                header={header}
                title={<FormattedMessage id={'users.edit.dialogTitle'} />}
            >
                <UserFormContainer />
                <UserSidebarGroups notifyParentOfChange={handleUserGroupsUpdate} />
            </SidebarShell>
        </React.Fragment>
    );
};

export const mapState = (state: RootState, ownProps: any): StateProps => ({
    // TODO use correct type for ownProps
    selectedUserId: selectUserIdOfSelectedUser(ownProps),
    showConfirmation: getShowConfirmation(state),
    showDeleteConfirmation: getShowDeleteConfirmation(state),
    hasChanges: userSidebarHasChanges(state),
    persistedFirstName: getFirstNameOfSelectedUser(state),
    persistedLastName: getLastNameOfSelectedUser(state),
});

export const mapDispatch = (
    dispatch: ThunkDispatch<RootState, any, AnyAction>,
    ownProps: RouteComponentProps
): DispatchProps => ({
    handleCancel: () => dispatch(cancelUserSidebar(ownProps)),
    handleUserGroupsUpdate: (groups) => dispatch(userSidebarSlice.actions.setNewGroups(groups)),
    handleConfirmDiscard: () => dispatch(confirmDiscardUserSidebar()),
    handleKeepEditing: () => dispatch(userSidebarSlice.actions.showConfirmation(false)),
});

export const UserSidebarContainer = withRouter(connect(mapState, mapDispatch)(UserSidebarPure));
