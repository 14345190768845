import { backendUrls } from './config';
import {
    decodeGroupsApiResponse,
    decodeRoleApiResponse,
    decodeSingleGroupApiResponse,
    decodeSingleUserApiResponse,
    decodeUsersApiResponse,
} from './userAdminBackendCodec';
import {
    mapApiAccountSettingToAccountSetting,
    mapApiGroupToUserGroup,
    mapApiRoleToUserGroupRole,
    mapApiTagToTag,
    mapApiUserToUser,
    mapToAllowedActions,
    mapToGroups,
    mapToLoadMore,
    mapToUsers,
} from './mapper';
import { User, UserGroup, UserGroupRole } from '../app/appTypes';
import { deleteRequest, getRequest, putRequest } from './requests';
import { DEFAULT_LIMIT_FOR_BACKEND_FETCHES } from '../../constants';
import { GroupsAndLoadMore, Tag, UsersAndLoadMore } from './types';

import { UserToInvite } from '../app/users/invite/inviteUserTypes';
import { decodeTagApiResponse } from './tagBackendCodec';
import { decodeAccountSettingsApiResponse } from './accountSettingsBackendCodec';
import { AccountSetting } from '../app/accountSettings/types';
import { reportErrorToSentry } from '../../configuration/setup/sentry';

const HTTP_STATUS_CREATED = 201;

const onRejected = (error?: Error): Promise<never> => (error ? Promise.reject(error) : Promise.reject());

const okOrReject = (response: Response = {} as Response, expectedStatusCode?: number): Response | Promise<any> => {
    if (expectedStatusCode && response.status !== expectedStatusCode) {
        return Promise.reject(new Error('UNEXPECTED_STATUS_CODE'));
    } else if (expectedStatusCode && response.status === expectedStatusCode) {
        return response;
    }

    if (response.status === 401) {
        return Promise.reject(new Error('UNAUTHENTICATED'));
    } else if (response.status === 403) {
        return Promise.reject(new Error('ACCESS_DENIED'));
    } else if (response.ok) {
        return response;
    } else {
        reportErrorToSentry(new Error(`${response.status} Backend error: ${response.statusText}`));
    }
    return Promise.reject(new Error('Backend error'));
};

const jsonOrReject = (response: Response = {} as Response): Error | Promise<any> => {
    return response.json().catch((error) => {
        reportErrorToSentry(new Error(`${response.status} Invalid payload: ${error.message}`));
    });
};

// USERS
export const fetchUser = (userId: string): Promise<User> =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/users/${userId}`, getRequest())
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeSingleUserApiResponse)
        .then(mapApiUserToUser)
        .catch(onRejected);

export const fetchUsers = (data: {
    limit?: number;
    rawUrl?: string;
    search?: string;
    showManagedGroups?: boolean; // TODO only relevant for debugging. Do we need it?
    userSortBy?: string;
    userSortByAsc?: boolean;
}): Promise<UsersAndLoadMore> => {
    const {
        limit = DEFAULT_LIMIT_FOR_BACKEND_FETCHES,
        rawUrl,
        search,
        showManagedGroups = false,
        userSortBy = '',
        userSortByAsc = true,
    } = data;

    const go = (url: string) =>
        fetch(url, getRequest())
            .then(okOrReject)
            .then(jsonOrReject)
            .then(decodeUsersApiResponse)
            .then((response) => {
                const users = mapToUsers(response);
                const loadMore = mapToLoadMore(response);
                const allowedActions = mapToAllowedActions(response.allowed_actions);
                return {
                    users: users,
                    loadMoreLink: loadMore ? loadMore : null,
                    allowedActions: allowedActions,
                };
            })
            .catch(onRejected);

    if (rawUrl) {
        return go(rawUrl);
    }

    const params = [
        search ? `q=${encodeURIComponent(search)}` : null,
        limit ? `limit=${encodeURIComponent(limit)}` : null,
        userSortBy ? `sort=${userSortByAsc ? '+' : '-'}${encodeURIComponent(userSortBy)}` : null,
        showManagedGroups ? `showManagedGroups=true` : null,
    ]
        .filter(Boolean)
        .join('&');

    return go(`${backendUrls.USER_ADMINISTRATION}/users` + (params ? `?${params}` : ''));
};

export const inviteUser = (user: UserToInvite): Promise<User> =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/users/${user.id}`, putRequest(user, true)) // TODO do we really want to create a uuid here?
        .then((res) => {
            return okOrReject(res, HTTP_STATUS_CREATED);
        })
        .then(jsonOrReject)
        .then(decodeSingleUserApiResponse)
        .then(mapApiUserToUser)
        .catch(onRejected);

export const saveUser = (user: User): Promise<User> =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/users/${user.id}`, putRequest(user))
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeSingleUserApiResponse)
        .then(mapApiUserToUser)
        .catch(onRejected);

export const deleteUser = (userId: string) =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/users/${userId}`, deleteRequest())
        .then(okOrReject)
        .then(() => {}) // we don't care about response for delete
        .catch(onRejected);

// GROUPS
export const fetchGroup = (groupId: string): Promise<UserGroup> =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/groups/${groupId}`, getRequest())
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeSingleGroupApiResponse)
        .then(mapApiGroupToUserGroup)
        .catch(onRejected);

export const fetchGroups = (data: {
    limit?: number;
    rawUrl?: string;
    search?: string;
    showManagedGroups?: boolean; // TODO only relevant for debugging. Do we need it?
    groupSortBy?: string;
    groupSortByAsc?: boolean;
}): Promise<GroupsAndLoadMore> => {
    const {
        limit = DEFAULT_LIMIT_FOR_BACKEND_FETCHES,
        rawUrl,
        search,
        showManagedGroups = false,
        groupSortBy = '',
        groupSortByAsc = true,
    } = data;

    const go = (url: string) =>
        fetch(url, getRequest())
            .then(okOrReject)
            .then(jsonOrReject)
            .then(decodeGroupsApiResponse)
            .then((response) => {
                const groups = mapToGroups(response);
                const loadMore = mapToLoadMore(response);
                const allowedActions = mapToAllowedActions(response.allowed_actions);
                return {
                    groups: groups,
                    loadMoreLink: loadMore ? loadMore : null,
                    allowedActions: allowedActions,
                };
            })
            .catch(onRejected);

    if (rawUrl) {
        return go(rawUrl);
    }

    const params = [
        search ? `q=${encodeURIComponent(search)}` : null,
        limit ? `limit=${encodeURIComponent(limit)}` : null,
        groupSortBy ? `sort=${groupSortByAsc ? '+' : '-'}${encodeURIComponent(groupSortBy)}` : null,
        showManagedGroups ? `showManagedGroups=true` : null,
    ]
        .filter(Boolean)
        .join('&');

    return go(`${backendUrls.USER_ADMINISTRATION}/groups` + (params ? `?${params}` : ''));
};

export const createGroup = (group: UserGroup) =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/groups/${group.id}`, putRequest(group))
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeSingleGroupApiResponse)
        .then(mapApiGroupToUserGroup)
        .catch(onRejected);

export const saveGroup = (group: UserGroup): Promise<UserGroup> =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/groups/${group.id}`, putRequest(group))
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeSingleGroupApiResponse)
        .then(mapApiGroupToUserGroup)
        .catch(onRejected);

export const deleteGroup = (groupId: string) =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/groups/${groupId}`, deleteRequest())
        .then(okOrReject)
        .then(() => {}) // we don't care about response for delete
        .catch(onRejected);

// ROLES
export const fetchRoles = (data?: { limit: number }): Promise<Array<UserGroupRole>> => {
    const { limit } = data ? data : { limit: DEFAULT_LIMIT_FOR_BACKEND_FETCHES };
    return fetch(`${backendUrls.USER_ADMINISTRATION}/roles` + (limit ? `?limit=${limit}` : ''), getRequest())
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeRoleApiResponse)
        .then((res) => res.items.map(mapApiRoleToUserGroupRole))
        .catch(onRejected);
};

// TAGS
export const fetchTags = (data?: { limit: number }): Promise<Array<Tag>> => {
    const { limit } = data ? data : { limit: DEFAULT_LIMIT_FOR_BACKEND_FETCHES };
    return fetch(`${backendUrls.TAGS_SERVICE}/tags` + (limit ? `?limit=${limit}` : ''), getRequest())
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeTagApiResponse)
        .then((res) => res.items.map(mapApiTagToTag))
        .catch(onRejected);
};

// ACCOUNT SETTINGS
export const fetchAccountSettings = (accountId: string): Promise<Array<AccountSetting>> =>
    fetch(`${backendUrls.ACCOUNTS_SERVICE}/${accountId}/settings`, getRequest())
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeAccountSettingsApiResponse)
        .then((it) => it.items.map(mapApiAccountSettingToAccountSetting))
        .then((it) => it.filter((it) => it !== undefined) as Array<AccountSetting>)
        .catch(onRejected);

export const putAccountSetting = (accountId: string, accountSetting: AccountSetting): Promise<any> =>
    fetch(`${backendUrls.ACCOUNTS_SERVICE}/${accountId}/settings/${accountSetting.id}`, putRequest(accountSetting))
        .then((res) => okOrReject(res, 204))
        .catch(onRejected);

export const putAccountSettingUserSelfReg = (accountId: string, accountSetting: AccountSetting): Promise<any> =>
    fetch(`${backendUrls.ACCOUNTS_SERVICE}/${accountId}/settings/user-self-registration`, putRequest(accountSetting))
        .then((res) => okOrReject(res, 204))
        .catch(onRejected);

export const fetchAccountById = async (accountId: string) =>
    fetch(`${backendUrls.ACCOUNTS_SERVICE}/${accountId}`, getRequest())
        .then(okOrReject)
        .then(jsonOrReject)
        .catch(onRejected);
