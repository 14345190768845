import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    mapDispatchToDeleteGroupDialogProps,
    mapDispatchToReallyDiscardGroupChangesProps,
    mapStateToDeleteGroupDialogProps,
    mapStateToReallyDiscardGroupChangesProps,
} from '../groups/groupsidebar/groupSidebarDialogs';
import {
    mapDispatchToDeleteUserDialogProps,
    mapDispatchToReallyDiscardUserChangesProps,
    mapStateToDeleteUserDialogProps,
    mapStateToReallyDiscardUserChangesProps,
} from '../users/usersidebar/userSidebarDialogs';
import { withRouter } from '../../../routes/routerUtils';

const ConfirmDialogWrapper = (props) => {
    const { values, clickCancel, clickConfirm, cancelButtonLabel, confirmButtonLabel, content, title } = props;

    return (
        <ConfirmationDialog
            bsSize={'sm'}
            cancelButtonText={<FormattedMessage id={cancelButtonLabel} />}
            confirmButtonText={<FormattedMessage id={confirmButtonLabel} />}
            content={<FormattedMessage id={content} values={values} />}
            onClickCancel={clickCancel}
            onClickConfirm={clickConfirm}
            show
            title={<FormattedMessage id={title} />}
        />
    );
};

ConfirmDialogWrapper.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    values: PropTypes.object.isRequired,
    clickCancel: PropTypes.func.isRequired,
    clickConfirm: PropTypes.func.isRequired,

    cancelButtonLabel: PropTypes.string.isRequired,
    confirmButtonLabel: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export const ConfirmDeleteGroupDialog = withRouter(
    connect(mapStateToDeleteGroupDialogProps, mapDispatchToDeleteGroupDialogProps)(ConfirmDialogWrapper)
);

export const ConfirmDeleteUserDialog = withRouter(
    connect(mapStateToDeleteUserDialogProps, mapDispatchToDeleteUserDialogProps)(ConfirmDialogWrapper)
);

export const ReallyDiscardGroupChanges = connect(
    mapStateToReallyDiscardGroupChangesProps,
    mapDispatchToReallyDiscardGroupChangesProps
)(ConfirmDialogWrapper);

export const ReallyDiscardUserChanges = connect(
    mapStateToReallyDiscardUserChangesProps,
    mapDispatchToReallyDiscardUserChangesProps
)(ConfirmDialogWrapper);
