export const USERS_LIST_MODE_CHANGED = 'app/USERS_LIST_MODE_CHANGED';
export const GROUPS_LIST_MODE_CHANGED = 'app/GROUPS_LIST_MODE_CHANGED';

export const changeGroupsListMode = (mode) => ({
    payload: mode,
    type: GROUPS_LIST_MODE_CHANGED,
});

export const changeUsersListMode = (mode) => ({
    payload: mode,
    type: USERS_LIST_MODE_CHANGED,
});
