import Fuse from 'fuse.js';
import { getRolesOfSelectedGroup, getTags } from '../appSelectors';
import { autoCompleteSettings } from '../../../fuseSettings';
import { getCreateGroupRestrictionsToAdd } from '../groups/creategroup/createGroupSelectors';
import { getGroupRestrictionsToAdd } from '../groups/groupsidebar/groupSidebarSelectors';
import { Restriction } from '../groups/creategroup/createGroupTypes';
import { Tag } from '../appTypes';
import { RootState } from '../../../configuration/setup/store';

export const extractSuggestions = (
    alreadySelectedTagIds: Array<string>,
    tagIdsToBeAdded: Array<string>,
    tags: Array<Tag>,
    value: string
) => {
    const notSelectedTags = tags
        .filter((tag) => !alreadySelectedTagIds.includes(tag.id))
        .filter((tag) => !tagIdsToBeAdded.includes(tag.id));

    const suggestions = notSelectedTags.map((tag) => ({
        label: tag.name,
        id: tag.id,
        type: 'key',
    }));

    if (value === '' || value.length > 32) {
        return { suggestions };
    }
    const fuse = new Fuse(suggestions, autoCompleteSettings);
    return { suggestions: fuse.search(value).map((it) => it.item) };
};

export const getTagIdsOfRole = (state: RootState, roleId: string) => {
    const rolesOfSelectedGroup = getRolesOfSelectedGroup(state);
    const [currentRole] = rolesOfSelectedGroup.filter((role) => role.role_id === roleId);
    return currentRole ? currentRole.scope.identifiers : [];
};

export const collectAssetGroupRestrictionsForRoleInGroupSidebar = (state: RootState, roleId: string, value: string) => {
    const tags = getTags(state);
    const alreadySelectedTagIds = getTagIdsOfRole(state, roleId);

    const tagIdsToBeAdded = getGroupRestrictionsToAdd(state)
        .filter((restriction: Restriction) => restriction.parentId === roleId)
        .map((restriction: Restriction) => restriction.id);

    return extractSuggestions(alreadySelectedTagIds, tagIdsToBeAdded, tags, value);
};

export const collectAssetGroupRestrictionsForRoleInCreateGroup = (state: RootState, roleId: string, value: string) => {
    const tags = getTags(state);
    const tagIdsToBeAdded = getCreateGroupRestrictionsToAdd(state)
        .filter((restriction) => restriction.parentId === roleId)
        .map((restriction) => restriction.id);

    return extractSuggestions([], tagIdsToBeAdded, tags, value);
};
