import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { TableToolbarPure } from '../../common/TableToolbar';
import { getGroupsListMode } from '../../../setting/selectors';
import { changeGroupsListMode } from '../../../setting/actions';
import { selectGroupSearch } from '../../../../routes/selector';

import { changeGroupSearch } from '../../../../routes/actions';
import { withRouter } from '../../../../routes/routerUtils';

const mapStateToGroupTableToolbarProps = (state, ownProps) => ({
    dataListMode: getGroupsListMode(state),
    searchPlaceholder: 'groups.table.search.placeholder',
    searchValue: selectGroupSearch(ownProps),
});

const mapDispatchToGroupTableToolbarProps = (dispatch, ownProps) => ({
    handleSelectSplitMode: () => dispatch(changeGroupsListMode('split')),
    handleSelectTableMode: () => dispatch(changeGroupsListMode('table')),
    handleSearchChange: (query) => changeGroupSearch(ownProps, query),
});
export const GroupTableToolbar = withRouter(
    connect(mapStateToGroupTableToolbarProps, mapDispatchToGroupTableToolbarProps)(injectIntl(TableToolbarPure))
);
