import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { SimpleAutoSuggest } from '../SimpleAutoSuggest';

export const RoleSearchField = (props) => {
    const { intl, onFetchSuggestions, onSelectSuggestion, roleId, readOnly } = props;

    return readOnly ? null : (
        <SimpleAutoSuggest
            noItemMessage={intl.formatMessage({ id: 'roles.edit.assetGroups.noAssetGroupsMessage' })}
            onFetchSuggestions={onFetchSuggestions(roleId)}
            onSelectSuggestion={onSelectSuggestion(roleId)}
            placeholder={intl.formatMessage({ id: 'roles.edit.assetGroups.searchPlaceholder' })}
            intl={intl}
            doNotTranslateSuggestions={true}
        />
    );
};

RoleSearchField.propTypes = {
    intl: PropTypes.object,
    readOnly: PropTypes.bool,

    onSelectSuggestion: PropTypes.func.isRequired,
    onFetchSuggestions: PropTypes.func.isRequired,
    roleId: PropTypes.string.isRequired,
};

export const TagSearch = injectIntl(RoleSearchField);
