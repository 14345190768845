import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { itemPropTypes, subItemPropTypes } from '../../types';

const itemButtonClassName = 'btn btn-primary btn-link btn-icon-only';
const itemClassName = 'display-flex align-items-center';
const itemHeaderClassName = 'flex-1-0';

const getButton = (onClick, rioglyph) => (
    <button type="button" onClick={onClick} className={itemButtonClassName}>
        <span
            className={`rioglyph rioglyph-${rioglyph} hover-rioglyph-trash text-size-14 text-color-dark hover-text-color-danger`}
            aria-hidden="true"
        />
    </button>
);

export const RemovableRoleListItem = memo((props) => {
    const { item, handleRemoveItem, handleResurrectItem, mayBeUpdated, className } = props;
    const isSpecial = item.shouldBeAdded || item.shouldBeRemoved;

    const removeButton = !isSpecial && getButton(() => handleRemoveItem(item), 'remove', item.id);

    const resurrectButton = item.shouldBeRemoved ? getButton(() => handleResurrectItem(item), 'revert', item.id) : null;

    const removeNewButton = item.shouldBeAdded ? getButton(() => handleRemoveItem(item), 'remove', item.id) : null;

    return (
        <div className={itemClassName + ' ' + className}>
            <div className={itemHeaderClassName}>{item.displayName}</div>
            {resurrectButton}
            {removeNewButton}
            {mayBeUpdated && removeButton}
        </div>
    );
});

RemovableRoleListItem.propTypes = {
    item: PropTypes.oneOfType([subItemPropTypes, itemPropTypes]),
    handleRemoveItem: PropTypes.func.isRequired,
    handleResurrectItem: PropTypes.func.isRequired,
    mayBeUpdated: PropTypes.bool.isRequired,

    className: PropTypes.string.isRequired,
};

RemovableRoleListItem.defaultProps = {
    className: '',
};
