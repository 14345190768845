import AutoSuggest from '@rio-cloud/rio-uikit/AutoSuggest';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

const renderSuggestion = (suggestion) => (
    <Fragment>
        <span className={classNames(`rioglyph rioglyph-${suggestion.type}`, 'margin-right-5')} />
        <span>{suggestion.label}</span>
    </Fragment>
);

const getInputProps = (searchRef, placeholder) => ({
    className: '',
    hasError: false,
    inputRef: searchRef,
    placeholder,
    tabIndex: 1,
});

const initState = {
    isLoading: false,
    suggestions: [],
    value: '',
};

const translateSuggestions = (suggestions, intl) => {
    if (suggestions === null || suggestions.length === 0) {
        return suggestions;
    } else {
        return suggestions.map((suggestion) => ({
            ...suggestion,
            label: intl.formatMessage({ id: suggestion.label }),
        }));
    }
};

export class SimpleAutoSuggest extends Component {
    constructor(props) {
        super(props);
        this.state = initState;

        this.handleFocus = this.handleFocus.bind(this);
        this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
        this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
        this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(_, { newValue }) {
        this.setState((state) => ({
            ...state,
            value: newValue,
        }));
    }

    handleFocus() {
        this.handleSuggestionsFetchRequested({ value: '' });
    }

    handleSuggestionsFetchRequested(arg = {}) {
        const { value } = arg;
        let { suggestions } = this.props.onFetchSuggestions(value);
        suggestions = this.props.doNotTranslateSuggestions
            ? suggestions
            : translateSuggestions(suggestions, this.props.intl);
        this.setState((state) => ({
            ...state,
            isLoading: false,
            suggestions: suggestions.filter((suggestion) =>
                suggestion.label.toLowerCase().includes(value.toLowerCase())
            ),
        }));
    }

    handleSuggestionSelected(_, { suggestion }) {
        this.props.onSelectSuggestion(suggestion);
        this.setState((state) => ({
            ...state,
            value: '',
        }));
    }

    handleSuggestionsClearRequested() {
        this.props.onSelectSuggestion('');
        this.setState((state) => ({
            ...state,
            isLoading: false,
            suggestions: [],
        }));
    }

    render() {
        const { dropDownClassName, noItemMessage, placeholder, searchRef, setTouchedTrue, inputProps } = this.props;

        const basicInputProps = getInputProps(searchRef, placeholder);

        return (
            <AutoSuggest
                className={classNames('form-group margin-top-10')}
                dropDownClassName={dropDownClassName}
                inputProps={{
                    ...basicInputProps,
                    ...inputProps,
                    onBlur: setTouchedTrue,
                    onChange: this.onChange,
                    onFocus: this.handleFocus,
                    value: this.state.value,
                }}
                isLoading={this.state.isLoading}
                noItemMessage={noItemMessage}
                onSuggestionSelected={this.handleSuggestionSelected}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                openOnFocus
                renderSuggestion={renderSuggestion}
                suggestions={this.state.suggestions}
            />
        );
    }
}

SimpleAutoSuggest.defaultProps = {
    dropDownClassName: '',
    noItemMessage: '',
    searchRef: null,
    setTouchedTrue: () => {},
    inputProps: {},
};

SimpleAutoSuggest.propTypes = {
    dropDownClassName: PropTypes.string,
    noItemMessage: PropTypes.string.isRequired,
    onFetchSuggestions: PropTypes.func.isRequired,
    onSelectSuggestion: PropTypes.func.isRequired,
    setTouchedTrue: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    searchRef: PropTypes.string,
    doNotTranslateSuggestions: PropTypes.bool,
    inputProps: PropTypes.object,
};
