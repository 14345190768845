import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../configuration/setup/store';

export const availableRole = {
    OVERVIEW: 'Overview',
    ADMINISTRATOR: 'Administrator',
    FLEET_MANAGER: 'FleetManager',
    FLEETUSER: 'Fleetuser',
    WORKSHOP_MANAGER: 'WorkshopManager',
    DISPATCHER: 'Dispatcher',
    HUMAN_RESOURCES: 'HumanResources',
    SHIPPER: 'Shipper',
    DRIVER: 'Driver',
    DRIVER_TRAINER: 'DriverTrainer',
    ANALYSIS_EXPERT: 'AnalysisExpert',
    BOOKING_EXPERT: 'BookingExpert',
} as const;

export type AvailableRole = (typeof availableRole)[keyof typeof availableRole];

interface RoleDescriptionState {
    selectedRole: AvailableRole;
}

const initialState: RoleDescriptionState = {
    selectedRole: availableRole.OVERVIEW,
};

const roleDescriptionSlice = createSlice({
    name: 'roleDescription',
    initialState,
    reducers: {
        selectRoleDescription: (state, action: PayloadAction<AvailableRole>) => {
            state.selectedRole = action.payload;
        },
    },
});

export const { selectRoleDescription } = roleDescriptionSlice.actions;

export const getRoleDescriptionSelectedRole = (state: RootState) => state.roleDescription.selectedRole;

export default roleDescriptionSlice.reducer;
