import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAccountSettings, putAccountSetting, putAccountSettingUserSelfReg } from '../../api/api';
import { setDriverSelfInvitation, setPending } from './accountSettingsSlice';
import { getModifiedAccountSettings } from './accountSettingsSelectors';
import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from '../../notifications/notificationType';
import { getAccountId } from '../../../configuration/tokenHandling/tokenHandlingSlice';
import { RootState } from '../../../configuration/setup/store';
import notificationSlice from '../../notifications/notificationSlice';

export const getAccountSettings = createAsyncThunk('accountSettings/get', async (arg, { dispatch, getState }) => {
    dispatch(setPending('load'));
    try {
        const accountId = getAccountId(getState() as RootState);
        if (accountId === null) {
            throw new Error('No accountId for user found');
        }
        const response = await fetchAccountSettings(accountId);
        const userSelfRegistration = response.find((it) => it.id === 'user-self-registration');
        if (userSelfRegistration) {
            dispatch(setDriverSelfInvitation(userSelfRegistration.values.driver_self_registration));
        }
    } catch (err) {
        dispatch(
            notificationSlice.actions.displayNotification({
                notificationType: ERROR_NOTIFICATION,
                notificationKey: 'accountSettings.fetch.error',
            })
        );
        console.error(err);
    }
    dispatch(setPending(undefined));
});

export const saveAllAccountSettings = createAsyncThunk('accountSettings/save', async (arg, { dispatch, getState }) => {
    dispatch(setPending('save'));

    const state = getState() as RootState;
    const accountId = getAccountId(state);

    const modifiedSettings = getModifiedAccountSettings(state as any);

    try {
        if (accountId === null) {
            throw new Error('No accountId for user found');
        }
        const userSelfRegistrationPromise = putAccountSetting(accountId, {
            id: 'user-self-registration',
            group: 'iam',
            values: {
                driver_self_registration: modifiedSettings.userSelfInvitation.driver!,
            },
        });
        await userSelfRegistrationPromise;
        dispatch(setDriverSelfInvitation(modifiedSettings.userSelfInvitation.driver!));
        dispatch(
            notificationSlice.actions.displayNotification({
                notificationType: SUCCESS_NOTIFICATION,
                notificationKey: 'accountSettings.save.success',
            })
        );
    } catch (err) {
        dispatch(
            notificationSlice.actions.displayNotification({
                notificationType: ERROR_NOTIFICATION,
                notificationKey: 'accountSettings.save.error',
            })
        );
        console.error(err);
    }
    dispatch(setPending(undefined));
});

export const saveDriverSelfRegistrationAccountSettings = createAsyncThunk(
    'accountSettings/saveDriverSelfRegistration',
    async (arg: 'enabled' | 'disabled', { dispatch, getState }) => {
        dispatch(setPending('save'));

        const state = getState() as RootState;
        const accountId = getAccountId(state);
        const modifiedSettings = getModifiedAccountSettings(state as any);

        try {
            if (accountId === null) {
                throw new Error('No accountId for user found');
            }
            await putAccountSettingUserSelfReg(accountId, {
                id: 'user-self-registration',
                group: 'iam',
                values: {
                    driver_self_registration: arg,
                },
            });
            dispatch(setDriverSelfInvitation(modifiedSettings.userSelfInvitation.driver!));
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: SUCCESS_NOTIFICATION,
                    notificationKey: 'accountSettings.save.success',
                })
            );
        } catch (err) {
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: ERROR_NOTIFICATION,
                    notificationKey: 'accountSettings.save.error',
                })
            );
            console.error(err);
        }
        dispatch(setPending(undefined));
    }
);
