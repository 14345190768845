import { getSelectedGroup } from '../../appSelectors';
import { getCurrentGroupDescription, getCurrentGroupName, groupSidebarHasChanges } from './groupPropertiesService';
import {
    getShowDeleteGroupConfirmation,
    getShowEditGroupDialog,
    getShowGroupConfirmation,
} from './groupSidebarSelectors';
import { cancelGroupSidebar, confirmDiscardGroupSidebar } from './groupSidebarThunks';
import { isAppInMaintenanceMode } from '../../../selectors';
import { fireTrackingEvent, TRACKING_CATEGORIES, USER_GROUP_TRACKING_ACTIONS } from '../../../../tracking/gtm-helper';
import groupSidebarSlice from './groupSidebarSlice';
import { AppDispatch, RootState } from '../../../../configuration/setup/store';
import { RouteComponentProps } from '../../../../routes/routerUtils';

export const mapStateToGroupSidebarProps = (state: RootState) => ({
    selectedGroup: getSelectedGroup(state),
    currentGroupName: getCurrentGroupName(state),
    currentGroupDescription: getCurrentGroupDescription(state),

    showDeleteConfirmation: getShowDeleteGroupConfirmation(state),
    showEditDialog: getShowEditGroupDialog(state),
    showConfirmation: getShowGroupConfirmation(state),

    groupSidebarHasChanges: groupSidebarHasChanges(state),

    maintenanceMode: isAppInMaintenanceMode(state),
});

export const mapDispatchToGroupSidebarProps = (dispatch: AppDispatch, ownProps: RouteComponentProps) => ({
    handleEditDetails: () => {
        fireTrackingEvent(
            TRACKING_CATEGORIES.USERADMIN_USER_GROUP,
            USER_GROUP_TRACKING_ACTIONS.EDIT_USER_GROUP_DETAILS
        );
        dispatch(groupSidebarSlice.actions.showEditGroupDialog(true));
    },
    handleCancel: () => dispatch(cancelGroupSidebar(ownProps)),

    abortChangingGroupDetails: () => dispatch(groupSidebarSlice.actions.showEditGroupDialog(false)),
    saveNewGroupDetails: (newGroupName: string, newGroupDescription: string) => {
        dispatch(groupSidebarSlice.actions.updateGroupProperties({ newGroupName, newGroupDescription }));
        dispatch(groupSidebarSlice.actions.showEditGroupDialog(false));
    },

    handleKeepEditing: () => dispatch(groupSidebarSlice.actions.showGroupConfirmation(false)),
    handleConfirmDiscard: () => dispatch(confirmDiscardGroupSidebar),
});
