const codeMapping = {
    GROUP_ALREADY_EXISTS: 'groups.create.error.groupAlreadyExists',
    GROUP_DELETE_NOT_ALLOWED: 'groups.edit.error.groupDeleteNotAllowed',
    GROUP_DOES_NOT_EXIST: 'groups.edit.error.groupDoesNotExist',
    GROUP_INVALID_NAME: 'groups.create.error.invalidName',
    GROUP_INVALID_ROLE_ASSIGNMENT: 'groups.edit.error.invalidRoleAssignment',
    GROUP_UPDATE_NOT_ALLOWED: 'groups.edit.error.groupUpdateNotAllowed',
    USER_DELETE_NOT_ALLOWED: 'users.edit.error.userDeleteNotAllowed',
    USER_DOES_NOT_EXIST: 'users.edit.error.userDoesNotExist',
    USER_UPDATE_NOT_ALLOWED: 'users.edit.error.userUpdateNotAllowed',
    USER_MODIFICATION_NOT_ALLOWED_GROUPS: 'users.edit.error.userUpdateNotAllowedNoGroups',
};

export const getSortOrder = (currentSearch, field) => {
    if (!currentSearch) {
        return '';
    }
    if (currentSearch.substring(1) !== field) {
        return '';
    }

    return currentSearch.charAt(0) === '+' ? 'asc' : 'desc';
};

export const getErrorMessageKey = (error, defaultKey) => {
    try {
        const key = codeMapping[error.body.code];
        return typeof key === 'undefined' ? defaultKey : key;
    } catch (_) {
        return defaultKey;
    }
};
