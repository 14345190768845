import Tag from '@rio-cloud/rio-uikit/Tag';
import { equals, filter, head, pipe, prop } from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { canRead } from '../../meta';
import { TableHead } from '../../common/TableHead';
import { LoadMore } from '../../common/LoadMore';
import { getSortOrder } from '../../common/commonFunctions';
import { intlPropType, listMode } from '../../types';
import { makeEmail } from '../../common/emailFunctions';
import { mapDispatchToUserTableProps, mapStateToUserTableProps } from './userTable';
import { withRouter } from '../../../../routes/routerUtils';
import { MfaList } from '../usersidebar/MfaList';
import { extractLanguageForGlossar } from '../../../../configuration/lang/lang';
import './table.css';

const KEY_PROP = 'id';

const keyProp = prop(KEY_PROP);

const stopPropagation = (event) => event.stopPropagation();

export const UserTable = (props) => {
    const {
        groups,
        handleLoadMore,
        handleSelectionChange,
        handleSortChange,
        hasMoreData,
        intl,
        isFirstPage,
        isLoading,
        userSort,
        selectedUserId,
        users,
        usersListMode,
        showUserGroupHelp,
    } = props;

    const makeGroup = (groupId) => {
        const group = pipe(
            filter((candidate) => equals(candidate.id, groupId)),
            head
        )(groups);

        if (!group || !canRead(group)) {
            return null;
        }

        return (
            <li className={'margin-1'} key={groupId}>
                <Tag size={'small'}>{group.name}</Tag>
            </li>
        );
    };

    const translate = (field) =>
        intl.formatMessage({
            id: field,
        });
    const glossarLanguage = extractLanguageForGlossar(intl.locale);

    const items = users.map((user) => {
        let loginFieldContent;
        if (user.preferredLogin === 'email' || (!user.preferredLogin && user.email)) {
            loginFieldContent = (
                <a href={makeEmail(user.email)} onClick={stopPropagation}>
                    {user.email}
                </a>
            );
        } else {
            loginFieldContent = user.phone_number;
        }
        const additionalAuthFactors = user.additional_authentication_factors;
        return (
            <tr
                className={classNames(keyProp(user) === selectedUserId && 'active')}
                data-key={keyProp(user)}
                key={keyProp(user)}
                onClick={() => handleSelectionChange(user)}
            >
                <td
                    className={'ellipsis-1'}
                    data-key="first_name"
                    data-field={translate('users.table.header.firstName')}
                >
                    <div>{user.first_name}</div>
                </td>
                <td className={'ellipsis-1'} data-field={translate('users.table.header.lastName')}>
                    <div>{user.last_name}</div>
                </td>
                <td className={'ellipsis-1'} data-field={translate('users.table.header.login')}>
                    <div>{loginFieldContent}</div>
                </td>
                <td data-field={translate('users.table.header.mfaEnabled')}>
                    <MfaList additionalAuthFactors={additionalAuthFactors} />
                </td>
                <td data-field={translate('users.table.header.userGroups')}>
                    <div>
                        <ul className={'list-inline margin-bottom-0'}>{user.group_ids.map(makeGroup)}</ul>
                    </div>
                </td>
            </tr>
        );
    });

    return (
        <>
            <div>
                <table
                    className={classNames(
                        'table table-layout-fixed table-column-overflow-hidden',
                        'table-bordered table-sticky table-head-filled users-table',
                        listMode.isSplit(usersListMode) && 'table-cards table-multi-cards'
                    )}
                >
                    <thead>
                        <tr>
                            <TableHead
                                field={'first_name'}
                                onClick={handleSortChange}
                                sortBy={'first_name'}
                                sortOrder={getSortOrder(userSort, 'first_name')}
                                text={translate('users.table.header.firstName')}
                            />
                            <TableHead
                                field={'last_name'}
                                onClick={handleSortChange}
                                sortBy={'last_name'}
                                sortOrder={getSortOrder(userSort, 'last_name')}
                                text={translate('users.table.header.lastName')}
                            />
                            <TableHead field={'login'} text={translate('users.table.header.login')} />
                            <TableHead
                                field={'mfaEnabled'}
                                text={
                                    <div>
                                        {translate('users.table.header.mfaEnabled')}
                                        <a
                                            className={classNames(
                                                'rioglyph rioglyph-question-sign margin-left-5 text-muted cursor-pointer no-dynamic-text-decoration'
                                            )}
                                            href={`https://rio.cloud/${glossarLanguage}/glossar/two-factor-authentication?noCookie=1`}
                                            target={'_blank'}
                                            rel={'noreferrer'}
                                            tabIndex={-1}
                                        />
                                    </div>
                                }
                            />
                            <TableHead
                                field={'userGroups'}
                                text={
                                    <div>
                                        {translate('users.table.header.userGroups')}
                                        <span
                                            className={classNames(
                                                'rioglyph rioglyph-question-sign margin-left-5 text-muted cursor-pointer'
                                            )}
                                            aria-hidden={'true'}
                                            onClick={showUserGroupHelp}
                                        />
                                    </div>
                                }
                            />
                        </tr>
                    </thead>
                    <tbody>
                        {items}
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                    </tbody>
                </table>
            </div>
            <LoadMore
                handleLoadMore={handleLoadMore}
                hasMore={hasMoreData}
                isLoading={isLoading}
                showNoMoreDataLabel={!isFirstPage && !hasMoreData}
            />
        </>
    );
};

UserTable.propTypes = {
    handleLoadMore: PropTypes.func.isRequired,
    handleSelectionChange: PropTypes.func.isRequired,
    handleSortChange: PropTypes.func.isRequired,
    intl: intlPropType.isRequired,
    isLoading: PropTypes.bool.isRequired,
    showUserGroupHelp: PropTypes.func.isRequired,
};

export const UserTableContainer = withRouter(
    connect(mapStateToUserTableProps, mapDispatchToUserTableProps)(injectIntl(UserTable))
);
