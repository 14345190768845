import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

export const UnsavedChangesDialog = memo((props) => (
    <ConfirmationDialog
        bsSize={'sm'}
        cancelButtonText={<FormattedMessage id={'dialog.discard.keepEditing'} />}
        confirmButtonText={<FormattedMessage id={'dialog.discard.discardChanges'} />}
        content={<FormattedMessage id={'dialog.discard.reallyDiscardChanges'} />}
        onClickCancel={props.onClickKeepEditing}
        onClickConfirm={props.onClickConfirmDiscard}
        show
        title={<FormattedMessage id={'dialog.discard.discardChangesTitle'} />}
    />
));

UnsavedChangesDialog.propTypes = {
    onClickKeepEditing: PropTypes.func.isRequired,
    onClickConfirmDiscard: PropTypes.func.isRequired,
};
