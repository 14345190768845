import EmptyState from '@rio-cloud/rio-uikit/EmptyState';
import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CreateGroupDialog } from '../creategroup/CreateGroupDialog';
import { GroupTableToolbar } from './GroupTableToolbar';
import { GroupTableContainer } from './GroupTableContainer';
import { mapDispatchToGroupWrapperTableProps, mapStateToGroupTableWrapperProps } from './groupTableWrapper';
import { withRouter } from '../../../../routes/routerUtils';

export const GroupTableWrapper = (props) => {
    const {
        handleCreateGroup,
        hasError,
        hasSearch,
        hasData,
        intl,
        isLoading,
        showCreateButton,
        showCreateDialog,
        maintenanceMode,
    } = props;

    const translate = (field) =>
        intl.formatMessage({
            id: field,
        });

    const noItems = (
        <div className={'display-flex justify-content-center margin-top-25'}>
            <EmptyState
                buttons={[
                    {
                        onClick: handleCreateGroup,
                        text: translate('groups.create.openDialog'),
                    },
                ]}
                headline={translate('groups.table.nodata')}
            />
        </div>
    );

    const searchEmpty = (
        <div className={'display-flex justify-content-center margin-top-25'}>
            <NotFoundState
                headline={translate('groups.table.search.empty.header')}
                message={translate('groups.table.search.empty')}
            />
        </div>
    );

    const errorScreen = (
        <div className={'display-flex justify-content-center margin-top-25'}>
            <ErrorState
                headline={translate('groups.table.fetchError.header')}
                message={translate('groups.table.fetchError')}
            />
        </div>
    );

    const spinner = (
        <div className={'display-flex justify-content-center margin-top-25'}>
            <Spinner />
        </div>
    );

    const createGroupButton = (
        <button className={'btn btn-primary'} onClick={handleCreateGroup} type={'button'} disabled={maintenanceMode}>
            <span className={'rioglyph rioglyph-plus'} />
            <FormattedMessage id={'groups.create.openDialog'} />
        </button>
    );

    const groupTableToolbar = <GroupTableToolbar>{showCreateButton ? createGroupButton : null}</GroupTableToolbar>;

    const renderContent = () => {
        if (isLoading) {
            return spinner;
        }
        if (hasData) {
            return <GroupTableContainer />;
        }
        if (hasSearch) {
            return searchEmpty;
        }
        return noItems;
    };

    return (
        <div data-semantic-id={'groups-view'}>
            {showCreateDialog && <CreateGroupDialog />}
            {hasError ? (
                errorScreen
            ) : (
                <React.Fragment>
                    {groupTableToolbar}
                    {renderContent()}
                </React.Fragment>
            )}
        </div>
    );
};

GroupTableWrapper.propTypes = {
    handleCreateGroup: PropTypes.func.isRequired,
    hasSearch: PropTypes.bool.isRequired,
    hasData: PropTypes.bool.isRequired,
    showCreateButton: PropTypes.bool.isRequired,
    maintenanceMode: PropTypes.bool,
};

export const GroupTableWrapperContainer = withRouter(
    connect(mapStateToGroupTableWrapperProps, mapDispatchToGroupWrapperTableProps)(injectIntl(GroupTableWrapper))
);
