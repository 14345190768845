import Tag from '@rio-cloud/rio-uikit/Tag';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { equals, filter, head, pipe, prop } from 'ramda';
import React from 'react';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { canRead, getRoleDisplayName } from '../../meta';
import { groupPropType, listMode, rolePropType, tagPropType } from '../../types';

import { LoadMore } from '../../common/LoadMore';
import { TableHead } from '../../common/TableHead';

import { getSortOrder } from '../../common/commonFunctions';
import { isRestrictedRole } from './groupService';
import { mapDispatchToGroupTableProps, mapStateToGroupTableProps } from './groupTable';
import { withRouter } from '../../../../routes/routerUtils';

const KEY_PROP = 'id';

const keyProp = prop(KEY_PROP);

const makeRoleKey = (id = 'unknown', scope = {}) => `${id}:${(scope.identifiers || []).join('|')}`;

export const GroupTablePure = (props) => {
    const {
        groups,
        groupsListMode,
        handleLoadMore,
        handleSelectionChange,
        handleSortChange,
        hasMoreData,
        groupSort,
        intl,
        isFirstPage,
        isLoading,
        roles,
        selectedGroupId,
        tags,
        showUserGroupRolesHelp,
    } = props;

    const translate = (field) =>
        intl.formatMessage({
            id: field,
        });

    const makeRole = ({ role_id, scope }, index) => {
        const role = pipe(
            filter((candidate) => equals(candidate.id, role_id)),
            head
        )(roles);

        if (!role) {
            return null;
        }

        const key = `${makeRoleKey(role_id, scope)}-${index}`;

        const isRestricted = isRestrictedRole(tags, scope);

        const restrictedMarker = isRestricted ? '*' : '';
        const title = isRestricted ? translate('groups.restrictedRoleLabel') : null;

        return (
            <li className={'margin-1'} key={key}>
                <Tag size={'small'} title={title}>
                    {translate(getRoleDisplayName(role))}
                    {restrictedMarker}
                </Tag>
            </li>
        );
    };

    const getTableRow = (group) => (
        <tr
            className={classNames(keyProp(group) === selectedGroupId && 'active')}
            data-key={keyProp(group)}
            key={keyProp(group)}
            onClick={() => handleSelectionChange(group)}
        >
            <td data-field={translate('groups.table.header.name')}>
                <div>{group.name}</div>
            </td>
            <td data-field={translate('groups.table.header.description')}>
                <div>{group.description}</div>
            </td>
            <td data-field={translate('groups.table.header.roles')}>
                <div>
                    <ul className={'list-inline margin-bottom-0 line-height-largest'}>
                        {group.assigned_roles.map(makeRole)}
                    </ul>
                </div>
            </td>
        </tr>
    );

    const items = groups.filter(canRead).map((group) => getTableRow(group));

    const tableHeader = (
        <tr>
            <TableHead
                className={'col-xs-2'}
                field={'name'}
                onClick={handleSortChange}
                sortBy={'name'}
                sortOrder={getSortOrder(groupSort, 'name')}
                text={translate('groups.table.header.name')}
            />
            <TableHead
                className={'col-xs-4'}
                field={'description'}
                onClick={handleSortChange}
                sortBy={'description'}
                sortOrder={getSortOrder(groupSort, 'description')}
                text={translate('groups.table.header.description')}
            />
            <TableHead
                field={'roles'}
                text={
                    <div>
                        {translate('groups.table.header.roles')}
                        <span
                            className={classNames(
                                'rioglyph rioglyph-question-sign margin-left-5 text-muted cursor-pointer'
                            )}
                            aria-hidden={'true'}
                            onClick={showUserGroupRolesHelp}
                        />
                    </div>
                }
            />
        </tr>
    );

    return (
        <React.Fragment>
            <div>
                <table
                    className={classNames(
                        'table table-layout-fixed table-column-overflow-hidden',
                        'table-bordered table-sticky table-head-filled groups-table',
                        listMode.isSplit(groupsListMode) && 'table-cards table-multi-cards'
                    )}
                >
                    <thead>{tableHeader}</thead>
                    <tbody>
                        {items}
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                        <tr className={'table-card-placeholder'} />
                    </tbody>
                </table>
            </div>
            <LoadMore
                handleLoadMore={handleLoadMore}
                hasMore={hasMoreData}
                isLoading={isLoading}
                showNoMoreDataLabel={!isFirstPage && !hasMoreData}
            />
        </React.Fragment>
    );
};

GroupTablePure.propTypes = {
    hasMoreData: PropTypes.bool.isRequired,
    isFirstPage: PropTypes.bool.isRequired,
    groups: PropTypes.arrayOf(groupPropType).isRequired,
    selectedGroupId: PropTypes.string,
    handleLoadMore: PropTypes.func.isRequired,
    handleSelectionChange: PropTypes.func.isRequired,
    handleSortChange: PropTypes.func.isRequired,
    roles: PropTypes.arrayOf(rolePropType).isRequired,
    tags: PropTypes.arrayOf(tagPropType).isRequired,
    groupSort: PropTypes.string,
    showUserGroupRolesHelp: PropTypes.func.isRequired,
};

GroupTablePure.defaultProps = {
    groupSort: '',
    selectedGroupId: '',
};

export const GroupTableContainer = withRouter(
    connect(mapStateToGroupTableProps, mapDispatchToGroupTableProps)(injectIntl(GroupTablePure))
);
