import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InviteUserState, PreferredLogin } from './inviteUserTypes';

const initialState: InviteUserState = {
    showInvite: false,
    firstName: '',
    lastName: '',
    preferredLogin: 'email',
    userGroupIds: [],
    login: '',
    inviteInProgress: false,
};

const inviteUserSlice = createSlice({
    name: 'inviteUser',
    initialState,
    reducers: {
        setInviteUserGroupIds(state, action: PayloadAction<string[]>) {
            state.userGroupIds = action.payload;
        },
        resetInviteState() {
            return initialState;
        },
        setNewInviteFirstName(state, action: PayloadAction<string>) {
            state.firstName = action.payload;
        },
        setNewInviteLastName(state, action: PayloadAction<string>) {
            state.lastName = action.payload;
        },
        setNewLogin(state, action: PayloadAction<string>) {
            state.login = action.payload;
        },
        setNewInvitePreferredLogin(state, action: PayloadAction<PreferredLogin>) {
            state.preferredLogin = action.payload;
        },
        setInviteInProgress(state, action: PayloadAction<boolean>) {
            state.inviteInProgress = action.payload;
        },
    },
});

export default inviteUserSlice;
