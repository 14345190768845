import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { SidebarShell } from '../../common/SidebarShell';
import { ModalPrompt } from '../../dialogs/ModalPrompt';
import { canUpdate } from '../../meta';
import { GroupSidebarFooter } from '../../common/SidebarFooter';
import { ConfirmDeleteGroupDialog, ReallyDiscardGroupChanges } from '../../common/ConfirmDialogWrapper';
import { mapDispatchToGroupSidebarProps, mapStateToGroupSidebarProps } from './groupSidebar';
import { EditGroupDialogContainer } from './EditGroupDialogContainer';
import { RouteComponentProps, withRouter } from '../../../../routes/routerUtils';
import { RoleAssignmentList } from '../../listelements/roles/RoleAssignmentList';

export class GroupSidebarPure extends React.Component<GroupSidebarPureProps & RouteComponentProps & any> {
    render() {
        const {
            handleCancel,
            handleEditDetails,
            showConfirmation,
            showDeleteConfirmation,
            showEditDialog,
            selectedGroup,
            currentGroupDescription,
            currentGroupName,
            groupSidebarHasChanges,
            handleConfirmDiscard,
            handleKeepEditing,
            maintenanceMode,
        } = this.props;

        const header = (
            <React.Fragment>
                <h2 className={'margin-top-5'}>
                    {`${currentGroupName}`}
                    {canUpdate(selectedGroup) && (
                        <button
                            className={'btn btn-link btn-icon-only pull-right'}
                            onClick={handleEditDetails}
                            type={'button'}
                            disabled={maintenanceMode}
                        >
                            <span className={'rioglyph rioglyph-pencil'} />
                        </button>
                    )}
                </h2>
                {currentGroupDescription && <p className={'text-size-large'}>{`${currentGroupDescription}`}</p>}
                {!canUpdate(selectedGroup) && (
                    <span>
                        <FormattedMessage id="groups.edit.readOnlyHint" />
                    </span>
                )}
            </React.Fragment>
        );

        const modalPromptWhenSwitchingToUsersTab = (
            <ModalPrompt
                key={selectedGroup.id}
                when={groupSidebarHasChanges}
                handleConfirmDiscard={handleConfirmDiscard}
                handleKeepEditing={handleKeepEditing}
                navigate={(path: string) => this.props.push(path)}
            />
        );

        return (
            <React.Fragment>
                {!showConfirmation && modalPromptWhenSwitchingToUsersTab}
                {showConfirmation && <ReallyDiscardGroupChanges />}
                {showDeleteConfirmation && <ConfirmDeleteGroupDialog />}
                {showEditDialog && <EditGroupDialogContainer />}
                <SidebarShell
                    className={'groups-sidebar'}
                    footer={<GroupSidebarFooter />}
                    handleClose={handleCancel}
                    header={header}
                    title={<FormattedMessage id={'groups.edit.dialogTitle'} />}
                >
                    <RoleAssignmentList />
                </SidebarShell>
            </React.Fragment>
        );
    }
}

interface GroupSidebarPureProps {
    handleCancel: any;
    handleEditDetails: any;
    showConfirmation: boolean;
    showDeleteConfirmation: boolean;
    showEditDialog: boolean;
    selectedGroup: {
        assigned_roles: [
            { role_id: string; scope: { identifier_type: 'account' | 'global' | 'tag'; identifiers: [string] } },
        ];
        description: string;
        id: string;
        name: string;
    };
    currentGroupDescription: string;
    currentGroupName: string;
    groupSidebarHasChanges: boolean;
    maintenanceMode: boolean;
    handleConfirmDiscard: any;
    handleKeepEditing: any;
}

export const GroupSidebarContainer = withRouter(
    connect(mapStateToGroupSidebarProps, mapDispatchToGroupSidebarProps)(GroupSidebarPure)
);
