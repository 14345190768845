import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { FormInputGroup } from '../../common/FormInputGroup';
import { mapDispatchToCreateGroupFormProps, mapStateToCreateGroupFormProps } from './createGroupForm';
import { mapDispatchToEditGroupFormProps, mapStateToEditGroupFormProps } from './editGroupForm';

export class GroupForm extends React.Component {
    componentDidMount() {
        this.props.setInitialValues();
    }

    render() {
        const {
            readOnly,
            groupName,
            groupDescription,
            setGroupName,
            setGroupDescription,
            groupDescriptionError,
            groupNameError,
        } = this.props;

        return (
            <div className={'form-group'}>
                <FormInputGroup
                    fieldError={groupNameError && <FormattedMessage id={groupNameError} />}
                    fieldLabel={<FormattedMessage id={'groups.create.label.name'} />}
                    fieldName={'name'}
                    onChange={setGroupName}
                    readOnly={readOnly}
                    value={groupName}
                />
                <FormInputGroup
                    fieldError={groupDescriptionError && <FormattedMessage id={groupDescriptionError} />}
                    fieldLabel={<FormattedMessage id={'groups.create.label.description'} />}
                    fieldName={'description'}
                    onChange={setGroupDescription}
                    readOnly={readOnly}
                    value={groupDescription}
                />
            </div>
        );
    }
}

GroupForm.propTypes = {
    readOnly: PropTypes.bool,
    groupName: PropTypes.string.isRequired,
    groupDescription: PropTypes.string.isRequired,
    setGroupName: PropTypes.func.isRequired,
    setGroupDescription: PropTypes.func.isRequired,
    groupDescriptionError: PropTypes.string,
    groupNameError: PropTypes.string,
    setInitialValues: PropTypes.func,
};

GroupForm.defaultProps = {
    readOnly: true,

    groupDescriptionError: null,
    groupNameError: null,

    setInitialValues: () => {},
};

export const EditGroupFormContainer = connect(mapStateToEditGroupFormProps, mapDispatchToEditGroupFormProps)(GroupForm);

export const CreateGroupFormContainer = connect(
    mapStateToCreateGroupFormProps,
    mapDispatchToCreateGroupFormProps
)(GroupForm);
