import { memo, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { OverlayTooltip } from './OverlayTooltip';
import { PreferredLogin } from '../users/invite/inviteUserTypes';
import Select, { SelectOption } from '@rio-cloud/rio-uikit/Select';

const getPreferredLoginIndex = (preferredLogin: PreferredLogin) => (preferredLogin === 'email' ? '0' : '1');

interface Props {
    fieldName: string;
    fieldLabel: ReactNode;
    fieldError: ReactNode;
    fieldErrorExample: ReactNode;
    value?: string;
    onChangePreferredLoginInput: (login: string) => void;
    tooltip?: string;
    onChangePreferredLogin: (preferredLogin: PreferredLogin) => void;
    preferredLogin: PreferredLogin;
}

interface EnrichedSelectOption extends SelectOption {
    preferredLogin: PreferredLogin;
}

const isEnrichedSelectOption = (arg0: SelectOption | undefined): arg0 is EnrichedSelectOption =>
    arg0 !== undefined && 'preferredLogin' in arg0;

export const FormInputGroupWithPreferredLogin = memo((props: Props) => {
    const [touched, toggleTouch] = useState(false);

    const options: Array<EnrichedSelectOption> = [
        {
            id: getPreferredLoginIndex('email'),
            preferredLogin: 'email',
            label: <FormattedMessage id={'users.invite.label.email'} />,
        },
        {
            id: getPreferredLoginIndex('phone'),
            preferredLogin: 'phone',
            label: <FormattedMessage id={'users.invite.label.phoneNumber'} />,
        },
    ];

    const {
        fieldName,
        fieldLabel,
        fieldError,
        fieldErrorExample,
        value,
        onChangePreferredLoginInput,
        tooltip,
        onChangePreferredLogin,
        preferredLogin,
    } = props;

    return (
        <div className={classNames('form-group', touched && fieldError && 'has-feedback has-error')}>
            <label className={'control-label display-flex justify-content-between'} htmlFor={fieldName}>
                {fieldLabel}
                {tooltip && <OverlayTooltip popoverTooltipMessage={tooltip} tooltipPlacement={'right-top'} />}
                <span className={'pull-right'}>
                    <FormattedMessage id={'users.invite.label.loginMethodInformation'} />
                </span>
            </label>
            <div className={'input-group position-relative width-100pct'}>
                <div className={'input-group-btn'}>
                    <Select
                        options={options}
                        value={[getPreferredLoginIndex(preferredLogin)]}
                        onChange={(selectOption) => {
                            onChangePreferredLoginInput('');
                            toggleTouch(false);
                            if (isEnrichedSelectOption(selectOption)) {
                                onChangePreferredLogin(selectOption.preferredLogin);
                            }
                        }}
                    />
                </div>
                <input
                    className={'form-control'}
                    name={fieldName}
                    onChange={(evt) => onChangePreferredLoginInput(evt.target.value)}
                    readOnly={false}
                    type={'text'}
                    onBlur={() => toggleTouch(true)}
                    value={value}
                />
                {touched && fieldError && <span className={'form-control-feedback rioglyph rioglyph-warning-sign'} />}
            </div>
            {touched && fieldError && fieldErrorExample && (
                <div className={'help-block text-right'}>
                    <div>{fieldError}</div>
                    <div>{fieldErrorExample}</div>
                </div>
            )}
        </div>
    );
});

FormInputGroupWithPreferredLogin.displayName = 'FormInputGroupWithPreferredLogin';
