import { isEmpty } from 'ramda';
import { getAllowedUserActions, getIsLoading, getLoadingError, getUsers } from '../../appSelectors';
import { isAppInMaintenanceMode } from '../../../selectors';
import { fireTrackingEvent, TRACKING_CATEGORIES, USER_TRACKING_ACTIONS } from '../../../../tracking/gtm-helper';
import { selectShowCreateUserModal, selectUserSearch } from '../../../../routes/selector';
import { showCreateUserModal } from '../../../../routes/actions';

const canInviteUsers = (state) => {
    const allowedUserActions = getAllowedUserActions(state);
    return allowedUserActions.includes('add');
};

export const mapStateToUserTableWrapperProps = (state, ownProps) => ({
    showInviteButton: canInviteUsers(state),
    hasError: Boolean(getLoadingError(state)),
    hasData: !isEmpty(getUsers(state)),
    showInvite: selectShowCreateUserModal(ownProps),
    isLoading: getIsLoading(state),
    hasSearch: Boolean(selectUserSearch(ownProps)),
    maintenanceMode: isAppInMaintenanceMode(state),
});

export const mapDispatchToUserTableWrapperProps = (_state, ownProps) => ({
    handleInviteUser: () => {
        fireTrackingEvent(TRACKING_CATEGORIES.USERADMIN_USER, USER_TRACKING_ACTIONS.CREATE_USER);
        showCreateUserModal(ownProps);
    },
});
