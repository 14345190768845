import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../configuration/setup/hooks';
import { isAppInMaintenanceMode } from '../features/selectors';

export const MaintenanceBanner = () => {
    const maintenanceMode = useAppSelector(isAppInMaintenanceMode);

    return maintenanceMode ? (
        <ApplicationLayoutBodyBanner>
            <div>
                <FormattedMessage id={'maintenanceMode.message'} />
            </div>
        </ApplicationLayoutBodyBanner>
    ) : null;
};
