import { Provider } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';

import { main } from './configuration';

import { config } from './config';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import { UserAdmin } from './features/app/App';
import { createRoot } from 'react-dom/client';
import { store } from './configuration/setup/store';
import { handleLoginRedirect } from './configuration/login/redirect';
import { User } from 'oidc-client-ts';
import { InvalidLogin } from './components/invalidLogin';

const renderApplication = (user: User) => {
    const container = document.getElementById('root') as HTMLElement;
    const root = createRoot(container);

    let showApp = true;
    try {
        showApp = user ? user.profile.sub.startsWith('prod-rio-users:') : true;
    } catch (e) {
        console.error(e);
    }

    if (showApp)
        // Note that we need to use the base "Router" with a "hash" history
        // because the "HashRouter" doesn't allow handing in a history
        // from the outside. So this is effectively a "HashRouter" despite
        // that not being obvious here
        root.render(
            <ErrorBoundary>
                <Provider store={store}>
                    <HashRouter>
                        <Routes>
                            <Route path={'*'} element={<UserAdmin />} />
                        </Routes>
                    </HashRouter>
                </Provider>
            </ErrorBoundary>
        );
    else {
        root.render(<InvalidLogin />);
    }
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication).catch(console.error);
}
