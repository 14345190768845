import { getCurrentGroupName } from './groupPropertiesService';
import { confirmDiscardGroupSidebar, deleteGroupThunk } from './groupSidebarThunks';
import { fireTrackingEvent, TRACKING_CATEGORIES, USER_GROUP_TRACKING_ACTIONS } from '../../../../tracking/gtm-helper';
import { deselectGroup } from '../../../../routes/actions';
import { AppDispatch, RootState } from '../../../../configuration/setup/store';
import { RouteComponentProps } from '../../../../routes/routerUtils';
import groupSidebarSlice from './groupSidebarSlice';

export const mapStateToDeleteGroupDialogProps = (state: RootState) => ({
    values: { name: getCurrentGroupName(state) },
    cancelButtonLabel: 'dialog.delete.cancel',
    confirmButtonLabel: 'dialog.delete.confirm',
    content: 'dialog.group.delete.message',
    title: 'dialog.group.delete.title',
});

export const mapDispatchToDeleteGroupDialogProps = (dispatch: AppDispatch, ownProps: RouteComponentProps) => ({
    clickConfirm: () => {
        fireTrackingEvent(
            TRACKING_CATEGORIES.USERADMIN_USER_GROUP,
            USER_GROUP_TRACKING_ACTIONS.DELETE_USER_GROUP_CONFIRM
        );
        dispatch(deleteGroupThunk);
        dispatch(groupSidebarSlice.actions.showDeleteGroupConfirmation(false));
        deselectGroup(ownProps);
    },
    clickCancel: () => dispatch(groupSidebarSlice.actions.showDeleteGroupConfirmation(false)),
});

export const mapStateToReallyDiscardGroupChangesProps = () => ({
    values: {},
    cancelButtonLabel: 'dialog.discard.keepEditing',
    confirmButtonLabel: 'dialog.discard.discardChanges',
    content: 'dialog.discard.reallyDiscardChanges',
    title: 'dialog.discard.discardChangesTitle',
});

export const mapDispatchToReallyDiscardGroupChangesProps = (dispatch: AppDispatch) => ({
    clickConfirm: () => {
        fireTrackingEvent(
            TRACKING_CATEGORIES.USERADMIN_USER_GROUP,
            USER_GROUP_TRACKING_ACTIONS.DISCARD_USER_GROUP_EDITING
        );
        dispatch(confirmDiscardGroupSidebar);
    },
    clickCancel: () => {
        fireTrackingEvent(
            TRACKING_CATEGORIES.USERADMIN_USER_GROUP,
            USER_GROUP_TRACKING_ACTIONS.CONTINUE_USER_GROUP_EDITING
        );
        dispatch(groupSidebarSlice.actions.showGroupConfirmation(false));
    },
});
