import { FormattedMessage } from 'react-intl';
import {
    DEFAULT_BUYER_GROUPS_DESCRIPTION,
    DEFAULT_GROUPS_DESCRIPTION,
    DEFAULT_SUPPLIER_GROUPS_DESCRIPTION,
} from '../../../listelements/groups/defaultGroups';
import { AccountType } from '../../../../../configuration/login/loginSlice';

export const UserGroupExplanation = (props: { accountType?: AccountType }) => {
    let defaultGroups;

    switch (props.accountType) {
        case 'SUPPLIER':
            defaultGroups = DEFAULT_SUPPLIER_GROUPS_DESCRIPTION;
            break;
        case 'BUYER':
            defaultGroups = DEFAULT_BUYER_GROUPS_DESCRIPTION;
            break;
        case 'CARRIER':
        default:
            defaultGroups = DEFAULT_GROUPS_DESCRIPTION;
            break;
    }

    return (
        <>
            <h4>
                <FormattedMessage id={'header.info.entities.usergroups.title'} />
            </h4>
            <div className="padding-top-10">
                <FormattedMessage id={'header.info.entities.usergroups.linkText'} />{' '}
                <FormattedMessage id={'header.info.entities.usergroups.text'} />
            </div>
            <div className={'table-responsive margin-bottom-20 padding-top-20'}>
                <table className={'table text-center margin-bottom-0'}>
                    <thead>
                        <tr>
                            <th scope={'col'}>
                                <FormattedMessage id={'groups.table.header.name'} />
                            </th>
                            <th scope={'col'} className={'text-left'}>
                                <FormattedMessage id={'groups.table.header.description'} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(defaultGroups).map(([key, value], _) => (
                            <tr key={key}>
                                <th className={'text-left'}>
                                    <span>
                                        <FormattedMessage id={value.titleId} />
                                    </span>
                                </th>
                                <td className={'text-left'}>
                                    <span>
                                        <FormattedMessage id={value.generalDescription} />
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};
