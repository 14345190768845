import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from '../../../notifications/notificationType';
import {
    getInviteFirstName,
    getInviteLastName,
    getInviteLogin,
    getInvitePreferredLogin,
    getInviteUserGroupIds,
} from './inviteUserSelectors';
import { ThunkAction } from '@reduxjs/toolkit';
import { UserToInvite } from './inviteUserTypes';
import { closeCreateUserModal } from '../../../../routes/actions';
import { getAccountId } from '../../../../configuration/tokenHandling/tokenHandlingSlice';
import { v4 as uuid } from 'uuid';
import { inviteUser } from '../../../api/api';
import { RouteComponentProps } from '../../../../routes/routerUtils';
import { getLocale } from '../../../../configuration/lang/langSlice';
import { RootState } from '../../../../configuration/setup/store';
import inviteUserSlice from './inviteUserSlice';
import appSlice from '../../appSlice';
import notificationSlice from '../../../notifications/notificationSlice';

export const submitInviteUser =
    (props: RouteComponentProps): ThunkAction<any, RootState, unknown, any> =>
    (dispatch, getState) => {
        dispatch(inviteUserSlice.actions.setInviteInProgress(true));

        const state = getState();
        const userToInvite = buildUserToInvite(state);

        const notifyFixableError = (error: Error) => {
            if (error.message === 'UNEXPECTED_STATUS_CODE') {
                dispatch(
                    notificationSlice.actions.displayNotification({
                        notificationType: ERROR_NOTIFICATION,
                        notificationKey: 'users.invite.error.userAlreadyExists',
                    })
                );
                return;
            }
            dispatch(
                notificationSlice.actions.displayNotification({
                    notificationType: ERROR_NOTIFICATION,
                    notificationKey: 'users.invite.error.invitationNotSent',
                })
            );
        };

        return inviteUser(userToInvite)
            .then(() => {
                dispatch(
                    notificationSlice.actions.displayNotification({
                        notificationType: SUCCESS_NOTIFICATION,
                        notificationKey: 'users.invite.invitationSent',
                    })
                );
                closeCreateUserModal(props);
                dispatch(inviteUserSlice.actions.resetInviteState());
                dispatch(appSlice.actions.userCreated(userToInvite.id));
            })
            .catch((error: any) => {
                closeCreateUserModal(props);
                dispatch(inviteUserSlice.actions.resetInviteState());
                // api.reportError(error);
                notifyFixableError(error); // TODO use proper string here
            });
    };

export const buildUserToInvite = (state: RootState) => {
    const accountId = getAccountId(state);
    if (!accountId) {
        throw Error(`User not authenticated`);
    }

    const userToInvite: UserToInvite = {
        first_name: getInviteFirstName(state),
        last_name: getInviteLastName(state),
        preferred_login: getInvitePreferredLogin(state),
        preferred_language: getLocale(state)!,
        group_ids: getInviteUserGroupIds(state),
        account_id: accountId,
        id: uuid(),
    };

    userToInvite[getInvitePreferredLogin(state) === 'email' ? 'email' : 'phone_number'] = getInviteLogin(state);

    return userToInvite;
};
