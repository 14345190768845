import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { ApplicationHeaderContainer } from '../../../components/ApplicationHeader';
import { UserSidebarContainer } from './usersidebar/UserSidebarContainer';
import { AccessNotAllowed } from '../common/AccessNotAllowed';
import { GenericErrorState } from '../common/GenericErrorState';
import { UserTableWrapperContainer } from './usertable/UserTableWrapperContainer';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { isAppInMaintenanceMode } from '../../selectors';
import { getAccessIsForbidden, getLastCreatedUserId, getSelectedUser, getServerErrorCode } from '../appSelectors';
import { useEffect } from 'react';
import { fetchDataForUsersTab, fetchUsersThunk } from './userstab/usersTabThunks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectUserSearch, selectUserSort } from '../../../routes/selector';
import { MaintenanceBanner } from '../../../components/MaintenanceBanner';

export const Users = () => {
    const dispatch = useAppDispatch();

    const selectedUser = useAppSelector(getSelectedUser);
    const lastCreatedUserId = useAppSelector(getLastCreatedUserId);
    const serverErrorCode = useAppSelector(getServerErrorCode);
    const accessIsForbidden = useAppSelector(getAccessIsForbidden);
    const maintenanceMode = useAppSelector(isAppInMaintenanceMode);

    const location = useLocation();
    const params = useParams();
    const push = useNavigate();

    const userSearch = selectUserSearch({ location, params, push });
    const userSort = selectUserSort({ location, params, push });

    useEffect(() => {
        fetchDataForUsersTab({ location, params, push })(dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchUsersThunk({ location, params, push })(dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSearch, userSort, lastCreatedUserId]);

    return (
        <ApplicationLayout>
            <ApplicationHeaderContainer />
            {selectedUser && <UserSidebarContainer maintenanceMode={maintenanceMode} />}
            <ApplicationLayout.Body className={'UserAdmin responsive'} banner={<MaintenanceBanner />}>
                {accessIsForbidden ? (
                    <AccessNotAllowed />
                ) : serverErrorCode ? (
                    <GenericErrorState />
                ) : (
                    <UserTableWrapperContainer />
                )}
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
};
