import { commonStringValidation, commonStringValidationAllowEmpty } from '../../../validation/userInputValidation';
import { getPersistedDescriptionOfSelectedGroup, getPersistedNameOfSelectedGroup } from '../../appSelectors';
import { getGroupDescriptionModification, getGroupNameModification } from './groupSidebarSelectors';

export const getEditGroupNameError = (state) => {
    const name = getGroupNameModification(state);
    return commonStringValidation(name, 'groups.create.error.invalidName');
};

export const getEditGroupDescriptionError = (state) => {
    const description = getGroupDescriptionModification(state);
    return commonStringValidationAllowEmpty(description, 'groups.create.error.invalidDescription');
};

export const canSubmitEditGroup = (state) => {
    const modifiedDescription = getGroupDescriptionModification(state);
    const modifiedName = getGroupNameModification(state);
    const persistedDescription = getPersistedDescriptionOfSelectedGroup(state);
    const persistedName = getPersistedNameOfSelectedGroup(state);
    const noNameChanges = modifiedName === persistedName;
    const noDescriptionChanges = modifiedDescription === persistedDescription;

    const descriptionError = getEditGroupDescriptionError(state);
    const nameError = getEditGroupNameError(state);

    if (noNameChanges && noDescriptionChanges) {
        return false;
    }

    return !descriptionError && !nameError;
};
