import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { PropsWithChildren } from 'react';

export const InvalidLogin = () => {
    return (
        <InvalidLoginWrapper>
            <div className={'text-size-h2 text-center margin-bottom-10 text-color-dark'}>Login Error</div>
            <div className={'margin-left-20 margin-right-20 margin-bottom-25 text-center text-color-darker'}>
                You&apos;re logged in with a non-standard RIO user account.
            </div>
            <div className={'display-flex justify-content-center'}>
                <a href={'https://auth.iam.rio.cloud/logout'} className={'btn btn-default'}>
                    Logout
                </a>
            </div>
        </InvalidLoginWrapper>
    );
};

const InvalidLoginWrapper = (props: PropsWithChildren) => (
    <ApplicationLayout>
        <ApplicationLayout.Body>
            <div className={'display-flex justify-content-center height-100pct'}>
                <div className={'width-100pct max-width-400 align-self-center'}>
                    <div className={'panel-default panel panel-body padding-25 flex-column'}>{props.children}</div>
                </div>
            </div>
        </ApplicationLayout.Body>
    </ApplicationLayout>
);
