import { equals } from 'ramda';
import { getFirstNameOfSelectedUser, getGroupIdsOfSelectedUser, getLastNameOfSelectedUser } from '../../appSelectors';
import {
    getEmailInSidebar,
    getFirstNameInSidebar,
    getLastNameInSidebar,
    getPhoneNumberInSidebar,
    getUserGroupsInSidebar,
} from './userSidebarSelectors';
import { RootState } from '../../../../configuration/setup/store';

export const userSidebarHasChanges = (state: RootState) => {
    const newGroups = getUserGroupsInSidebar(state);
    const newEmail = getEmailInSidebar(state);
    const newPhoneNumber = getPhoneNumberInSidebar(state);

    const lastNameChanges = getLastNameInSidebar(state) !== getLastNameOfSelectedUser(state);
    const firstNameChanges = getFirstNameInSidebar(state) !== getFirstNameOfSelectedUser(state);

    const groupChanges = newGroups !== undefined && !equals(getGroupIdsOfSelectedUser(state), newGroups);

    const fieldChanges = Boolean(newEmail || newPhoneNumber || firstNameChanges || lastNameChanges);
    return groupChanges || fieldChanges;
};
