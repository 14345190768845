import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RoleAccess } from './roleDescriptionContent';

interface Props {
    access: RoleAccess;
    details?: string;
}

export const RoleAccessRights = (props: Props) => {
    const { access, details } = props;

    const intl = useIntl();

    const appendix = details ? '.details' : '';
    const accessModified = access + appendix;

    let className = 'label pull-right label-';
    let linkClassName = 'text-color-';
    switch (access) {
        case 'roles.description.fullAccess':
            className += 'success';
            linkClassName += 'success';
            break;
        case 'roles.description.restrictedAccess':
            className += 'warning';
            linkClassName += 'warning';
            break;
        case 'roles.description.noAccess':
            className += 'danger';
            linkClassName += 'danger';
            break;
        default:
            className += 'muted';
            linkClassName += 'muted';
            break;
    }

    const title = details ? intl.formatMessage({ id: details }) : undefined;

    return (
        <React.Fragment>
            <span className={className}>
                {
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                        className={linkClassName}
                        data-toggle={'tooltip'}
                        title={title}
                        style={{ textDecoration: 'none' }}
                    >
                        <FormattedMessage id={accessModified} />
                    </a>
                }
            </span>
        </React.Fragment>
    );
};
