export const updateQueryParameter = (
    searchTerm: string,
    queryParamKey: QUERY_PARAMETER,
    queryParamValue: string
): string => {
    const queryParameters = new URLSearchParams(searchTerm);
    if (queryParameters.has(queryParamKey)) {
        queryParameters.delete(queryParamKey);
    }
    if (queryParamValue !== '') {
        queryParameters.append(queryParamKey, queryParamValue);
    }
    return '?' + queryParameters.toString();
};

export function getQueryParameter(searchTerm: string, queryParamKey: QUERY_PARAMETER): string | null {
    const queryParameters = new URLSearchParams(searchTerm);
    return queryParameters.has(queryParamKey) ? queryParameters.get(queryParamKey) : null;
}

export const removeQueryParameter = (searchTerm: string, queryParamKey: QUERY_PARAMETER): string => {
    const queryParameters = new URLSearchParams(searchTerm);
    queryParameters.delete(queryParamKey);
    return '?' + queryParameters.toString();
};

export enum QUERY_PARAMETER {
    INFO_MODAL = 'infoModal',
    GROUP_SEARCH = 'groupsSearch',
    GROUP_SORT = 'groupsSort',
    USER_SEARCH = 'usersSearch',
    USER_SORT = 'usersSort',
    CREATE_USER_MODAL = 'createUserModal',
    CREATE_GROUP_MODAL = 'createGroupModal',
}
