import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { intlPropType, listMode } from '../types';

const getViewModeButton = (handleSelectSplitMode, isActive, icon) => (
    <button
        className={classNames('btn btn-default btn-icon-only', isActive && 'active')}
        onClick={handleSelectSplitMode}
        type={'button'}
    >
        <span className={classNames('rioglyph', icon)} />
    </button>
);

export const TableToolbarPure = memo((props) => {
    const {
        children,
        intl,
        dataListMode,
        handleSearchChange,
        handleSelectSplitMode,
        handleSelectTableMode,
        searchPlaceholder,
        searchValue,
    } = props;

    const searchField = (
        <div className={'table-toolbar-search input-group'}>
            <span className={'input-group-addon'}>
                <span className={'rioglyph rioglyph-search'} />
            </span>
            <ClearableInput
                autoFocus
                onChange={handleSearchChange}
                placeholder={intl.formatMessage({ id: searchPlaceholder })}
                value={searchValue ? searchValue : ''}
            />
        </div>
    );

    return (
        <div className={'table-toolbar'}>
            <div className={'table-toolbar-container'}>
                <div className={'table-toolbar-group-left'}>
                    <div className={'table-toolbar-column'}>{children}</div>
                </div>
                <div className={classNames('table-toolbar-group-right')}>
                    <div className={'table-toolbar-column'}>{searchField}</div>
                    <div className={'table-toolbar-column'}>
                        <div className={'btn-group display-flex flex-row'}>
                            {getViewModeButton(
                                handleSelectTableMode,
                                listMode.isTable(dataListMode),
                                'rioglyph-table-view'
                            )}
                            {getViewModeButton(
                                handleSelectSplitMode,
                                listMode.isSplit(dataListMode),
                                'rioglyph-split-view'
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

TableToolbarPure.propTypes = {
    handleSelectSplitMode: PropTypes.func.isRequired,
    handleSelectTableMode: PropTypes.func.isRequired,
    dataListMode: PropTypes.oneOf(['table', 'split']).isRequired,
    handleSearchChange: PropTypes.func.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,
    searchValue: PropTypes.string,
    intl: intlPropType.isRequired,
};

TableToolbarPure.defaultProps = {
    searchValue: '',
};
