import { includes, pathOr, prop } from 'ramda';

export const canRead = (it) => includes('read', pathOr(['read'], ['metadata', 'allowed_actions'], it));

export const canUpdate = (it) => includes('update', pathOr(['update'], ['metadata', 'allowed_actions'], it));

export const canDelete = (it) => includes('delete', pathOr(['delete'], ['metadata', 'allowed_actions'], it));

const roleName = /^roles\.names\./u;

export const getRoleDisplayName = (role) => {
    const key = prop('key', role);

    if (roleName.test(key)) {
        return key;
    }

    return prop('name', role);
};
