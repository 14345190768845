import { fetchAccountById } from '../features/api/api';
import { reportErrorToSentry } from './setup/sentry';
import { processAccountsApiResponse } from '../features/api/accountBackendCodec';
import { accountType } from './login/loginSlice';

export const getAccountType = async (accountId: string) => {
    try {
        const account = await fetchAccountById(accountId).then(processAccountsApiResponse);
        return account.accountType;
    } catch (e) {
        reportErrorToSentry(e);
        // use CARRIER as fallback
        return accountType.UNKNOWN;
    }
};
