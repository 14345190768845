import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Restriction } from '../creategroup/createGroupTypes';
import { User, UserGroupRole } from '../../appTypes';
import { SliceActions } from '../../reduxHelper';

export interface GroupSidebarInitialState {
    modifications: {
        usersToAdd: User[];
        usersToRemove: User[];
        rolesToAdd: UserGroupRole[];
        rolesToRemove: UserGroupRole[];
        restrictionsToAdd: Restriction[];
        restrictionsToRemove: Restriction[];

        newGroupName: string;
        newGroupDescription: string;
    };
    showDeleteConfirmation: boolean;
    showEditDialog: boolean;
    showConfirmation: boolean;
}

export const initialState: GroupSidebarInitialState = {
    modifications: {
        usersToAdd: [],
        usersToRemove: [],
        rolesToAdd: [],
        rolesToRemove: [],
        restrictionsToAdd: [],
        restrictionsToRemove: [],

        newGroupName: '',
        newGroupDescription: '',
    },
    showDeleteConfirmation: false,
    showEditDialog: false,
    showConfirmation: false,
};

const groupSidebarSlice = createSlice({
    name: 'groupSidebarSlice',
    initialState,
    reducers: {
        addUser(state, action: PayloadAction<User>) {
            const previousUsersToAdd = state.modifications.usersToAdd;
            state.modifications.usersToAdd = [...previousUsersToAdd, action.payload];
        },
        removeUser(state, action: PayloadAction<User>) {
            const userWasNotPersisted =
                state.modifications.usersToAdd.filter((user) => user.id === action.payload.id).length > 0;
            const newUsersToAdd = state.modifications.usersToAdd.filter((user) => user.id !== action.payload.id);
            const newUsersToRemove = userWasNotPersisted
                ? state.modifications.usersToRemove
                : [...state.modifications.usersToRemove, action.payload];

            state.modifications.usersToAdd = newUsersToAdd;
            state.modifications.usersToRemove = newUsersToRemove;
        },
        resurrectUser(state, action: PayloadAction<User>) {
            state.modifications.usersToRemove = state.modifications.usersToRemove.filter(
                (user) => user.id !== action.payload.id
            );
        },
        addRole(state, action: PayloadAction<UserGroupRole>) {
            const previousRolesToAdd = state.modifications.rolesToAdd;
            state.modifications.rolesToAdd = [...previousRolesToAdd, action.payload];
        },
        removeRole(state, action: PayloadAction<UserGroupRole>) {
            const roleWasNotPersisted = state.modifications.rolesToAdd
                .map((role) => role.id)
                .includes(action.payload.id);
            const newRolesToAdd = state.modifications.rolesToAdd.filter((role) => role.id !== action.payload.id);
            const newRolesToRemove = roleWasNotPersisted
                ? state.modifications.rolesToRemove
                : [...state.modifications.rolesToRemove, action.payload];

            state.modifications.rolesToAdd = newRolesToAdd;
            state.modifications.rolesToRemove = newRolesToRemove;
        },
        resurrectRole(state, action: PayloadAction<UserGroupRole>) {
            state.modifications.rolesToRemove = state.modifications.rolesToRemove.filter(
                (role) => role.id !== action.payload.id
            );
        },
        addRestriction(state, action: PayloadAction<{ restriction: Restriction; roleId: string }>) {
            const newRestriction = {
                ...action.payload.restriction,
                parentId: action.payload.roleId,
            };
            const previousRestrictionsToAdd = state.modifications.restrictionsToAdd;
            state.modifications.restrictionsToAdd = [...previousRestrictionsToAdd, newRestriction];
        },
        removeRestriction(state, action: PayloadAction<Restriction>) {
            const restrictionWasNotPersisted =
                state.modifications.restrictionsToAdd.filter((restriction) =>
                    compareRelevantFields(restriction, action.payload)
                ).length > 0;

            const newRestrictionsToAdd = state.modifications.restrictionsToAdd.filter(
                (restriction) =>
                    restriction.id !== action.payload.id || restriction.parentId !== action.payload.parentId
            );
            const newRestrictionsToRemove = restrictionWasNotPersisted
                ? state.modifications.restrictionsToRemove
                : [...state.modifications.restrictionsToRemove, action.payload];

            state.modifications.restrictionsToAdd = newRestrictionsToAdd;
            state.modifications.restrictionsToRemove = newRestrictionsToRemove;
        },
        resurrectRestriction(state, action: PayloadAction<Restriction>) {
            state.modifications.restrictionsToRemove = state.modifications.restrictionsToRemove.filter(
                (restriction) => restriction.id !== action.payload.id
            );
        },
        updateGroupProperties(state, action: PayloadAction<{ newGroupName: string; newGroupDescription: string }>) {
            state.modifications.newGroupName = action.payload.newGroupName;
            state.modifications.newGroupDescription = action.payload.newGroupDescription;
        },
        reset() {
            return initialState;
        },
        updateGroupDescription(state, action: PayloadAction<string>) {
            state.modifications.newGroupDescription = action.payload;
        },
        updateGroupName(state, action: PayloadAction<string>) {
            state.modifications.newGroupName = action.payload;
        },
        deleteGroupSuccess(state) {
            state.showConfirmation = false;
        },
        deleteGroupError(state) {
            state.showConfirmation = false;
        },
        showDeleteGroupConfirmation(state, action: PayloadAction<boolean>) {
            state.showDeleteConfirmation = action.payload;
        },
        showEditGroupDialog(state, action: PayloadAction<boolean>) {
            state.showEditDialog = action.payload;
        },
        showGroupConfirmation(state, action: PayloadAction<boolean>) {
            state.showConfirmation = action.payload;
        },
        resetGroupState() {
            return initialState;
        },
        saveGroupSuccess() {},
        saveGroupError() {},
    },
});

const compareRelevantFields = (restriction: Restriction, restrictionToBeRemoved: Restriction) =>
    restriction.id === restrictionToBeRemoved.id && restriction.parentId === restrictionToBeRemoved.parentId;

export type GroupSidebarActionTypes = SliceActions<typeof groupSidebarSlice.actions>;
export default groupSidebarSlice;
