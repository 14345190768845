import EmptyState from '@rio-cloud/rio-uikit/EmptyState';
import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { intlPropType } from '../../types';
import { InviteUserContainer } from '../invite/InviteUserContainer';
import { UserTableToolbar } from './UserTableToolbar';
import { UserTableContainer } from './UserTableContainer';
import { mapDispatchToUserTableWrapperProps, mapStateToUserTableWrapperProps } from './userTableWrapper';
import { withRouter } from '../../../../routes/routerUtils';

export const UserTableWrapper = (props) => {
    const {
        handleInviteUser,
        hasError,
        hasSearch,
        intl,
        isLoading,
        showInvite,
        showInviteButton,
        hasData,
        maintenanceMode,
    } = props;

    const translate = (field) =>
        intl.formatMessage({
            id: field,
        });

    const noItems = (
        <div className={'display-flex justify-content-center margin-top-25'}>
            <EmptyState
                buttons={[
                    {
                        onClick: handleInviteUser,
                        text: translate('users.invite.openDialog'),
                    },
                ]}
                headline={translate('users.table.nodata')}
            />
        </div>
    );

    const searchEmpty = (
        <div className={'display-flex justify-content-center margin-top-25'}>
            <NotFoundState
                headline={translate('users.table.search.empty.header')}
                message={translate('users.table.search.empty')}
            />
        </div>
    );

    const errorScreen = (
        <div className={'display-flex justify-content-center margin-top-25'}>
            <ErrorState
                headline={translate('users.table.fetchError.header')}
                message={translate('users.table.fetchError')}
            />
        </div>
    );

    const spinner = (
        <div className={'display-flex justify-content-center margin-top-25'}>
            <Spinner />
        </div>
    );

    const inviteButton = (
        <button className={'btn btn-primary'} onClick={handleInviteUser} type={'button'} disabled={maintenanceMode}>
            <span className={'rioglyph rioglyph-plus'} />
            {translate('users.invite.openDialog')}
        </button>
    );

    const groupTableToolbar = <UserTableToolbar>{showInviteButton ? inviteButton : null}</UserTableToolbar>;

    const renderContent = () => {
        if (isLoading) {
            return spinner;
        }
        if (hasData) {
            return <UserTableContainer />;
        }
        if (hasSearch) {
            return searchEmpty;
        }
        return noItems;
    };

    return (
        <div data-semantic-id={'users-view'}>
            {showInvite && <InviteUserContainer />}
            {hasError ? (
                errorScreen
            ) : (
                <>
                    {groupTableToolbar}
                    {renderContent()}
                </>
            )}
        </div>
    );
};

UserTableWrapper.propTypes = {
    handleInviteUser: PropTypes.func.isRequired,
    intl: intlPropType.isRequired,
    isLoading: PropTypes.bool.isRequired,
    showInviteButton: PropTypes.bool,
    showInvite: PropTypes.bool.isRequired,
    hasData: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    hasSearch: PropTypes.bool.isRequired,
    maintenanceMode: PropTypes.bool,
};

export const UserTableWrapperContainer = withRouter(
    connect(mapStateToUserTableWrapperProps, mapDispatchToUserTableWrapperProps)(injectIntl(UserTableWrapper))
);
