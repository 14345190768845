import { accessToken } from '../../configuration/tokenHandling/accessToken';

export const getRequest = (): RequestInit => ({
    method: 'GET',
    headers: {
        Authorization: `Bearer ${accessToken.getAccessToken()}`,
    },
});

export const postRequest = (data: unknown): RequestInit => ({
    method: 'POST',
    headers: {
        Authorization: `Bearer ${accessToken.getAccessToken()}`,
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
});

export const putRequest = (data: unknown, invite?: boolean): RequestInit => {
    const headersGeneral = {
        Authorization: `Bearer ${accessToken.getAccessToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    const headersForInvite = {
        Authorization: `Bearer ${accessToken.getAccessToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'if-none-match': '*',
    };
    return {
        method: 'PUT',
        headers: invite ? headersForInvite : headersGeneral,
        body: JSON.stringify(data),
    };
};

export const deleteRequest = (): RequestInit => ({
    method: 'DELETE',
    headers: {
        Authorization: `Bearer ${accessToken.getAccessToken()}`,
    },
});
