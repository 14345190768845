import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SliceActions } from '../app/reduxHelper';

interface InitialState {
    key: string | null;
    type: string | null;
}

const initialState: InitialState = {
    key: null,
    type: null,
};

const notificationSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        resetDisplayedNotification() {
            return initialState;
        },
        displayNotification(state, action: PayloadAction<{ notificationKey: string; notificationType: string }>) {
            state.key = action.payload.notificationKey;
            state.type = action.payload.notificationType;
        },
    },
});

export type NotificationActionTypes = SliceActions<typeof notificationSlice.actions>;
export default notificationSlice;
