import { useIntl } from 'react-intl';
import Markdown from 'react-markdown';
import map from 'lodash/fp/map';
import range from 'lodash/fp/range';

export const CounterList = ({ chapter, count }: { chapter: string; count: number }) => {
    const intl = useIntl();

    const items = map(
        (number) => (
            <Markdown key={number}>
                {intl.formatMessage({ id: `infoDialog.functions.${chapter}.counter${number}` })}
            </Markdown>
        ),
        range(1, count + 1)
    );

    return <div className={'counter color-onboarding filled size-small margin-top-20'}>{items}</div>;
};
