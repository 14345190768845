import partial from 'lodash/fp/partial';
import { FormattedMessage } from 'react-intl';

import { Chapter } from './Chapter';
import { InfoDialogMenuItem } from './InfoDialogMenuItem';
import { INFO_TRACKING_ACTIONS } from '../../../tracking/gtm-helper';
import ListMenu from '@rio-cloud/rio-uikit/ListMenu';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { accountType, AccountType, getAccountType } from '../../../configuration/login/loginSlice';
import { ListMenuNavItem } from '@rio-cloud/rio-uikit/components/listMenu/ListMenuGroup';

interface ExtendedNavItem extends ListMenuNavItem {
    applicableAccountTypes: AccountType[];
}

const ALL_ACCOUNT_TYPES: AccountType[] = [accountType.CARRIER, accountType.BUYER, accountType.SUPPLIER];

const buildNavItem = (
    activeChapter: string,
    onClick: (event: any) => void,
    applicableAccountTypes: AccountType[],
    props: { chapter: string; trackingAction: string }
): ExtendedNavItem => ({
    key: props.chapter,
    item: <InfoDialogMenuItem activeChapter={activeChapter} onClick={onClick} {...props} />,
    applicableAccountTypes,
});

const getMenuItems = (activeChapter: string, onClick: (event: any) => void, currentAccountType: AccountType) => {
    const buildItem = partial(buildNavItem, [activeChapter, onClick]);

    const isApplicableNavItems = (navItem: ExtendedNavItem) =>
        navItem.applicableAccountTypes.includes(currentAccountType);

    return [
        {
            group: <FormattedMessage id={'infoDialog.about'} />,
            navItems: [
                buildItem(ALL_ACCOUNT_TYPES, {
                    chapter: Chapter.WELCOME,
                    trackingAction: INFO_TRACKING_ACTIONS.WELCOME_BUTTON_CLICKED,
                }),
            ].filter(isApplicableNavItems),
        },
        {
            group: <FormattedMessage id={'infoDialog.concepts'} />,
            navItems: [
                buildItem([accountType.CARRIER], {
                    chapter: Chapter.GROUPS,
                    trackingAction: INFO_TRACKING_ACTIONS.USER_GROUP_DESCRIPTION_LINK_CLICKED,
                }),
                buildItem([accountType.CARRIER], {
                    chapter: Chapter.ROLES,
                    trackingAction: INFO_TRACKING_ACTIONS.USER_GROUP_ROLE_DESCRIPTION_LINK_CLICKED,
                }),
                buildItem([accountType.CARRIER], {
                    chapter: Chapter.FLEET,
                    trackingAction: INFO_TRACKING_ACTIONS.FLEET_GROUP_ROLE_DESCRIPTION_LINK_CLICKED,
                }),

                buildItem([accountType.BUYER], {
                    chapter: Chapter.BUYER_GROUPS,
                    trackingAction: INFO_TRACKING_ACTIONS.USER_GROUP_DESCRIPTION_LINK_CLICKED,
                }),
                buildItem([accountType.SUPPLIER], {
                    chapter: Chapter.SUPPLIER_GROUPS,
                    trackingAction: INFO_TRACKING_ACTIONS.USER_GROUP_DESCRIPTION_LINK_CLICKED,
                }),
            ].filter(isApplicableNavItems),
        },
        {
            group: <FormattedMessage id={'infoDialog.accountSettings'} />,
            navItems: [
                buildItem([accountType.CARRIER], {
                    chapter: Chapter.DRIVER_SELF_REGISTRATION,
                    trackingAction: INFO_TRACKING_ACTIONS.ACCOUNT_SETTINGS_DRIVER_SELF_REGISTRATION_BUTTON_CLICKED,
                }),
            ].filter(isApplicableNavItems),
        },
        {
            group: <FormattedMessage id={'infoDialog.features'} />,
            navItems: [
                buildItem(ALL_ACCOUNT_TYPES, {
                    chapter: Chapter.INVITE_USER,
                    trackingAction: INFO_TRACKING_ACTIONS.FUNCTIONS_INVITE_USER_BUTTON_CLICKED,
                }),
                buildItem([accountType.CARRIER], {
                    chapter: Chapter.INVITE_USER_FOR_DRIVER,
                    trackingAction: INFO_TRACKING_ACTIONS.FUNCTIONS_INVITE_USER_FOR_DRIVER_BUTTON_CLICKED,
                }),
                buildItem([accountType.CARRIER], {
                    chapter: Chapter.CREATE_USER_GROUP,
                    trackingAction: INFO_TRACKING_ACTIONS.FUNCTIONS_CREATE_USERGROUP_BUTTON_CLICKED,
                }),
                buildItem([accountType.CARRIER], {
                    chapter: Chapter.RESTRICT_USER_GROUP,
                    trackingAction: INFO_TRACKING_ACTIONS.FUNCTIONS_RESTRICT_USERGROUP_BUTTON_CLICKED,
                }),
                buildItem(ALL_ACCOUNT_TYPES, {
                    chapter: Chapter.DELETE_USER,
                    trackingAction: INFO_TRACKING_ACTIONS.FUNCTIONS_DELETE_USER_BUTTON_CLICKED,
                }),
                buildItem([accountType.CARRIER], {
                    chapter: Chapter.DELETE_USER_GROUP,
                    trackingAction: INFO_TRACKING_ACTIONS.FUNCTIONS_DELETE_USERGROUP_BUTTON_CLICKED,
                }),
            ].filter(isApplicableNavItems),
        },
    ];
};

interface Props {
    chapter: string;
    onSelectChapter: (event: any) => void;
}

export const InfoDialogMenu = ({ chapter, onSelectChapter }: Props) => {
    const accountType = useAppSelector(getAccountType);

    return (
        <ListMenu menuItems={getMenuItems(chapter, onSelectChapter, accountType)} groupClassName={'padding-left-0'} />
    );
};
