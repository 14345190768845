import React from 'react';
import { useIntl } from 'react-intl';
import DeleteUserGroup from '../../../../assets/delete_user_group.png';
import { CounterList } from '../CounterList';

export const DeleteUserGroupExplained = () => {
    const intl = useIntl();

    const title = intl.formatMessage({ id: `infoDialog.features.deleteUserGroup` });

    return (
        <div className={'row'} data-testid={'Create User'}>
            <div className={'col-xs-12'}>
                <h4>{title}</h4>
                <div className={`margin-bottom-25`}>
                    <img src={DeleteUserGroup} alt={'tdv'} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div>
                <CounterList chapter={'deleteUserGroup'} count={2} />
            </div>
        </div>
    );
};
