export const DEFAULT_GROUPS_DESCRIPTION = {
    FLEET_ADMIN: {
        key: 'Fleet_Admin',
        titleId: 'groups.name.Fleetadmin',
        generalDescription: 'groups.names.description.Fleetadmin',
    },
    FLEET_USERS: {
        key: 'Fleet_Users',
        titleId: 'groups.name.Fleetusers',
        generalDescription: 'groups.names.description.Fleetusers',
    },
    FLEET_DRIVER: {
        key: 'Fleet_Driver',
        titleId: 'groups.name.Fleetdriver',
        generalDescription: 'groups.names.description.Fleetdriver',
    },
    BOOKING_EXPERT: {
        key: 'Booking_Expert',
        titleId: 'groups.name.Bookingexpert',
        generalDescription: 'groups.name.description.Bookingexpert',
    },
    ANALYSIS_EXPERT: {
        key: 'Analysis_Expert',
        titleId: 'groups.name.Analysisexpert',
        generalDescription: 'groups.name.description.Analysisexpert',
    },
    CUSTOM: {
        key: 'Custom',
        titleId: 'groups.name.Custom',
        generalDescription: 'groups.names.description.Custom',
    },
};

export const DEFAULT_SUPPLIER_GROUPS_DESCRIPTION = {
    SUPPLIER_ADMIN: {
        key: 'Supplier_Admin',
        titleId: 'groups.name.Supplieradmin',
        generalDescription: 'groups.names.description.Supplieradmin',
    },
    SUPPLIER_USER: {
        key: 'Supplier_User',
        titleId: 'groups.name.Supplieruser',
        generalDescription: 'groups.names.description.Supplieruser',
    },
};

export const DEFAULT_BUYER_GROUPS_DESCRIPTION = {
    BUYER_ADMIN: {
        key: 'Buyer_Admin',
        titleId: 'groups.name.Buyeradmin',
        generalDescription: 'groups.names.description.Buyeradmin',
    },
    BUYER_USER: {
        key: 'Buyer_User',
        titleId: 'groups.name.Buyeruser',
        generalDescription: 'groups.names.description.Buyeruser',
    },
};
