import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import PropTypes from 'prop-types';
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RoleAssignmentCreateGroup } from '../../listelements/roles/RoleAssignmentList';
import { CreateGroupFormContainer } from '../groupform/GroupFormContainer';
import { mapDispatchToCreateGroupDialog, mapStateToCreateGroupDialog } from './createGroup';
import { withRouter } from '../../../../routes/routerUtils';

export const CreateGroupDialogPure = (props) => {
    const { handleCancel, handleSubmit, canSubmit } = props;

    const body = (
        <React.Fragment>
            <CreateGroupFormContainer />
            <RoleAssignmentCreateGroup />
        </React.Fragment>
    );

    return (
        <ConfirmationDialog
            bsSize={'sm'}
            cancelButtonText={<FormattedMessage id={'groups.create.cancel'} />}
            confirmButtonText={<FormattedMessage id={'groups.create.saveGroup'} />}
            content={body}
            disableConfirm={!canSubmit}
            onClickCancel={handleCancel}
            onClickConfirm={handleSubmit}
            show
            title={<FormattedMessage id={'groups.create.dialogTitle'} />}
        />
    );
};

CreateGroupDialogPure.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    showCreateGroup: PropTypes.bool.isRequired,
};

export const CreateGroupDialog = withRouter(
    connect(mapStateToCreateGroupDialog, mapDispatchToCreateGroupDialog)(CreateGroupDialogPure)
);
