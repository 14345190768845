export enum Chapter {
    WELCOME = 'infoDialog.welcome',

    // concepts
    GROUPS = 'infoDialog.concepts.groups',
    ROLES = 'infoDialog.concepts.roles',
    FLEET = 'infoDialog.concepts.fleet',

    SUPPLIER_GROUPS = 'infoDialog.concepts.supplier-groups',
    BUYER_GROUPS = 'infoDialog.concepts.buyer-groups',

    // features
    INVITE_USER = 'infoDialog.features.inviteUser',
    INVITE_USER_FOR_DRIVER = 'infoDialog.features.inviteUserForDriver',
    DELETE_USER = 'infoDialog.features.deleteUser',
    CREATE_USER_GROUP = 'infoDialog.features.createUserGroup',
    DELETE_USER_GROUP = 'infoDialog.features.deleteUserGroup',
    RESTRICT_USER_GROUP = 'infoDialog.features.restrictUserGroup',

    // account settings
    DRIVER_SELF_REGISTRATION = 'infoDialog.accountSettings.driverSelfRegistration',
}
