import { createGroupThunk } from './createGroupThunks';
import { canSubmitCreateGroup } from './createGroupInputValidation';
import { fireTrackingEvent, TRACKING_CATEGORIES, USER_GROUP_TRACKING_ACTIONS } from '../../../../tracking/gtm-helper';
import { closeCreateGroupModal } from '../../../../routes/actions';
import { selectShowCreateGroupModal } from '../../../../routes/selector';
import { getGroupCreationInProgress } from './createGroupSelectors';
import { AppDispatch, RootState } from '../../../../configuration/setup/store';
import createGroupSlice from './createGroupSlice';

export const mapStateToCreateGroupDialog = (state: RootState, ownProps: any) => ({
    canSubmit: !getGroupCreationInProgress(state) && canSubmitCreateGroup(state),
    showCreateGroup: selectShowCreateGroupModal(ownProps),
});

export const mapDispatchToCreateGroupDialog = (dispatch: AppDispatch, ownProps: any) => ({
    handleSubmit: () => {
        fireTrackingEvent(
            TRACKING_CATEGORIES.USERADMIN_USER_GROUP,
            USER_GROUP_TRACKING_ACTIONS.SAVE_CREATED_USER_GROUP
        );
        dispatch(createGroupThunk(ownProps));
    },
    handleCancel: () => {
        dispatch(createGroupSlice.actions.resetCreateGroupState());
        closeCreateGroupModal(ownProps);
    },
});
