import { createGroupRoleViewModel, roleViewModel } from '../../services/roleService';
import {
    collectAssetGroupRestrictionsForRoleInCreateGroup,
    collectAssetGroupRestrictionsForRoleInGroupSidebar,
} from '../../services/fetchRestrictionsService';
import { getSelectedGroup } from '../../appSelectors';
import { canRead, canUpdate } from '../../meta';
import { isAppInMaintenanceMode } from '../../../selectors';
import { AppDispatch, RootState } from '../../../../configuration/setup/store';
import createGroupSlice from '../../groups/creategroup/createGroupSlice';
import { UserGroupRole } from '../../appTypes';
import { Restriction, RestrictionUpdate } from '../../groups/creategroup/createGroupTypes';
import groupSidebarSlice from '../../groups/groupsidebar/groupSidebarSlice';

export const mapStateToGroupSidebarRoleAssignmentProps = (state: RootState) => ({
    canRead: canRead(getSelectedGroup(state)),
    canUpdate: !isAppInMaintenanceMode(state) && canUpdate(getSelectedGroup(state)),
    category: '',
    items: roleViewModel(state),
    handleFetchSuggestionsSubItems: (roleId: string) => (value: string) =>
        collectAssetGroupRestrictionsForRoleInGroupSidebar(state, roleId, value),
    viewEmptyPlaceholder: canUpdate(getSelectedGroup(state)),
});

export const mapDispatchToGroupSidebarRoleAssignmentProps = (dispatch: AppDispatch) => ({
    handleResurrectItem: (role: UserGroupRole) => dispatch(groupSidebarSlice.actions.resurrectRole(role)),
    handleRemoveItem: (role: UserGroupRole) => dispatch(groupSidebarSlice.actions.removeRole(role)),

    handleResurrectSubItem: (restriction: Restriction) =>
        dispatch(groupSidebarSlice.actions.resurrectRestriction(restriction)),
    handleRemoveSubItem: (restriction: Restriction) =>
        dispatch(groupSidebarSlice.actions.removeRestriction(restriction)),
    onSelectSuggestionSubItem: (roleId: string) => (restriction: Restriction) =>
        dispatch(groupSidebarSlice.actions.addRestriction({ restriction, roleId })),
});

export const mapStateToCreateGroupRoleAssignmentProps = (state: RootState) => ({
    canRead: true,
    canUpdate: true,
    category: '',
    items: createGroupRoleViewModel(state),
    handleFetchSuggestionsSubItems: (roleId: string) => (value: string) =>
        collectAssetGroupRestrictionsForRoleInCreateGroup(state, roleId, value),
    viewEmptyPlaceholder: true,
});

export const mapDispatchToCreateGroupRoleAssignmentProps = (dispatch: AppDispatch) => ({
    handleResurrectItem: () => {},
    handleRemoveItem: (role: UserGroupRole) => dispatch(createGroupSlice.actions.removeRole(role)),

    handleResurrectSubItem: () => {},
    handleRemoveSubItem: (restriction: RestrictionUpdate) =>
        dispatch(createGroupSlice.actions.removeRestriction(restriction)),
    onSelectSuggestionSubItem: (roleId: string) => (restriction: Restriction) =>
        dispatch(createGroupSlice.actions.addRestriction({ restriction, parentId: roleId })),
});
