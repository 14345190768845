import { getRoles, getSelectedGroup, getUsers } from '../../appSelectors';
import { getCreateGroupRestrictionsToAdd, getCreateGroupRolesToAdd } from '../creategroup/createGroupSelectors';
import { getCurrentGroupDescription, getCurrentGroupName } from './groupPropertiesService';
import {
    getGroupRestrictionsToAdd,
    getGroupRestrictionsToRemove,
    getGroupRolesToAdd,
    getGroupRolesToRemove,
    getGroupUsersToAdd,
    getGroupUsersToRemove,
} from './groupSidebarSelectors';
import { AssignedRole, DisplayedGroupRole, User, UserGroup, UserGroupRole } from '../../appTypes';
import { Restriction } from '../creategroup/createGroupTypes';
import { v4 as uuid } from 'uuid';
import { getAccountId } from '../../../../configuration/tokenHandling/tokenHandlingSlice';
import { RootState } from '../../../../configuration/setup/store';

const addGroupId = (user: User, groupId: string) => {
    if (user.group_ids && user.group_ids.includes(groupId)) {
        return user;
    }
    return {
        ...user,
        group_ids: [...user.group_ids!, groupId], // TODO can group_ids be undefined?
    };
};

const removeGroupId = (user: User, groupIdToRemove: string) => ({
    ...user,
    group_ids: [...user.group_ids!.filter((groupId) => groupId !== groupIdToRemove)], // TODO can group_ids be undefined?
});

export const getUserChangesPayloads = (
    users: Array<User>,
    usersToAdd: Array<User>,
    usersToRemove: Array<User>,
    selectedGroup: UserGroup
): Array<User> => {
    const groupId = selectedGroup.id;
    const userIdsToAdd = usersToAdd.map((user) => user.id);
    const userIdsToRemove = usersToRemove.map((user) => user.id);

    const payloadsForAddingUsersToGroup = users
        .filter((user) => userIdsToAdd.includes(user.id))
        .map((user) => addGroupId(user, groupId));

    const payloadsForRemovingUsersFromGroup = users
        .filter((user) => userIdsToRemove.includes(user.id))
        .map((user) => removeGroupId(user, groupId));

    return payloadsForAddingUsersToGroup.concat(payloadsForRemovingUsersFromGroup);
};

export const getUserChangesPayloadsFromState = (state: RootState): Array<User> => {
    const users = getUsers(state);
    const usersToAdd = getGroupUsersToAdd(state);
    const usersToRemove = getGroupUsersToRemove(state);
    const selectedGroup = getSelectedGroup(state);

    return getUserChangesPayloads(users, usersToAdd, usersToRemove, selectedGroup!); // TODO can selectedGroup be null?
};

const toRoleInPayload = (roleIdToAdd: string, roles: Array<UserGroupRole>) => ({
    role_id: roleIdToAdd,
    scope: {
        identifiers: [],
        identifier_type: roles.filter((role) => role.id === roleIdToAdd)[0].scope_type,
    },
});

const modifyRestrictions = (
    role: AssignedRole,
    restrictionToAdd: Array<Restriction>, // TODO Restriction or RestrictionUpdate
    restrictionToRemove: Array<Restriction>
) => {
    const restrictionIdsToAdd = restrictionToAdd
        .filter((restriction) => restriction.parentId === role.role_id)
        .map((restriction) => restriction.id);
    const restrictionIdsToRemove = restrictionToRemove
        .filter((restriction) => restriction.parentId === role.role_id)
        .map((restriction) => restriction.id);

    const identifiers = role.scope.identifiers
        .filter((restrictionId) => !restrictionIdsToRemove.includes(restrictionId))
        .concat(restrictionIdsToAdd);
    return {
        ...role,
        scope: {
            ...role.scope,
            identifiers,
        },
    };
};

const setSystemIdentifier = (role: AssignedRole, accountId: string): AssignedRole => {
    return {
        ...role,
        scope: {
            ...role.scope,
            identifiers: [accountId],
            identifier_type: 'account',
        },
    };
};

const removeSystemIdentifier = (role: AssignedRole, accountId: string): AssignedRole => ({
    ...role,
    scope: {
        ...role.scope,
        identifiers: role.scope.identifiers.filter((identifier) => identifier !== accountId),
        identifier_type: 'tag',
    },
});

export const setOrRemoveSystemTagsIfNeeded = (role: AssignedRole, accountId: string): AssignedRole => {
    if (role.scope.identifiers.length === 0) {
        return setSystemIdentifier(role, accountId);
    }
    if (role.scope.identifiers.length > 1) {
        return removeSystemIdentifier(role, accountId);
    }
    return role;
};

export const getUpdatedGroupPayload = (
    selectedGroup: UserGroup,
    newName: string,
    newDescription: string,
    restrictionToAdd: Array<Restriction>,
    restrictionToRemove: Array<Restriction>,
    roles: Array<UserGroupRole>,
    rolesToAdd: Array<DisplayedGroupRole>,
    rolesToRemove: Array<UserGroupRole>,
    accountId: string
): UserGroup => {
    const roleIdsToRemove = rolesToRemove.map((role) => role.id);
    const roleIdsToAdd = rolesToAdd.map((role) => role.id);
    const newAssignedRoles = selectedGroup.assigned_roles
        .filter((role) => !roleIdsToRemove.includes(role.role_id))
        .concat(roleIdsToAdd.map((roleIdToAdd) => toRoleInPayload(roleIdToAdd, roles)))
        .map((role) => modifyRestrictions(role, restrictionToAdd, restrictionToRemove))
        .map((role) => setOrRemoveSystemTagsIfNeeded(role, accountId));

    return {
        ...selectedGroup,
        name: newName,
        description: newDescription,
        assigned_roles: newAssignedRoles,
    };
};

export const getUpdatedGroupPayloadFromState = (state: RootState): UserGroup => {
    const selectedGroup = getSelectedGroup(state);
    const newName = getCurrentGroupName(state);
    const newDescription = getCurrentGroupDescription(state);

    const restrictionToAdd = getGroupRestrictionsToAdd(state);
    const restrictionToRemove = getGroupRestrictionsToRemove(state);

    const roles = getRoles(state);
    const rolesToAdd = getGroupRolesToAdd(state);
    const rolesToRemove = getGroupRolesToRemove(state);

    const accountId = getAccountId(state);

    return getUpdatedGroupPayload(
        selectedGroup!, // TODO nullable?
        newName,
        newDescription,
        restrictionToAdd,
        restrictionToRemove,
        roles,
        rolesToAdd,
        rolesToRemove,
        accountId!
    );
};

export const getCreatePayloadFromState = (state: RootState, name: string, description: string): UserGroup => {
    const groupId = uuid();
    const selectedGroup: UserGroup = { id: groupId, name: '', description: '', assigned_roles: [] };
    const roles = getRoles(state);

    const restrictionToAdd = getCreateGroupRestrictionsToAdd(state);
    const rolesToAdd = getCreateGroupRolesToAdd(state);

    const accountId = getAccountId(state);

    return getUpdatedGroupPayload(
        selectedGroup,
        name,
        description,
        restrictionToAdd,
        [],
        roles,
        rolesToAdd,
        [],
        accountId!
    );
};
