import get from 'lodash/get';
import { CreateGroupState } from './createGroupTypes';
import { DisplayedGroupRole } from '../../appTypes';
import { RootState } from '../../../../configuration/setup/store';

export const getCreateGroup = (state: RootState): CreateGroupState => get(state, 'createGroup');
export const getCreateGroupRolesToAdd = (state: RootState): Array<DisplayedGroupRole> =>
    get(getCreateGroup(state), 'rolesToAdd', []);
export const getCreateGroupRestrictionsToAdd = (state: RootState) =>
    get(getCreateGroup(state), 'restrictionsToAdd', []);

export const getCreateGroupNameModification = (state: RootState) => get(getCreateGroup(state), 'newGroupName', '');
export const getCreateGroupDescriptionModification = (state: RootState) =>
    get(getCreateGroup(state), 'newGroupDescription', '');
export const getGroupCreationInProgress = (state: RootState) => get(getCreateGroup(state), 'groupCreationInProgress');
