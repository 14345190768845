import { IntlProvider } from 'react-intl';

import { Shell } from '../../components/shell/Shell';
import { reportErrorToSentry } from '../../configuration/setup/sentry';
import { DEFAULT_LOCALE } from '../../configuration/lang/lang';
import { getAccessToken } from '../../configuration/tokenHandling/tokenHandlingSlice';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../../configuration/lang/langSlice';
import { config } from '../../config';
import { ConfigCatProvider, createConsoleLogger, DataGovernance } from 'configcat-react';

const intlErrorHandler = (intlError: Error) => {
    reportErrorToSentry(intlError);
};

export const UserAdmin = () => {
    const displayMessages = useAppSelector((state) => getDisplayMessages(state));
    const userLocale = useAppSelector((state) => getLocale(state));
    const accessToken = useAppSelector((state) => getAccessToken(state));
    const logger = createConsoleLogger(config.configCat.logLevel);

    if (!displayMessages) {
        return null;
    }

    return (
        <IntlProvider
            defaultLocale={DEFAULT_LOCALE}
            key={userLocale}
            locale={userLocale ? userLocale : DEFAULT_LOCALE}
            onError={intlErrorHandler}
            messages={displayMessages}
        >
            <ConfigCatProvider
                sdkKey={config.configCat.apiKey}
                options={{
                    logger,
                    dataGovernance: DataGovernance.EuOnly,
                }}
            >
                <Shell accessToken={accessToken!} />
            </ConfigCatProvider>
        </IntlProvider>
    );
};
