import get from 'lodash/get';
import { PreferredLogin } from './inviteUserTypes';
import { RootState } from '../../../../configuration/setup/store';

export const getInviteUser = (state: RootState) => get(state, 'inviteUser');
export const getInviteFirstName = (state: RootState) => get(getInviteUser(state), 'firstName');
export const getInviteLastName = (state: RootState) => get(getInviteUser(state), 'lastName');
export const getInviteEmail = (state: RootState) => get(getInviteUser(state), 'email');
export const getInvitePhoneNumber = (state: RootState) => get(getInviteUser(state), 'phoneNumber');
export const getInvitePreferredLogin = (state: RootState): PreferredLogin =>
    get(getInviteUser(state), 'preferredLogin', 'email');
export const getInviteUserGroupIds = (state: RootState): string[] => get(getInviteUser(state), 'userGroupIds', []);
export const getInviteLogin = (state: RootState): string => get(getInviteUser(state), 'login');
export const getInviteInProgress = (state: RootState): boolean => get(getInviteUser(state), 'inviteInProgress');
