import { getGroups, getGroupsLoadMoreLink, getIsLoading, getRoles, getTags } from '../../appSelectors';
import { getGroupsListMode } from '../../../setting/selectors';
import { loadMoreGroups } from '../groupstab/groupTabThunks';
import { GROUPS_PAGE_SIZE } from '../../../../constants';
import { groupSelectionInTable } from './groupTableThunks';
import {
    fireTrackingEvent,
    INFO_TRACKING_ACTIONS,
    TRACKING_CATEGORIES,
    USER_GROUP_TRACKING_ACTIONS,
} from '../../../../tracking/gtm-helper';
import { Chapter } from '../../infoModal/Chapter';
import { changeGroupSort, setActiveInfoModalChapter } from '../../../../routes/actions';
import { selectGroupIdOfSelectedGroup, selectGroupSort } from '../../../../routes/selector';

export const mapStateToGroupTableProps = (state, ownProps) => ({
    selectedGroupId: selectGroupIdOfSelectedGroup(ownProps),
    isFirstPage: getGroups(state).length <= GROUPS_PAGE_SIZE,
    hasMoreData: Boolean(getGroupsLoadMoreLink(state)),
    groupsListMode: getGroupsListMode(state),
    groups: getGroups(state),
    tags: getTags(state),
    roles: getRoles(state),
    groupSort: selectGroupSort(ownProps),
    isLoading: getIsLoading(state),
});

export const mapDispatchToGroupTableProps = (dispatch, ownProps) => ({
    handleSelectionChange: (group) => {
        fireTrackingEvent(TRACKING_CATEGORIES.USERADMIN_USER_GROUP, USER_GROUP_TRACKING_ACTIONS.SELECT_USER_GROUP);
        dispatch(groupSelectionInTable(group, ownProps));
    },
    handleSortChange: (ev) => {
        const newSortBy = ev.currentTarget.getAttribute('data-sortby');
        const previousGroupSort = selectGroupSort(ownProps);
        const previousSortAsc = previousGroupSort ? previousGroupSort.charAt(0) === '+' : true;
        const previousSortBy = previousGroupSort ? previousGroupSort.substring(1) : '';
        if (previousSortBy === newSortBy) {
            changeGroupSort(ownProps, (!previousSortAsc ? '+' : '-') + newSortBy);
        } else {
            changeGroupSort(ownProps, (previousSortAsc ? '+' : '-') + newSortBy);
        }
    },
    handleLoadMore: () => dispatch(loadMoreGroups),
    showUserGroupRolesHelp: () => {
        fireTrackingEvent(
            TRACKING_CATEGORIES.USERADMIN_INFO,
            INFO_TRACKING_ACTIONS.USER_GROUP_ROLE_TABLE_HEADING_CLICKED
        );
        setActiveInfoModalChapter(Chapter.ROLES, ownProps);
    },
});
