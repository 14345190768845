import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeEmail } from '../../common/emailFunctions';
import { mapDispatchToInviteUserFormProps, mapStateToInviteUserFormProps } from '../invite/inviteUserForm';
import { FormInputGroup } from '../../common/FormInputGroup';
import { FormInputGroupWithPreferredLogin } from '../../common/FormInputGroupWithPreferredLogin';
import { mapDispatchToUserFormProps, mapStateToUserFormProps } from './userForm';
import { PreferredLogin } from '../invite/inviteUserTypes';
import { MfaList } from './MfaList';

export interface UserFormStateProps {
    readOnly: boolean;
    isCreationDialog: boolean | undefined;
    loginIsReadOnly: boolean;
    firstName: string;
    lastName: string;
    email: string | null | undefined;
    additionalAuthenticationFactors?: string[];
    phoneNumber: string | null | undefined;
    login: string;
    preferredLogin: PreferredLogin;
    phoneNumberIsReadOnly?: boolean; //
    phoneNumberError?: string | null | undefined; //
    loginError?: string | null | undefined; //
    firstNameError?: string; //
    lastNameError?: string; //
}

export interface UserFormDispatchProps {
    onChangeFirstName: (firstName: string) => void;
    onChangeLastName: (lastName: string) => void;
    onChangeLogin?: (login: string) => void;
    onChangePreferredLogin?: (preferredLogin: PreferredLogin) => void;
}

export class UserForm extends React.Component<UserFormStateProps & UserFormDispatchProps> {
    render() {
        const {
            readOnly,
            isCreationDialog,
            firstName,
            lastName,
            onChangeFirstName,
            onChangeLastName,
            lastNameError,
            firstNameError,
            additionalAuthenticationFactors,
        } = this.props;

        return (
            <div className={'form-group'}>
                <FormInputGroup
                    fieldError={firstNameError && <FormattedMessage id={firstNameError} />}
                    fieldLabel={<FormattedMessage id={'users.invite.label.firstName'} />}
                    fieldName={'first_name'}
                    onChange={onChangeFirstName}
                    readOnly={readOnly}
                    value={firstName}
                />
                <FormInputGroup
                    fieldError={lastNameError && <FormattedMessage id={lastNameError} />}
                    fieldLabel={<FormattedMessage id={'users.invite.label.lastName'} />}
                    fieldName={'last_name'}
                    onChange={onChangeLastName}
                    readOnly={readOnly}
                    value={lastName}
                />
                {this.renderLoginFields()}
                {!isCreationDialog && (
                    <div>
                        <div className={'text-color-dark margin-bottom-5 text-size-12'}>
                            <FormattedMessage id={'users.invite.label.mfa'} />
                        </div>
                        <MfaList additionalAuthFactors={additionalAuthenticationFactors} />
                    </div>
                )}
            </div>
        );
    }

    renderLoginFields() {
        return this.props.loginIsReadOnly ? this.renderSidebarLoginField() : this.renderInvitationLoginField();
    }

    renderInvitationLoginField() {
        const { login, loginError, onChangePreferredLogin, onChangeLogin, preferredLogin } = this.props;

        return (
            <FormInputGroupWithPreferredLogin
                fieldError={loginError ? <FormattedMessage id={loginError} /> : null}
                fieldErrorExample={
                    preferredLogin === 'email' ? (
                        <FormattedMessage id={'users.invite.error.emailExample'} />
                    ) : (
                        <FormattedMessage id={'users.invite.error.phoneNumberExample'} />
                    )
                }
                fieldLabel={<FormattedMessage id={'users.invite.label.login'} />}
                fieldName={'login'}
                onChangePreferredLoginInput={onChangeLogin ? onChangeLogin : (arg0: string) => {}}
                onChangePreferredLogin={onChangePreferredLogin ? onChangePreferredLogin : (arg0: string) => {}}
                preferredLogin={preferredLogin}
                value={login}
            />
        );
    }

    renderSidebarLoginField() {
        const { email, phoneNumber, preferredLogin } = this.props; // email from userForm -> selectedUser

        const emailIsPreferredLogin = preferredLogin === 'email';

        return (
            <FormInputGroup
                fieldLabel={<FormattedMessage id={'users.invite.label.login'} />}
                fieldName={'login'}
                icon={emailIsPreferredLogin ? 'rioglyph rioglyph-envelope' : 'rioglyph rioglyph-phone'}
                mailToEmail={emailIsPreferredLogin ? makeEmail(email) : undefined}
                readOnly={true}
                value={emailIsPreferredLogin ? email! : phoneNumber!}
            />
        );
    }
}

export const UserFormContainer = connect(mapStateToUserFormProps, mapDispatchToUserFormProps)(UserForm);

export const InviteUserFormContainer = connect(
    mapStateToInviteUserFormProps,
    mapDispatchToInviteUserFormProps
)(UserForm);
