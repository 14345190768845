import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { UserInviteGroups } from '../../listelements/groups/GroupLists';
import { InviteUserFormContainer } from '../usersidebar/UserFormContainer';
import { mapDispatchToInviteUserProps, mapStateToInviteUserProps } from './inviteUser';
import { withRouter } from '../../../../routes/routerUtils';

export const InviteUserDialog = (props) => {
    const { handleCancel, handleSubmitForm, canSubmit, handleUserGroupsSelectionForInvite } = props;

    const body = (
        <React.Fragment>
            <InviteUserFormContainer />
            <UserInviteGroups notifyParentOfChange={handleUserGroupsSelectionForInvite} />
        </React.Fragment>
    );

    return (
        <ConfirmationDialog
            bsSize={'sm'}
            cancelButtonText={<FormattedMessage id={'users.invite.cancel'} />}
            confirmButtonText={<FormattedMessage id={'users.invite.sendInvite'} />}
            content={body}
            disableConfirm={!canSubmit}
            onClickCancel={handleCancel}
            onClickConfirm={handleSubmitForm}
            show
            title={<FormattedMessage id={'users.invite.title'} />}
        />
    );
};

InviteUserDialog.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    handleSubmitForm: PropTypes.func.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    showInvite: PropTypes.bool.isRequired,
    handleUserGroupsSelectionForInvite: PropTypes.func.isRequired,
};

export const InviteUserContainer = withRouter(
    connect(mapStateToInviteUserProps, mapDispatchToInviteUserProps)(injectIntl(InviteUserDialog))
);
