import {
    getCreateGroupDescriptionModification,
    getCreateGroupNameModification,
} from '../creategroup/createGroupSelectors';
import { getCreateGroupDescriptionError, getCreateGroupNameError } from '../creategroup/createGroupInputValidation';
import { AppDispatch } from '../../../../setup/types';
import { RootState } from '../../../../configuration/setup/store';
import createGroupSlice from '../creategroup/createGroupSlice';

export const mapStateToCreateGroupFormProps = (state: RootState) => ({
    readOnly: false,
    groupName: getCreateGroupNameModification(state),
    groupDescription: getCreateGroupDescriptionModification(state),
    groupNameError: getCreateGroupNameError(state),
    groupDescriptionError: getCreateGroupDescriptionError(state),
});

export const mapDispatchToCreateGroupFormProps = (dispatch: AppDispatch) => ({
    setGroupName: (newGroupName: string) => dispatch(createGroupSlice.actions.createGroupName(newGroupName)),
    setGroupDescription: (newGroupDescription: string) =>
        dispatch(createGroupSlice.actions.createGroupDescription(newGroupDescription)),
});
